/**
 * Exports aspect ratio-related design tokens
 */

import { token } from '../utils';

export const ratio = token('ratio', [{
        key: '2x3',
        value: { width: 2, height: 3, },
        order: 10,
    },{
        key: '1x1',
        value: { width: 1, height: 1, },
        order: 20,
    },{
        key: '4x3',
        value: { width: 4, height: 3, },
        order: 30,
    },{
        key: '3x2',
        value: { width: 3, height: 2, },
        order: 40,
    },{
        key: 'golden-ratio',
        value: { width: 1.618, height: 1, },
        order: 50,
    },{
        key: '16x9',
        value: { width: 16, height: 9, },
        order: 60,
    }]
);

export const defaultRatio = '1x1';
