import React from 'react';

function Love64(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "love 64";

	return (
		<svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<line fill="none" stroke={secondaryfill} x1="15" x2="20.761" y1="49" y2="43.239"/>
		<line fill="none" stroke={secondaryfill} x1="42" x2="56" y1="22" y2="8"/>
		<polygon fill="none" points="15 54 15 49 10 49 6 53 11 53 11 58 15 54" stroke={secondaryfill}/>
		<polygon points="54 6 60 4 58 10 54 6" stroke="none"/>
		<polygon fill="none" points="54 6 60 4 58 10 54 6" stroke={secondaryfill}/>
		<path d="M42,12a12.134,12.134,0,0,0-10,5.4A11.986,11.986,0,0,0,10,24c0,12,22,30,22,30S54,36,54,24A12.035,12.035,0,0,0,42,12Z" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Love64;