/**
 * Exports BlockCallout
 */

import React from 'react';
import pt from 'prop-types';
import { Flex, Container, Heading, Paragraph, Box, Breakpoint } from '@arturpol/systheme';
import { useCallout } from '../../utils';
import CalloutService from './Service';
import feather from '../../../content/images/feather-callout.svg';
import bg from '../../../content/images/callout-bg.jpg';

const FeatherBackground = props => {

    props = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgImage: `url(${ feather })`,
        bgRepeat: 'no-repeat',
        bgPosition: { small: '-128px 24px', medium: 'calc(50% + 96px)', large: 'calc(50% + 256px)', },
        bgSize: { small: '480px auto', medium: '480px auto', },
        opacity: 0.03,
        ...props,
    };

    return <Box { ...props } />;

};

const ColumnWrapper = props => {

    props = {
        flexDirection: { small: 'column', medium: 'row' },
        alignItems: 'stretch',
        pt: { smallOnly: 6, },
        pb: { smallOnly: 6, },
        gap: { smallOnly: 4, },
        gutter: { medium: 'large', large: 'xxlarge', },
        gutterType: { medium: 'margin', },
        ...props,
    };

    return <Flex { ...props } />;

};

const CalloutFooter = props => {

    props = {
        el: 'footer',
        flexDirection: { small: 'column', medium: 'row', },
        alignItems: { small: 'stretch', medium: 'flex-start', },
        gap: { small: 'medium', },
        ...props,
    };

    return <Flex { ...props } />

};

const ContentWrapper = props => {

    props = {
        pt: { medium: 6, },
        pb: { medium: 6, },
        ...props,
    };

    return <Box { ...props } />;

};

/**
 * BlockCallout React component.
 * Block callout with opaque background to convince user to perform a specific action
 * @param {object} params
 */
const BlockCallout = ({ id, service, children, ...rest }) => {

    const { title, description } = useCallout(id);

    const props = {
        el: 'aside',
        position: 'relative',
        bgImage: `url(${ bg })`,
        bgRepeat: 'repeat',
        bgPosition: 'center center',
        ...rest,
    };

    const footer = <CalloutFooter>{ children }</CalloutFooter>;
    
    return (
        <Box { ...props }>
            <FeatherBackground />
            <Container position="relative">
                <ColumnWrapper>
                    <ContentWrapper span={{ small: 'shrink', medium: 'stretch', large: 6, }}>
                        <Heading kind="h4" variant="secondary">{ title }</Heading>
                        <Paragraph mb={{ small: 0, medium: 5, }}>{ description }</Paragraph>
                        <Breakpoint medium={ footer } />
                    </ContentWrapper>
                    { service ? <CalloutService title={ service.title } price={ service.price } span="shrink" offset={{ large: 2, }} /> : null }
                    <Breakpoint smallOnly={ footer } />
                </ColumnWrapper>
            </Container>
        </Box>
    );

};

BlockCallout.propTypes = {

    /**
     * Callout id
     * See more in /content/data/callout.yml
     */
    id: pt.string.isRequired,

    /**
     * Primary and secondary callout buttons
     * See more in /src/components/Callout/Button.js and /content/data/button.yml
     */
    children: pt.node.isRequired,

    /**
     * Featured service
     */
    service: pt.shape({
        title: pt.string.isRequired,
        price: pt.shape({
            amount: pt.number.isRequired,
        }),
    }),

};

export default BlockCallout;