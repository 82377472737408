import React from 'react';

function Face64(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "face 64";

	return (
		<svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<polyline fill="none" points="5 17 5 5 17 5"/>
		<polyline fill="none" points="59 17 59 5 47 5"/>
		<polyline fill="none" points="5 47 5 59 17 59"/>
		<polyline fill="none" points="59 47 59 59 47 59"/>
		<path d="M14,52l9.265-3.088A4,4,0,0,0,26,45.117V42.485C20.374,39.357,18,31.939,18,26V24a14,14,0,0,1,28,0v2c0,5.939-2.374,13.357-8,16.485v2.632a4,4,0,0,0,2.735,3.8L50,52" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Face64;