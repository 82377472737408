/**
 * Exports Layout
 */

import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { createGlobalStyle } from 'styled-components';
import { Systheme } from '@arturpol/systheme';
import { bafDark } from '../../themes';
import { hasProperty } from '../../utils';
import * as translations from '../../i18n/pl';
import { VideoModalProvider } from '../VideoModal';
import { CookieModal } from '../CookieModal';
import { PathsProvider } from '../Paths';
import PageWithModal from './PageWithModal';
import bgImage from '../../../static/images/page-bg.jpg';

// Array of page collections, which are rendered without default background image
const COLLECTIONS_WITHOUT_BG_IMAGE = ['article', 'webpage'];

/**
 * Global <body> style with page background
 */
const GlobalStyle = createGlobalStyle`
    body{
        ${ props => props.$showBackgroundImage ? `background-image: url(${bgImage});` : '' }
        background-repeat: repeat;
    }
`;

/**
 * Top-most React component that should exist permanently above every Gatsby page content.
 * Use Gatsby's ```wrapPageElement``` to install Layout instead of adding it in every page template.
 * @param {object} props 
 */
const Layout = props => {

    // No background image on Article template
    const showBackgroundImage = !hasProperty(props, 'pageContext', 'object') 
        || !hasProperty(props.pageContext, 'collection', 'string') || COLLECTIONS_WITHOUT_BG_IMAGE.indexOf(props.pageContext.collection) < 0;

    return (
        <AnimatePresence mode="wait">
            <Systheme isRenderedOnServer theme={ bafDark } zIndex="modal" translations={ translations } >
                <GlobalStyle $showBackgroundImage={ showBackgroundImage } />
                <PathsProvider>
                    <VideoModalProvider>
                        <PageWithModal { ...props } />
                    </VideoModalProvider>
                    <CookieModal />
                </PathsProvider>
            </Systheme>
        </AnimatePresence>
    );

};

export default Layout;