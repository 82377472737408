/**
 * Exports useAboutMeVideo
 */

import { useCallback } from 'react';
import { useVideoModal } from '../../components';
import useVideos from './useVideos';

// Video ID to open from /content/data/site.yml
const VIDEO_ID = 'about';

/**
 * React hook
 * @returns single function to open a modal window with an "about me" video
 */
const useAboutMeVideo = () => {

    const video = useVideos(VIDEO_ID);
    const id = video.youTubeId;
    const { open } = useVideoModal();
    
    return useCallback(() => {
        open(id);
    }, [ id, open ]);

};

export default useAboutMeVideo;