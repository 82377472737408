/**
 * Exports VideoModal
 */

import React from 'react';
import pt from 'prop-types';
import { Modal, useModal, Box, AspectRatio } from '@arturpol/systheme';

// Base YouTube url for embedded videos (iframe)
const YOUTUBE_EMBED_URL = 'https://www.youtube.com/embed';

// YouTube video url params used in the iframe, see: https://developers.google.com/youtube/player_parameters?hl=pl
const YOUTUBE_PARAMS = {
    autoplay: 1,
    controls: 0,
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
};

// Video aspect ratio
const VIDEO_ASPECT_RATIO = '16x9';

/**
 * ModalContent React component
 * Renders YouTube iframe with embedded video
 * @param {object} params
 */
const ModalContent = ({ videoId, ...rest }) => {
    
    const { opened } = useModal();
    const urlParams = new URLSearchParams(YOUTUBE_PARAMS).toString();
    const url = `${ YOUTUBE_EMBED_URL }/${ videoId }?${ urlParams }`;

    const iframeProps = {
        src: url,
        title: 'Video player',
        frameBorder: 0,
        allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
        allowFullScreen: true,
        width: 560,
        height: 315,
        style: {
            width: '100%',
            height: '100%',
        },
    };

    return (
        <Box { ...rest }>
            <AspectRatio ratio={ VIDEO_ASPECT_RATIO }>
                { opened ? <iframe { ...iframeProps }></iframe> : null }
            </AspectRatio>
        </Box>
    );

};

/**
 * VideoModal React component
 * Renders a video inside a modal window
 * @param {object} params 
 */
const VideoModal = ({ videoId, ...rest }) => {

    const props = {
        size: 'xlarge',
        alignX: 'center',
        alignY: 'center',
        swipe: false,
        animation: 'zoom',
        padding: 0,
        ...rest,
    };

    return (
        <Modal { ...props }>
            <ModalContent videoId={ videoId } />
        </Modal>
    );

};

VideoModal.propTypes = {

    /**
     * YouTube video ID e.g. 'oJ5RBY0AVEk'
     */
    videoId: pt.string,

};

export default VideoModal;