/**
 * Exports Mdx
 */

import React from 'react';
import pt from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import { Paragraph, Heading, Dl, Dt, Dd } from '@arturpol/systheme';
import { Link } from '../Link';
import { P, H1, H2, H3, H4, H5, H6, Blockquote, Ul, Ol, Li, Hr, A } from '../Typography';
import { Image } from '../Image';
import { ArticleImage } from '../ArticleImage';

// List of shortcodes (components available in .mdx files without explicit import)
const SHORTCODES = {
    P: Paragraph,
    H: Heading,
    Link,
    Image,
    ArticleImage,
    Dl,
    Dt,
    Dd,
};

// List of components used to render markdown elements
// See: https://www.gatsbyjs.com/plugins/gatsby-plugin-mdx/#mdxprovider
const COMPONENTS = {
    p: P,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H5,
    h6: H6,
    blockquote: Blockquote,
    ul: Ul,
    ol: Ol,
    li: Li,
    hr: Hr,
    a: A,
};

/**
 * Mdx React component
 * Customized instance of MDXRenderer created using MDXProvider.
 * Used to render content (body) from .mdx files.
 * @param {object} params
 */
const Mdx = ({ children }) => {

    const props = {
        components: { ...COMPONENTS, ...SHORTCODES },
        children,
    };

    return <MDXProvider { ...props } />;

};

Mdx.propTypes = {

    /**
     * Mdx body to render
     */
    children: pt.node.isRequired,

};

export default Mdx;