import { token } from '../../utils';

export default token('lineHeight', [{
    key: 'major-second',
    order: 112,
    value: 1.125,
},{
    key: 'major-third',
    order: 125,
    value: 1.25,
},{
    key: 'perfect-fourth',
    order: 133,
    value: 1.333,
},{
    key: 'perfect-fifth',
    order: 150,
    value: 1.5,
},{
    key: 'golden-ratio',
    order: 161,
    value: 1.618,
},{
    key: 'perfect-sixth',
    order: 175,
    value: 1.75,
}]);