import React from 'react';

function Menu32(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "menu 32";

	return (
		<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<line fill="none" x1="2" x2="30" y1="16" y2="16"/>
		<line fill="none" stroke={fill} x1="16" x2="30" y1="7" y2="7"/>
		<line fill="none" stroke={fill} x1="2" x2="16" y1="25" y2="25"/>
	</g>
</svg>
	);
};

export default Menu32;