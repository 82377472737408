import React from 'react';

function Wand48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "wand 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<rect height="7.071" width="38.184" fill="none" stroke={fill} transform="translate(-14.941 21.929) rotate(-45)" x="-0.092" y="25.464"/>
		<line fill="none" stroke={fill} x1="24" x2="29" y1="19" y2="24"/>
		<line fill="none" x1="18" x2="12.343" y1="8.657" y2="3"/>
		<line fill="none" x1="18" x2="12.343" y1="3" y2="8.657"/>
		<line fill="none" x1="45" x2="39.343" y1="35.657" y2="30"/>
		<line fill="none" x1="45" x2="39.343" y1="30" y2="35.657"/>
		<line fill="none" x1="45" x2="39.343" y1="3" y2="8.657"/>
		<line fill="none" x1="39.343" x2="45" y1="3" y2="8.657"/>
	</g>
</svg>
	);
};

export default Wand48;