import React from 'react';

function Minus12(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "minus 12";

	return (
		<svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeWidth="1">
		<line fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" x1="1.5" x2="11.5" y1="6.5" y2="6.5"/>
	</g>
</svg>
	);
};

export default Minus12;