/**
 * Exports Parallax
 */

import React, { useRef } from 'react';
import pt from 'prop-types';
import { useTheme } from 'styled-components';
import { useScroll, useTransform } from 'framer-motion';
import { Box, useTransition, durationProp, easingProp } from '@arturpol/systheme';

/**
 * Distance to move from center
 */
const DISTANCE = 4;

/**
 * Parallax React component
 * @param {object} params
 * @returns wraps any node that will be moving vertically at different speed than a scroll
 */
const Parallax = props => {

    const theme = useTheme();
    const ref = useRef(null);

    const { scrollYProgress } = useScroll({
        target: ref,
    });

    const y = useTransform(
        scrollYProgress, 
        [0 , 1], 
        [theme.rem(-1 * DISTANCE), theme.rem(DISTANCE)],
    );

    const ty = useTransition(y, {
        duration: durationProp(theme.duration, 'xlong'),
        ease: easingProp(theme.easing, 'ease-out/weak'),
    });
    
    props = {
        motion: true,
        ref,
        style: {
            y: ty,
        },
        ...props,
    };

    return <Box { ...props } />;

};

Parallax.propTypes = {

    /**
     * Any node
     */
    children: pt.node.isRequired,

};

export default Parallax;