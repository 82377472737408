/**
 * Exports SocialMediaList
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTheme } from 'styled-components';
import { Flex } from '@arturpol/systheme';
import { useSocialMedia, getSocialMediaLink } from '../../../utils';
import SocialMediaItem from './SocialMediaItem';

/**
 * React hook
 * @returns top menu social media items
 */
const useMainMenuSocialMedia = () => {

    const socialMedia = useSocialMedia();

    const data = useStaticQuery(graphql`
        query getMainMenuSocialMedia {
            menu(_id: {eq: "main-social-media"}) {
                items {
                    _id
                    _socialMedia
                    title
                }
            }
        }
    `);

    return data.menu.items.map(item => getSocialMediaLink(item, socialMedia));

};

/**
 * SocialMediaList React component
 * @param {object} props 
 */
const SocialMediaList = props => {
    
    const items = useMainMenuSocialMedia();
    const { topMenu } = useTheme();

    props = {
        el: 'ul',
        paddingLeft: topMenu.horizontalPadding,
        paddingRight: topMenu.horizontalPadding,
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 'medium',
        ...props,
    };

    return (
        <Flex { ...props }>
            { items.map(({ link, context }) => (
                <SocialMediaItem link={ link } context={ context } key={ context.id } />
            ))}
        </Flex>
    );

};

export default SocialMediaList;