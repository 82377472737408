/**
 * Exports top menu-related design tokens
 */

export const horizontalPadding = { small: 3, medium: 6, large: 3, };
export const verticalPadding = { small: 3, medium: 6, large: 3, };
export const transitionDuration = 'medium';
export const transitionEasing = 'ease-in-out/medium';
export const closeButtonOffsetTop = { small: 2, medium: 5, large: 2, };
export const closeButtonOffsetRight = { small: 2.5, medium: 5.5, large: 2.5, };
export const itemHeight = { small: 6, medium: 8, large: 6, };
export const itemTextKind = { small: 'h/400', medium: 'h/500', large: 'h/400', };