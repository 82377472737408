/**
 * Exports Session
 */

import React from 'react';
import pt from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container, Flex, Heading, Paragraph, Icon, Breakpoint, Box, useResponsiveProp } from '@arturpol/systheme';
import { getLink, useRevealTransition } from '../../utils';
import { SecondaryButton } from '../Button';
import { StyledImage } from '../StyledImage';
import { SessionIcon } from '../SessionIcon';
import bg from '../../../content/images/callout-bg.jpg';
import feather from '../../../content/images/feather-callout.svg';

// Unified shadow for image and session details
const BOX_SHADOW = 'out/medium';

// Border around session details
const WRAPPER_BORDER = '1px solid gray/70';

/**
 * React hook
 * Returns button characteristics for the Session component
 * @param {string} sessionId - id of the session page to be opened by the button
 * @returns object ready to be supplied to a <Link> component or <Button>
 */
const useSessionButtonLink = sessionId => {

    const { data } = useStaticQuery(graphql`
        query getSessionButton {
            data(_id: {eq: "site"}) {
                session {
                    buttons {
                        open {
                            anchor
                            title
                        }
                    }
                }
            }
        }
    `);
    
    const { link } = getLink({
        ...data.session.buttons.open,
    });

    return {
        ...link,
        to: sessionId,
    };

};

/**
 * iOS overflow fix
 */
const StyledColumnWrapper = styled(Flex)`
    isolation: isolate;
`;

/**
 * ColumnWrapper React component
 * Contains image and session details wrapper, 
 * determines on overall layout (column for small viewport, row for medium up)
 * @param {object} props 
 */
const ColumnWrapper = props => {

    const revealProps = useRevealTransition();

    props = {
        flexDirection: { small: 'column', medium: 'row', },
        bgColor: { smallOnly: 'gray/90' },
        boxShadow: { smallOnly: BOX_SHADOW, }, 
        borderRadius: { smallOnly: 'medium', },
        overflow: { smallOnly: 'hidden', },
        justifyContent: { small: 'column', medium: 'row', },
        alignItems: 'stretch',
        gutterType: { medium: 'margin', },
        gutter: { medium: 'medium', large: 'xxlarge', },
        border: { smallOnly: WRAPPER_BORDER, },
        ...revealProps,
        ...props,
    };

    return <StyledColumnWrapper { ...props } />;

};

/**
 * Thumbnail React component
 * Wrapper for the image column/row
 * @param {object} params
 */
const Thumbnail = ({ children, ...rest }) => {

    const props = {
        el: 'figure',
        ...rest,
    };

    const imageProps = useResponsiveProp({
        small: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            boxShadow: 'none',
        },
        medium: {
            sticker: true,
            boxShadow: BOX_SHADOW,
        },
    });

    return (
        <Box { ...props }>
            <StyledImage { ...imageProps }>
                { children }
            </StyledImage>
        </Box>
    );

};

/**
 * DetailsWrapper React component
 * Used to hold 2 (small viewport) or 3 (large viewport) vertical sections with session details
 * @param {object} props 
 */
const DetailsWrapper = props => {

    props = {
        backgroundImage: `url(${ bg })`,
        backgroundRepeat: 'repeat',
        flexDirection: 'column',
        alignItems: 'stretch',
        boxShadow: { medium: BOX_SHADOW, },
        ...props,
    };

    return <Flex { ...props } />;

};

/**
 * StyledSessionIcon React component
 * Applies styles over SessionIcon
 * @param {object} params 
 */
const StyledSessionIcon = ({ id, ...rest }) => {
    
    const props = {
        id,
        size: 'xxlarge',
        fill: 'white',
        opacity: 0.2,
        ...rest,
    };

    return <SessionIcon { ...props } />;

};

/**
 * DetailsSection React component
 * Prototype component of any of 3 sections with session details
 * @param {*} props 
 */
const DetailsSection = props => {

    props = {
        flexDirection: 'column',
        justifyContent: 'center',
        borderBottom: '1px solid gray/90',
        padding: { small: 3, large: 4, },
        ...props,
    };

    return <Flex { ...props } />;

};

// Applies gradient on IconSection
const StyledIconSection = styled(DetailsSection)`
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
`;

/**
 * IconSection React component
 * Used only on large viewport, holds an icon in a separate session details section
 * @param {object} params
 * @returns 
 */
const IconSection = ({ id, ...rest }) => {

    const props = {
        alignItems: 'center',
        ...rest,
    };

    return (
        <StyledIconSection { ...props }>
            <StyledSessionIcon id={ id } />
        </StyledIconSection>
    );

};

/**
 * StyledTitleSection React component
 * Adds feather background to TitleSection
 */
const StyledTitleSection = styled(DetailsSection)`
    &::before{
        position: absolute;
        display: block;
        content: " ";
        opacity: 0.05;
        background: url(${ props => props.$image }) 0 0 / contain no-repeat;
        top: -24px;
        right: -96px;
        width: 288px;
        height: 288px;
    }

    & > * {
        position: relative;
    }
`;

/**
 * TitleSection React component
 * Session details section with title and subtitle
 * @param {object} props 
 */
const TitleSection = props => {

    props = {
        el: 'hgroup',
        position: 'relative',
        overflow: 'hidden',
        $image: feather,
        ...props,
    };

    return <StyledTitleSection { ...props } />;

};

// Adds gradient to FooterSection
const StyledFooterSection = styled(DetailsSection)`
    background: linear-gradient(180deg,rgba(255,255,255,0.02) 0%,rgba(0,0,0,0.05) 100%);
`;

/**
 * FooterSection React component
 * Session details section with button that opens session page
 * @param {object} props 
 */
const FooterSection = props => {

    props = {
        el: 'footer',
        alignItems: 'stretch',
        borderBottom: 'none',
        background: 'linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%)',
        rowGap: 3,
        ...props,
    };

    return <StyledFooterSection { ...props } />;

};

/**
 * LocationCaption React component
 * @param {object} props 
 */
const LocationCaption = props => {

    props = {
        kind: 'label',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginBottom: 0,
        ...props,
    };

    return <Paragraph { ...props } />;

};

/**
 * Location React component
 * Contains icon and location caption
 * @param {object} params
 */
const Location = ({ children, ...rest }) => {

    const { typography } = useTheme();
    const fill = typography.bodyPrimaryColor;

    const props = {
        columnGap: 'xsmall',
        alignItems: 'center',
        ...rest,
    };

    return (
        <Flex { ...props }>
            <Icon glyph="pin" size="medium" fill={ fill } />
            <LocationCaption span="stretch">{ children }</LocationCaption>
        </Flex>
    );

};

/**
 * Session React component
 * Session excerpt with a featured image and a link to the session page.
 * @param {object} params
 */
const Session = ({ image, id, portfolio, title, subtitle, location, ...rest }) => {

    const link = useSessionButtonLink(id);

    const props = {
        el: 'article',
        marginBottom: { small: 5, medium: 8, large: 12, },
        ...rest,
    };

    return (
        <Container { ...props }>
            <ColumnWrapper>
                <Thumbnail span={{ small: 'shrink', medium: 7, large: 8, }}>
                    <GatsbyImage { ...image } />
                </Thumbnail>
                <DetailsWrapper span={{ small: 'shrink', medium: 5, large: 4, }} border={{ medium: WRAPPER_BORDER }}>
                    <Breakpoint span="4" large={ 
                        <IconSection id={ portfolio } />
                    } />
                    <TitleSection span={{ small: 6, large: 4, }}>
                        <Breakpoint smallOnly={ 
                            <StyledSessionIcon id={ portfolio } size="xlarge" mb="1" /> 
                        } />
                        <Heading el="h4" kind={{ small: 'h4', mediumOnly: 'h5', }} variant="secondary" mb="1">{ title }</Heading>
                        <Paragraph mb="0">{ subtitle }</Paragraph>
                    </TitleSection>
                    <FooterSection span={{ small: 6, large: 4, }}>
                        <Location>{ location }</Location>
                        <SecondaryButton { ...link } />
                    </FooterSection>
                </DetailsWrapper>
            </ColumnWrapper>
        </Container>
    );

};

Session.propTypes = {

    /**
     * Session page id
     */
    id: pt.string.isRequired,

    /**
     * Session featured image
     */
    image: pt.object.isRequired,

    /**
     * Session's related portfolio id
     * Used to determine the icon to display
     */
    portfolio: pt.string.isRequired,

    /**
     * Session title
     */
    title: pt.string.isRequired,

    /**
     * Session subtitle
     */
    subtitle: pt.string.isRequired,

    /**
     * Session location
     */
    location: pt.string.isRequired,
    
};

export default Session;