import React from 'react';

function Calendar20(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "calendar 20";

	return (
		<svg height={height} width={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth} transform="translate(0.5 0.5)">
		<path d="M0.83 7.47h18.26" fill="none"/>
		<path d="M2.49 2.49h16.6v16.6h-18.26v-16.6h1.66z" fill="none" stroke={fill}/>
		<path d="M4.98 0.83l0 3.32" fill="none" stroke={fill}/>
		<path d="M14.94 0.83l0 3.32" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Calendar20;