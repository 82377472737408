import React from 'react';

function Warning24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 2;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "warning 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M1.243,18.953,10.152,2.111a2.093,2.093,0,0,1,3.7,0l8.909,16.842A2.079,2.079,0,0,1,20.908,22H3.092A2.079,2.079,0,0,1,1.243,18.953Z" fill="none" stroke={fill}/>
		<line fill="none" stroke={secondaryfill} x1="12" x2="12" y1="8" y2="14"/>
		<circle cx="12" cy="17.5" r="1.5" stroke="none"/>
	</g>
</svg>
	);
};

export default Warning24;