/**
 * Exports LanguageCarousel
 */

import React from 'react';
import pt from 'prop-types';
import { useLanguages } from '../../utils';
import { CarouselProvider } from '../Carousel';
import { TagCarousel } from '../TagCarousel';

const SLIDE_CHANGE_INTERVAL_MS = 2000;

/**
 * LanguageCarousel React component
 * Displays host languages in a small carousel with next/previous navigation
 */
const LanguageCarousel = React.forwardRef(({ auto, ...rest }, ref) => {

    const languages = useLanguages();
    const items = languages.map(({ id, caption, image }) => {

        return {
            id,
            image,
            label: caption,
        };

    });

    return (
        <CarouselProvider items={ items } auto={ auto } interval={ SLIDE_CHANGE_INTERVAL_MS }>
            <TagCarousel { ...rest } ref={ ref } />
        </CarouselProvider>
    );

});

LanguageCarousel.propTypes = {

    /**
     * Change slides automatically?
     */
    auto: pt.bool,

};

LanguageCarousel.defaultProps = {
    auto: true,
};

export default LanguageCarousel;
