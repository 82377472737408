import React from 'react';

function Badge48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "badge 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<polyline fill="none" points="28,12 46,12 46,46 2,46 2,12 20,12 " stroke={fill}/>
		<path d="M28,16h-8V6 c0-2.209,1.791-4,4-4h0c2.209,0,4,1.791,4,4V16z" fill="none" stroke={fill}/>
		<path d="M24,39H10 l0,0c0-3.314,2.686-6,6-6h2C21.314,33,24,35.686,24,39L24,39z" fill="none"/>
		<line fill="none" x1="30" x2="39" y1="27" y2="27"/>
		<line fill="none" x1="30" x2="39" y1="35" y2="35"/>
		<circle cx="17" cy="26" fill="none" r="4"/>
	</g>
</svg>
	);
};

export default Badge48;