/**
 * Exports styled image-related design tokens
 */

export const idToGlyph = {
    wedding: 'rings',
    couple: 'love',
    art: 'face',
    family: 'horse',
    event: 'cake',
};