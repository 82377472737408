/**
 * Exports TopMenuProvider, useTopMenuActions, useTopMenuState, MAIN_MENU_ID, PORTFOLIO_MENU_ID,
 */

import React, { useContext, useState, useCallback, useMemo, useRef } from 'react';
import pt from 'prop-types';
import { useTheme } from 'styled-components';
import { durationProp } from '@arturpol/systheme';
import { scrollToTop, useCurrentPageInfo } from '../../utils';
import { PORTFOLIO_NODE_TYPE } from '../../config';

// Close event delay
const CLOSE_DELAY_MS = 150;

/**
 * Two top menu IDs used in open() action shared in TopMenuActionContext
 */
const MAIN_MENU_ID = 'main';
const PORTFOLIO_MENU_ID = 'portfolio';

/**
 * Scroll to top duration
 */
const SCROLL_DURATION = 'long';

/**
 * Initial state of the top menu
 */
const DEFAULT_STATE_CONTEXT = {
    opened: false,
    menuId: MAIN_MENU_ID,
};

/**
 * Two contexts: for actions and for top menu state
 */
const TopMenuActionContext = React.createContext(null);
const TopMenuStateContext = React.createContext(DEFAULT_STATE_CONTEXT);

/**
 * TopMenuProvider React component 
 * Supplies children with two top menu contexts: actions and menu state.
 * @param {object} params 
 */
const TopMenuProvider = ({ children, ...rest }) => {
    
    // Close timeout handler
    const timeoutHandle = useRef(false);

    // Is menu opened?
    const [ opened, setOpened ] = useState(false);

    // Which menu is shown?
    const [ menuId, setMenuId ] = useState(MAIN_MENU_ID);

    const { duration } = useTheme();
    const { collection } = useCurrentPageInfo();

    // If menu isn't specified in open() action, use default menu as specified:
    // Open main menu if current page is not a portfolio (based on page collection name)
    const defaultMenu = collection === PORTFOLIO_NODE_TYPE ? PORTFOLIO_MENU_ID : MAIN_MENU_ID;

    /**
     * Menu open action
     * @param {string} menu - menu id
     * @param {boolean} shouldScrollToTop - true to scroll to top 
     */
    const open = useCallback((menu, shouldScrollToTop = false) => {

        setMenuId(menu ? menu : defaultMenu);

        if(shouldScrollToTop){
            const scrollDuration = durationProp(duration, SCROLL_DURATION);
            scrollToTop(scrollDuration, 0, setOpened.bind(this, true));
        } else {
            setOpened(true);
        }

    }, [ duration, defaultMenu ]);

    /**
     * Menu close action
     */
    const close = useCallback((delay = true) => {

        const duration = delay ? CLOSE_DELAY_MS : 0;
        
        if(timeoutHandle && timeoutHandle.current) clearTimeout(timeoutHandle);
        timeoutHandle.current = setTimeout(() => setOpened(false), duration);

    }, []);

    // Actions context
    const actions = useMemo(() => {
        
        return {
            open,
            close,
        };

    }, [ open, close ]);

    // State context
    const state = useMemo(() => {

        return {
            opened,
            menuId,
        };

    }, [ opened, menuId ]);

    return (
        <TopMenuActionContext.Provider value={ actions }>
            <TopMenuStateContext.Provider value={ state }>
                { children }
            </TopMenuStateContext.Provider>
        </TopMenuActionContext.Provider>
    );

};

TopMenuProvider.propTypes = {

    /**
     * Underlying components, including TopMenu
     */
    children: pt.node.isRequired,

};

/**
 * Helper React hooks
 */
const useTopMenuActions = () => useContext(TopMenuActionContext);
const useTopMenuState = () => useContext(TopMenuStateContext);

export {
    TopMenuProvider,
    useTopMenuActions,
    useTopMenuState,
    MAIN_MENU_ID,
    PORTFOLIO_MENU_ID,
};