import React from 'react';

function Chat48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "chat 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d=" M21.164,38.997C23.693,40.262,26.73,41,30,41c1.179,0,2.326-0.102,3.432-0.284L42,45v-8.081c2.482-2.114,4-4.881,4-7.919 c0-1.038-0.176-2.042-0.506-2.999" fill="none"/>
		<path d="M22,2 C10.954,2,2,9.163,2,18c0,3.594,1.499,6.9,4,9.571V38l9.665-4.833C17.658,33.7,19.783,34,22,34c11.046,0,20-7.163,20-16 S33.046,2,22,2z" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Chat48;