import { token } from '../../utils';

export default token('easing', [{
    key: 'linear',
    value: 'linear',
},{
    key: 'ease-in',
    value: [{
        key: 'weak',
        value: 'cubic-bezier(0.12, 0, 0.39, 0)',
    },{
        key: 'medium',
        value: 'cubic-bezier(0.32, 0, 0.67, 0)',
    },{
        key: 'strong',
        value: 'cubic-bezier(0.64, 0, 0.78, 0)'
    },{
        key: 'back',
        value: 'cubic-bezier(0.36, 0, 0.66, -0.56)',
    }]
},{
    key: 'ease-out',
    value: [{
        key: 'weak',
        value: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
    },{
        key: 'medium',
        value: 'cubic-bezier(0.33, 1, 0.68, 1)',
    },{
        key: 'strong',
        value: 'cubic-bezier(0.22, 1, 0.36, 1)',
    },{
        key: 'back',
        value: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
    }]
},{
    key: 'ease-in-out',
    value: [{
        key: 'weak',
        value: 'cubic-bezier(0.37, 0, 0.63, 1)',
    },{
        key: 'medium',
        value: 'cubic-bezier(0.65, 0, 0.35, 1)',
    },{
        key: 'strong',
        value: 'cubic-bezier(0.83, 0, 0.17, 1)',
    },{
        key: 'back',
        value: 'cubic-bezier(0.68, -0.6, 0.32, 1.6)',
    }]
}]);