/**
 * Helper functions for building navigation based on graphql data
 */

import { hasProperty } from './misc';

/**
 * Transforms raw link data from graphql query into form accepted by Link component
 * @param {object} params 
 * @returns object with 'link' property with Link props and 'context' with remaining content
 */
const getLink = ({ _id, title, external, _page, path, url, anchor, params, ...context }) => {

    const link = {};

    if(typeof _id === 'string') context.id = _id;
    if(typeof title === 'string') link.title = title;
    if(external) link.target = '_blank';
    
    if(typeof _page === 'string') link.to = context.pageId = _page;
    if(typeof path === 'string') link.to = path;
    if(typeof url === 'string') link.to = url;

    if(typeof anchor === 'string') link.children = anchor;
    if(typeof params === 'object' && params !== null) link.params = params; 

    return {
        link,
        context,
    };

};

/**
 * Tranforms links containing social media profile references to a form accepted by Link component
 * @param {object} link - social media link with _socialMedia id
 * @param {object} socialMedia - social media profiles fetched using useSocialMedia() hook
 * @returns object with 'link' property with Link props and 'context' with remaining content
 */
const getSocialMediaLink = (link, socialMedia) => {

    const { _socialMedia, ...rest } = link;
    
    const result = {
        ...rest,
    };

    if(typeof _socialMedia === 'string' && hasProperty(socialMedia, _socialMedia, 'object')){

        const socialMediaData = socialMedia[_socialMedia];
        if(hasProperty(socialMediaData, 'url', 'string')) result.url = socialMediaData.url;

    }

    return getLink(result);

};

export {
    getSocialMediaLink,
    getLink
};