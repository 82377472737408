import { token } from '../../utils';

export default token('fontFamily', [{
    key: 'body',
    value: "Montserrat, Gotham, Raleway, 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif",
},{
    key: 'heading',
    value: "Anabae, 'Noto Serif Display', Didot, 'Playfair Display', 'Noto Serif', serif",
},{
    key: 'monospace',
    value: "'Fira Code', Consolas, Courier, monospace",
}]);