/**
 * Exports SocialMediaItem
 */

import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { Box, Icon, tokenProp } from '@arturpol/systheme';
import { SOCIAL_MEDIA_ID_TO_GLYPH } from '../../../config';
import { Link } from '../../Link';

const getIconGlyph = socialMediaId => {

    return SOCIAL_MEDIA_ID_TO_GLYPH.hasOwnProperty(socialMediaId) ? SOCIAL_MEDIA_ID_TO_GLYPH[socialMediaId] : false;

};

const ListItem = styled(Box)`
    list-style: none;
`;

const StyledLink = styled(Link)`
    &:hover{
        background-color: ${ props => tokenProp(props.theme.color, 'black')};
        color: ${ props => tokenProp(props.theme.color, 'primary/50')};
    }
`;

/**
 * SocialMediaItem React component
 * @param {object} params
 */
const SocialMediaItem = ({ link, context, ...rest }) => {

    const props = {
        el: 'li',
        display: 'inline-block',
        ...rest,
    };

    const linkProps = {
        ...link,
        size: 6,
        p: 1.5,
        borderRadius: 'round',
        backgroundColor: 'gray/90',
        color: 'primary/40',
        display: 'block',
        transition: 'all short linear',
        target: '_blank',
    };

    const iconProps = {
        size: 'medium',
        glyph: getIconGlyph(context.id),
    };

    return (
        <ListItem { ...props }>
            <StyledLink { ...linkProps }>
                <Icon { ...iconProps } />
            </StyledLink>
        </ListItem>
    );

};

SocialMediaItem.propTypes = {

    /**
     * Props for Link component
     */
    link: pt.shape({
        to: pt.string.isRequired,
        title: pt.string,
    }).isRequired,

    /**
     * Context from getLink()
     */
    context: pt.shape({
        id: pt.string.isRequired,    
    }).isRequired,
    
};

export default SocialMediaItem;