/**
 * Exports Article
 */

import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Flex, Paragraph, Heading, useResponsiveProp, Box, tokenProp } from '@arturpol/systheme';
import { Link } from '../Link';

const TRANSITION_DURATION = 'medium';
const TRANSITION_EASING = 'ease-out/medium';

const ArticleTitle = props => {

    const transition = `color ${ TRANSITION_DURATION } ${ TRANSITION_EASING }`;

    props = {
        kind: 'h5',
        variant: 'secondary',
        transition,
        ...props,
    };

    return <Heading { ...props } />;

}

const ColumnWrapper = ({ large, ...rest }) => {

    const flexDirection = large ? { small: 'column', medium: 'row', } : { small: 'column', large: 'row', };

    const props = {
        minHeight: '100%',
        flexDirection,
        alignItems: 'stretch',
        ...rest,
    };

    return <Flex { ...props } />

};

const StyledImageWrapper = styled(Box)`
    .gatsby-image-wrapper {
        transition: transform ${ props => tokenProp(props.theme.duration, TRANSITION_DURATION) } ${ props => tokenProp(props.theme.easing, TRANSITION_EASING) };
    }
`;

const ImageWrapper = ({ image, large, ...rest }) => {

    const imageForLarge = useResponsiveProp({
        small: image.image.wide,
        medium: image.image.square,
    });

    const imageForRegular = useResponsiveProp({
        small: image.image.wide,
        large: image.image.tall,
    });

    const img = large ? imageForLarge : imageForRegular;

    const heightForLarge = useResponsiveProp({
        small: false,
        medium: '100%',
    });

    const heightForRegular = useResponsiveProp({
        small: false,
        large: '100%',
    });

    const height = large ? heightForLarge : heightForRegular;
    const style = height ? { height, } : {};

    const props = {
        el: 'figure',
        overflow: 'hidden',
        ...rest,
    };

    return (
        <StyledImageWrapper { ...props }>
            <GatsbyImage alt={ image.alt } image={ img } style={ style } />
        </StyledImageWrapper>
    );

};

const TextWrapper = ({ large, ...rest }) => {
    
    const justifyContent = large ? { medium: 'center', } : { large: 'center', };
    const padding = large ? { small: 3, medium: 5, } : 3;

    const props = {
        el: 'hgroup',
        padding,
        flexDirection: 'column',
        justifyContent,
        alignItems: 'stretch',
        ...rest,
    };

    return <Flex { ...props } />;

};

const StyledLink = styled(Link)`
    isolation: isolate;


    &:hover .gatsby-image-wrapper {
        transform: scale(1.15);
    }
 
    &:hover h5 {
        color: ${ props => tokenProp(props.theme.color, 'white') };
    }
`;

/**
 * Article React component
 * Article card with an excerpt
 * @param {object} params 
 */
const Article = ({ id, excerpt, image, title, large, ...rest }) => {

    large = useResponsiveProp(large);

    const props = {
        to: id,
        display: 'block',
        minHeight: '100%',
        backgroundColor: 'rgba(18,18,18,0.75)',
        borderRadius: 'medium',
        overflow: 'hidden',
        ...rest,
    };

    const textSpan = large ? { small: 'shrink', medium: 'stretch', } : { small: 'shirnk', large: 'stretch', };

    return (
        <StyledLink { ...props }>
            <ColumnWrapper large={ large }>
                <ImageWrapper large={large} image={ image } span="shrink" />
                <TextWrapper span={ textSpan } large={ large }>
                    <ArticleTitle>{ title }</ArticleTitle>
                    <Paragraph kind={ large ? 'body' : 'label' }>{ excerpt }</Paragraph>
                </TextWrapper>
            </ColumnWrapper>
        </StyledLink>
    );

};

Article.propTypes = {

    /**
     * Article page id
     */
    id: pt.string.isRequired,

    /**
     * Article excerpt
     */
    excerpt: pt.string.isRequired,

    /**
     * Article featured image
     */
    image: pt.object.isRequired,

    /**
     * Article title
     */
    title: pt.string.isRequired,

    /**
     * Is large?
     */
    large: pt.oneOfType([pt.bool, pt.object]),

};

Article.defaultProps = {
    large: false,
};

export default Article;