/**
 * Exports PortfolioMenuItem
 */

import React, { useCallback } from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Flex, Text, tokenProp } from '@arturpol/systheme';
import { usePath } from '../../Paths';
import { Link } from '../../Link';
import { SessionIcon } from '../../SessionIcon';
import { useTopMenuActions } from '../Context';

const StyledListItem = styled(Box)`
    list-style: none;
`;

const ListItem = props => {

    props = {
        el: 'li',
        ...props,
    };

    return <StyledListItem { ...props } />;

};

const Title = ({ large, ...rest }) => {

    const props = {
        el: 'h5',
        kind: (large ? 'h/400' : 'h/300' ),
        color: 'white',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginBottom: 0,
        paddingTop: 0.5,
        ...rest,
    };

    return <Text { ...props } />;

};

const StyledContent = styled(Flex)`
    background: linear-gradient(180deg, rgba(18, 18, 18, 0.8) 0%, rgba(18, 18, 18, 0.9) 50%, rgba(18, 18, 18, 0.8) 100%);
    isolation: isolate;
    
    &::before{
        position: absolute;
        display: block;
        content: " ";
        top: calc(50% - 1.5rem);
        left: 0;
        height: 3rem;
        width: 4px;
        border-radius: 0 4px 4px 0;
        background-color: ${ props => tokenProp(props.theme.color, 'primary/50') };
        opacity: ${ props => props.$active ? '1' : '0' };
    }
`;

const Content = ({ portfolio, title, active, large, ...rest }) => {

    const props = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        padding: '2 3',
        columnGap: 'small',
        transition: 'background long linear',
        $active: active,
        ...rest,
    };

    if(large){
        props.padding = '3 6';
        props.columnGap = 'medium';
    }

    const iconSize = large ? 'xlarge' : 'medium';
    
    return (
        <StyledContent { ...props }>
            <SessionIcon id={ portfolio } fill="primary/50" size={ iconSize } />
            <Title large={ large }>{ title }</Title>
        </StyledContent>
    );

};

/**
 * Overrides default opacity deriven from 'dim' property of PortfolioMenuItem
 */
const StyledLink = styled(Link)`
    &:hover {
        opacity: 1;
    }

    &:hover ${StyledContent}{
        background: linear-gradient(180deg, rgba(18, 18, 18, 0.66) 0%, rgba(18, 18, 18, 0.75) 50%, rgba(18, 18, 18, 0.66) 100%);
    }
`;

/**
 * PortfolioMenuItem React component
 * @param {object} params 
 */
const PortfolioMenuItem = ({ link, context, current, image, large, dim, ...rest }) => {

    // Close menu on tap
    const { close } = useTopMenuActions();
    const path = usePath(context.pageId);

    const callback = useCallback(() => close(), [ close ]);
    const { children, ...linkRest } = link;

    // Active if current page path is the same
    let isActive = current && current.path === path;
    dim = dim && !isActive;
    
    const linkProps = {
        ...linkRest,
        display: 'block',
        position: 'relative',
        onClick: callback,
        overflow: 'hidden',
        opacity: ( dim ? 0.75 : 1),
        transition: 'opacity short linear',
    };

    if(large) linkProps.maxHeight = 12;

    return (
        <ListItem { ...rest }>
            <StyledLink { ...linkProps }>
                <GatsbyImage { ...image } />
                <Content portfolio={ context.pageId } title={ children } active={ isActive } large={ large } />
            </StyledLink>
        </ListItem>
    );

};

PortfolioMenuItem.propTypes = {

    /**
     * Props for Link component
     */
    link: pt.shape({
        children: pt.string.isRequired,
        to: pt.string,
        title: pt.string,
    }).isRequired,

    /**
     * Context from getLink()
     */
    context: pt.shape({
        pageId: pt.string.isRequired,
    }).isRequired,

    /**
     * Current page info
     */
    current: pt.shape({
        path: pt.string,
        collection: pt.string,
    }),

    /**
     * Props supplied to GatsbyImage as item background
     */
    image: pt.shape({
        alt: pt.string.isRequired,
        image: pt.object.isRequired,
    }),

    /**
     * Render large (mostly for larger viewports)
     */
    large: pt.bool,

    /**
     * Should dim the item with an exception when there is a pointer over it?
     */
    dim: pt.bool,

};

PortfolioMenuItem.defaultProps = {
    large: false,
    dim: false,
};

export default PortfolioMenuItem;