/**
 * Exports PrimaryButton
 */

import React from 'react';
import { Button } from '@arturpol/systheme';
import { Link } from '../Link';

/**
 * PrimaryButton React component
 * Accepts Link properties such as "to", otherwise remains rendered as <button>
 * @param {object} params
 */
const PrimaryButton = ({ to, ...rest }) => {

    const props = {
        ...rest,
    };

    if(typeof to === 'string'){
        
        // Render as Link if "to" prop is specified
        props.el = Link;
        props.to = to;

    }

    return <Button { ...props } />;
    
};

export default PrimaryButton;