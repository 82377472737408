/**
 * Exports PortfolioMenuList
 */

import React from 'react';
import pt from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useResponsiveProp, Box } from '@arturpol/systheme';
import { useCurrentPageInfo, getLink, hasProperty } from '../../../utils';
import { usePath } from '../../Paths';
import PortfolioMenuItem from './Item';

/**
 * React hook
 * @returns portfolio menu items as links
 */
const usePortfolioMenuItems = () => {

    const data = useStaticQuery(graphql`
        query getPortfolioMenuItems {
            menu(_id: {eq: "portfolio"}) {
                items {
                    _id
                    _page
                    anchor
                    title
                    url
                    image {
                        alt
                        src {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED, width: 976)
                            }
                        }
                    }
                }
            }
        }
    `);
    
    return data.menu.items.map(({ image, ...item }) => {

        const link = getLink(item);
        
        return {
            ...link,
            image: {
                alt: image.alt,
                image: getImage(image.src),
            },
        };

    });

};

/**
 * React hook
 * Returns true if at least one item is the current page
 * @param {array} items 
 * @param {string} currentPath 
 */
const useHasCurrentPage = (items, currentPath) => {

    const paths = usePath();
    let isCurrent = false;
    
    items.forEach(({ context }) => {
        
        const path = hasProperty(paths, context.pageId, 'string') ? paths[context.pageId] : false;
        if(path && path === currentPath) isCurrent = true;
        
    });

    return isCurrent;

};

/**
 * PortfolioMenuList React component
 * @param {object} props 
 */
const PortfolioMenuList = ({ large, ...rest }) => {
    
    const items = usePortfolioMenuItems();

    // Needed to determine active item
    const current = useCurrentPageInfo();

    // Determines whether among all items there is currently opened page
    const hasCurrent = useHasCurrentPage(items, current.path);

    large = useResponsiveProp(large);

    const props = {
        el: 'ul',
        minHeight: 50,
        ...rest,
    };
    
    return (
        <Box { ...props }>
            { items.map(({ link, context, image }) => {

                return <PortfolioMenuItem link={ link } context={ context } current={ current } image={ image } large={ large } dim={ hasCurrent } key={ context.id } />

            })}
        </Box>
    );

};

PortfolioMenuList.propTypes = {

    /**
     * Render large items?
     */
    large: pt.oneOfType([ pt.bool, pt.object ]),

};

PortfolioMenuList.defaultProps = {
    large: false,
};

export default PortfolioMenuList;