import { token } from '../../utils';

export default token('zIndex', [{
    key: 'back',
    order: -1,
    value: -1,
},{
    key: 'default',
    order: 0,
    value: 0,
},{
    key: 'level-1',
    order: 16,
    value: 16,
},{
    key: 'level-2',
    order: 32,
    value: 32,
},{
    key: 'form',
    order: 512,
    value: 512,
},{
    key: 'modal',
    order: 1024,
    value: 1024,
},{
    key: 'modal-form',
    order: 1536,
    value: 1536,
},{
    key: 'top',
    order: 2048,
    value: 2048,
}]);