/**
 * Exports ImageCarousel
 */

import React from 'react';
import pt from 'prop-types';
import { CarouselProvider } from '../Carousel';
import CarouselWrapper from './Wrapper';

/**
 * ImageCarousel React component
 * Carousel rotating images linked to a page
 * @param {object} params
 */
const ImageCarousel = ({ items, page, initialIndex, previousTitle, nextTitle, ...rest }) => {

    return (
        <CarouselProvider items={ items } auto={ false } initialIndex={ initialIndex }>
            <CarouselWrapper page={ page } previousTitle={ previousTitle } nextTitle={ nextTitle } { ...rest } />
        </CarouselProvider>
    );

};

ImageCarousel.propTypes = {

    /**
     * Image slides
     */
    items: pt.arrayOf(pt.object).isRequired,

    /**
     * Page id to link
     */
    page: pt.string,

    /**
     * Initital slide index
     */
    initialIndex: pt.number,

    /**
     * Previous slide button title
     */
    previousTitle: pt.string,

    /**
     * Next slide button title
     */
    nextTitle: pt.string,
    
};

export default ImageCarousel;