/**
 * Exports TagSlideList
 */

import React from 'react';
import pt from 'prop-types';
import { Flex } from '@arturpol/systheme';
import TagSlide from './Slide';

/**
 * TagSlideList React component
 * Displays list of slides in the TagCarousel
 */
const TagSlideList = React.forwardRef(({ items, current, ...rest }, ref) => {

    const props = {
        el: 'ul',
        flexDirection: 'column',
        alignItems: 'stretch',
        alignContent: 'stretch',
        posiiton: 'relative',
        overflow: 'hidden',
        ...rest,
        ref,
    };

    return (
        <Flex { ...props }>
            { items.map((item, index) => {

                const props = { ...item };
                if(current !== index) props.hidden = true;
                return <TagSlide { ...props } key={ index } />;

            })}
        </Flex>
    );

});

TagSlideList.propTypes = {

    /**
     * Slide items
     */
    items: pt.arrayOf(pt.shape({
        label: pt.node.isRequired,
        icon: pt.string,
        image: pt.object,
    })).isRequired,

    /**
     * Current slide as 0-based index in the 'items' array
     */
    current: pt.number.isRequired,

};

export default TagSlideList;