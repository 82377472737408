import React from 'react';

import { Paragraph, Heading, Text, Ul, Ol, Li, Ruler as Hr } from '@arturpol/systheme';
import { TextLink } from '../Link';

const P = props => <Paragraph kind="body" { ...props } />;

const H1 = props => <Heading kind="h1" variant="secondary" mt="4" { ...props } />;
const H2 = props => <Heading kind="h2" variant="secondary" mt="4" { ...props } />;
const H3 = props => <Heading kind="h3" variant="secondary" mt="4" { ...props } />;
const H4 = props => <Heading kind="h4" variant="secondary" mt="4" { ...props } />;
const H5 = props => <Heading kind="h5" variant="secondary" mt="4" { ...props } />;
const H6 = props => <Heading kind="h6" variant="secondary" mt="4" { ...props } />;

const Blockquote = props => <Text el="blockquote" kind="quote" { ...props } />;

const A = ({ href, ...rest }) => <TextLink { ...rest } to={ href } />;

export {
    P,
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
    Blockquote,
    Ul,
    Ol,
    Li,
    Hr,
    A,
};