/**
 * Exports getNewsArticleSchema
 */

const getImage = ({ ratio1x1, ratio4x3, ratio16x9 }, baseUrl) => {

    return [ ratio1x1, ratio4x3, ratio16x9 ].map(img => {
        return `${ baseUrl }${ img.childImageSharp.fixed.src }`;
    });

};

const getPersonSchema = ({ name, url }, baseUrl) => {

    return {
        '@type': 'Person',
        name,
        url: `${ baseUrl }${ url }`,
    };

};

const getNewsArticleSchema = ({ title, image, date, author }, baseUrl) => {

    return {
        '@type': 'NewsArticle',
        headline: title,
        image: getImage(image, baseUrl),
        datePublished: date,
        author: getPersonSchema(author, baseUrl),
    };

};

export default getNewsArticleSchema;