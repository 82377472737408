/**
 * Exports PageWithModal
 */

import React, { useRef, useCallback, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Drawer, Box } from '@arturpol/systheme';
import PageLayout from './Page';

/**
 * Wraps Gatsby's page content and renders it either as normal page or as a modal, 
 * depending on the page collection configuration (see config.js).
 * @param {object} props 
 */
const PageWithModal = props => {
    
    // Preserves page props from the previous request, so they can be used to render the page content behind modal's backdrop
    const pageProps = useRef(null);

    const { pageContext, path, location } = props;

    // Reading collection config for this page
    const { modal, fallbackParentPath, modalOptions } = pageContext;

    let redirectToFallbackPage = false, redirectFromFallbackPage = false;

    if(modal){
        // If current page is a modal and we don't have previous' page props, set flag to redirect to the fallback page for this collection
        redirectToFallbackPage = pageProps.current === null;
    }else{
        // Save current page props
        pageProps.current = props;
        // If this page has been opened only to fetch its props, redirect to the original modal page
        redirectFromFallbackPage = location && location.state && location.state.redirect;
    }
    
    useEffect(() => {

        if(redirectToFallbackPage){
            // try to fetch parent page props first...
            navigate(fallbackParentPath, { 
                replace: true, 
                state: { redirect: path }
            });
        }

        if(redirectFromFallbackPage) {
            // then return to the original page with modal...
            navigate(location.state.redirect, { 
                replace: true 
            });
        }

    });

    // Close modal by redirecting user to the previous page
    const closeModal = useCallback(() => navigate(pageProps.current.path), []);

    // Open modal only if current page is a modal and we don't need to redirect
    const opened = !!(modal && !redirectToFallbackPage);

    let modalChildren = null;

    if(modal){
        
        // Supply close() action to modal child using props
        modalChildren = React.Children.map(props.children, child => {

            if(!React.isValidElement(child)) return child;
            return React.cloneElement(child, { close: closeModal });

        });

    }

    const modalProps = {
        ...modalOptions,
        opened,
        onClose: closeModal,
        children: modalChildren,
    };

    // All this magic is to render content of the modal on the server-side (for SEO) as a regular page
    // This content is then removed on the client, so only one copy exists - in the actual modal
    const pagePropsObject = pageProps.current ? pageProps.current : {};
    const { children, ...layoutProps } = modal ? pagePropsObject : props;
    const ssrModalContent = <Box el="dialog" display="none">{ modalProps.children }</Box>
    const isSSR = modal && !opened;
    
    return (
        <>
            <PageLayout { ...layoutProps }>
                { isSSR ? ssrModalContent : null }
                { children }
            </PageLayout>
            <Drawer { ...modalProps } />
        </>
    );

};

export default PageWithModal;