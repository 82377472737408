import { token } from '../../utils';

export default token('borderRadius', [{
    key: 'none',
    value: '0px',
    order: 0,
},{
    key: 'small',
    value: '4px',
    order: 4,
},{
    key: 'medium',
    value: '16px',
    order: 16,
},{
    key: 'large',
    value: '32px',
    order: 32,
},{
    key: 'xlarge',
    value: '64px',
    order: 64,
},{
    key: 'xxlarge',
    value: '192px',
    order: 192,
},{
    key: 'round',
    value: '99999px',
    order: 5,
}]);