/**
 * Exports FooterTextBlock
 */

import React from 'react';
import pt from 'prop-types';
import { Box, H } from '@arturpol/systheme';
import { Mdx } from '../Mdx';

/**
 * FooterTextBlock React component
 * @param {object} params
 */
const FooterTextBlock = ({ title, description, ...rest }) => {
    
    const props = {
        el: 'article',
        mb: { mediumDown: 3 },
        ...rest,
    };

    return (
        <Box { ...props }>
            <H kind="h5" variant="secondary">{ title }</H>
            <Mdx>{ description }</Mdx>
        </Box>
    );

};

FooterTextBlock.propTypes = {

    /**
     * Block title 
     */
    title: pt.string.isRequired,

    /**
     * Description in Mdx format
     */
    description: pt.any.isRequired,

};

export default FooterTextBlock;