/**
 * Exports toast-related design tokens
 */

import { token } from '../utils';

// Delay after which toast will be closed automatically
export const defaultRemoveDurationMs = 3000;

// Exit/enter transition duration
export const transitionDuration = 'medium';

// Enter/exit animation y axis distance
export const transtionSlideDistance = 2;

// Transitions used in enter/exit animations
export const transitionEasingIn = 'ease-in/medium';
export const transitionEasingOut = 'ease-out/medium';

// Vertical gap in ToastContainer between individual toast messages
export const gapBetweenToasts = 1;

// Margin between the screen and toast message
export const containerVerticalPadding = 2;
export const containerHorizontalPadding = 2;

// Individual toast config starts here...

export const borderWidth = '4px';
export const borderRadius = 'small';
export const boxShadow = 'out/medium';

// Horizontal gap between icon, buttons and texts
export const innerGap = 1;

// Both for regular icon and close button icon
export const iconSize = 'small';

// Text component 'kind' prop
export const titleTextKind = 'label';
export const titleBottomMargin = 0.5;

// Text component 'kind' prop
export const descriptionTextKind = 'p/caption';

// Toast padding
export const verticalPadding = '1';
export const horizontalPadding = '2';

// Optional action button props
export const buttonSize = 'small';
export const buttonKind = 'text';

// Close icon button glyph name
export const closeButtonGlyph = 'close';

// Toast colours defined as variants
export const variant = token('toastVariant', [{
    key: 'primary',
    value: {
        backgroundColor: 'gray/80',
        border: 'primary/50',
        icon: 'primary/50',
        closeButtonVariant: 'gray',
        buttonVariant: 'primary',
    },
},{
    key: 'success',
    value: {
        backgroundColor: 'gray/80',
        border: 'success/50',
        icon: 'success/50',
        closeButtonVariant: 'gray',
        buttonVariant: 'primary',
    },
},{
    key: 'alert',
    value: {
        backgroundColor: 'gray/80',
        border: 'alert/50',
        icon: 'alert/50',
        closeButtonVariant: 'gray',
        buttonVariant: 'primary',
    },
}]);

// Default colour variant
export const defaultVariant = 'primary';