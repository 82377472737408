/**
 * Sets up base unit to specify sizes and spaces in styles.
 * Exports global size tokens and helper functions.
 */

import { sizeProvider } from '../utils';

const baseUnitPx = 8;
const baseFontSizePx = 16;

const { size, rem, em, px } = sizeProvider(baseUnitPx, baseFontSizePx);

const baseUnit = size(`${baseUnitPx}px`);
const baseFontSize = size(`${baseFontSizePx}px`);

export {
    baseFontSize,
    baseUnitPx,
    baseUnit,
    size,
    rem,
    em,
    px,
};