/**
 * Exports getSchema
 */

import React from 'react';
import { getGeneralSchema } from './general';
import getFaqPageSchema from './faqPage';
import getHowToSchema from './howTo';
import getBreadcrumbListSchema from './breadcrumbList';
import getNewsArticleSchema from './newsArticle';
import getOrganizationSchema from './organization';
import getProductSchema from './product';

/**
 * Returns LD+JSON schema for SEO purposes of selected types
 * supported by Google e.g. HowTo
 * Resulting <script> tag should be placed in <head> section of HTML page
 * @param {object} data - object with keys such as 'howTo' or 'faq' to determine schema type and value being data
 * required by particular formatting function (see individual requirements)
 * @param {string} baseUrl - website base URL
 * @returns React node
 */
const getSchema = (data, baseUrl) => {

    const general = getGeneralSchema();
    let result = [];

    for(let i in data){

        if(!data.hasOwnProperty(i)) continue;
        
        switch(i){
            case 'faqPage':
                result.push(getFaqPageSchema(data[i], baseUrl));
                break;
            case 'howTo':
                result.push(getHowToSchema(data[i], baseUrl));
                break;
            case 'breadcrumbList':
                result.push(getBreadcrumbListSchema(data[i], baseUrl));
                break;
            case 'newsArticle':
                result.push(getNewsArticleSchema(data[i], baseUrl));
                break;
            case 'organization':
                result.push(getOrganizationSchema(data[i], baseUrl));
                break;
            case 'product':
                result.push(getProductSchema(data[i], baseUrl));
                break;
            default:
                break;
        }

    }

    if(result.length <= 0) return null;
    result = result.map(schema => ({ ...general, ...schema }));
    const obj = result.length === 1 ? result[0] : result;
    const json = JSON.stringify(obj);
    return <script type="application/ld+json">{ json }</script>;

};

export default getSchema;