/**
 * Exports image-related design tokens
 */

const mobileGap = 2;
const mobileMarginBottom = mobileGap;

const mobile = {
    gap: mobileGap,
    marginBottom: mobileMarginBottom,
};

// Gutter prop names
export const allowedGaps = [ 'small', 'large' ];

export const gap = {
    // Viewport size
    small: {
        // Gutter and margin size
        small: { ...mobile },
        large: { ...mobile },
    },
    medium: {
        small: { ...mobile },
        large: {
            gap: 3,
            marginBottom: 3,
        },
    },
    large: {
        small: { ...mobile },
        large: {
            gap: 8,
            marginBottom: 8,
        },
    },
};

// Used when no 'gap' property is set
export const defaultGap = 'small';
export const defaultMarginBottom = 'small';

// Allowed image column 'widths' 
export const allowedWidths = [ 3, 4, 5, 6, 7, 8, 9, 10 ];
