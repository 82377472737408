/**
 * Website configuration for browser-based interface
 */

/**
 * Portfolio node name as used in the graphql
 */
export const PORTFOLIO_NODE_TYPE = 'portfolio';
export const ARTICLE_NODE_TYPE = 'article';

/**
 * Object associating icon ids to feature ids listed in
 * /content/data/feature.yml
 */
export const FEATURE_ICONS = {
    safety: 'safe',
    covid: 'virus',
    church: 'badge',
    uniqueness: 'submarine',
    friendliness: 'spa',
    creativity: 'wand',
    network: 'users',
    mother: 'baby-clothes',
    advisor: 'users',
};

/**
 * Icons corresponding to socialMediaIds in
 * /content/data/site.yml
 */
export const SOCIAL_MEDIA_ID_TO_GLYPH = {
    facebook: 'facebook',
    instagram: 'instagram',
    youtube: 'youtube',
};