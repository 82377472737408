exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-about-js-content-file-path-opt-build-repo-content-about-about-mdx": () => import("./../../../src/templates/About/About.js?__contentFilePath=/opt/build/repo/content/about/about.mdx" /* webpackChunkName: "component---src-templates-about-about-js-content-file-path-opt-build-repo-content-about-about-mdx" */),
  "component---src-templates-article-article-js-content-file-path-opt-build-repo-content-article-dobra-fotografia-index-mdx": () => import("./../../../src/templates/Article/Article.js?__contentFilePath=/opt/build/repo/content/article/dobra-fotografia/index.mdx" /* webpackChunkName: "component---src-templates-article-article-js-content-file-path-opt-build-repo-content-article-dobra-fotografia-index-mdx" */),
  "component---src-templates-article-article-js-content-file-path-opt-build-repo-content-article-faq-index-mdx": () => import("./../../../src/templates/Article/Article.js?__contentFilePath=/opt/build/repo/content/article/faq/index.mdx" /* webpackChunkName: "component---src-templates-article-article-js-content-file-path-opt-build-repo-content-article-faq-index-mdx" */),
  "component---src-templates-article-article-js-content-file-path-opt-build-repo-content-article-inkluzywna-fotografia-index-mdx": () => import("./../../../src/templates/Article/Article.js?__contentFilePath=/opt/build/repo/content/article/inkluzywna-fotografia/index.mdx" /* webpackChunkName: "component---src-templates-article-article-js-content-file-path-opt-build-repo-content-article-inkluzywna-fotografia-index-mdx" */),
  "component---src-templates-article-article-js-content-file-path-opt-build-repo-content-article-przygotowanie-przed-sesja-index-mdx": () => import("./../../../src/templates/Article/Article.js?__contentFilePath=/opt/build/repo/content/article/przygotowanie-przed-sesja/index.mdx" /* webpackChunkName: "component---src-templates-article-article-js-content-file-path-opt-build-repo-content-article-przygotowanie-przed-sesja-index-mdx" */),
  "component---src-templates-article-article-js-content-file-path-opt-build-repo-content-article-umowa-z-fotografem-index-mdx": () => import("./../../../src/templates/Article/Article.js?__contentFilePath=/opt/build/repo/content/article/umowa-z-fotografem/index.mdx" /* webpackChunkName: "component---src-templates-article-article-js-content-file-path-opt-build-repo-content-article-umowa-z-fotografem-index-mdx" */),
  "component---src-templates-blog-blog-js-content-file-path-opt-build-repo-content-blog-blog-mdx": () => import("./../../../src/templates/Blog/Blog.js?__contentFilePath=/opt/build/repo/content/blog/blog.mdx" /* webpackChunkName: "component---src-templates-blog-blog-js-content-file-path-opt-build-repo-content-blog-blog-mdx" */),
  "component---src-templates-contact-contact-js-content-file-path-opt-build-repo-content-contact-contact-mdx": () => import("./../../../src/templates/Contact/Contact.js?__contentFilePath=/opt/build/repo/content/contact/contact.mdx" /* webpackChunkName: "component---src-templates-contact-contact-js-content-file-path-opt-build-repo-content-contact-contact-mdx" */),
  "component---src-templates-home-home-js-content-file-path-opt-build-repo-content-home-home-mdx": () => import("./../../../src/templates/Home/Home.js?__contentFilePath=/opt/build/repo/content/home/home.mdx" /* webpackChunkName: "component---src-templates-home-home-js-content-file-path-opt-build-repo-content-home-home-mdx" */),
  "component---src-templates-landing-index-landing-index-js-content-file-path-opt-build-repo-content-landing-index-landing-index-mdx": () => import("./../../../src/templates/LandingIndex/LandingIndex.js?__contentFilePath=/opt/build/repo/content/landingIndex/landingIndex.mdx" /* webpackChunkName: "component---src-templates-landing-index-landing-index-js-content-file-path-opt-build-repo-content-landing-index-landing-index-mdx" */),
  "component---src-templates-landing-landing-js": () => import("./../../../src/templates/Landing/Landing.js" /* webpackChunkName: "component---src-templates-landing-landing-js" */),
  "component---src-templates-offer-offer-js-content-file-path-opt-build-repo-content-offer-offer-mdx": () => import("./../../../src/templates/Offer/Offer.js?__contentFilePath=/opt/build/repo/content/offer/offer.mdx" /* webpackChunkName: "component---src-templates-offer-offer-js-content-file-path-opt-build-repo-content-offer-offer-mdx" */),
  "component---src-templates-portfolio-portfolio-js": () => import("./../../../src/templates/Portfolio/Portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-portfolio-js" */),
  "component---src-templates-product-product-js": () => import("./../../../src/templates/Product/Product.js" /* webpackChunkName: "component---src-templates-product-product-js" */),
  "component---src-templates-proposal-proposal-js": () => import("./../../../src/templates/Proposal/Proposal.js" /* webpackChunkName: "component---src-templates-proposal-proposal-js" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-art-2022-rimma-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/art-2022-rimma/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-art-2022-rimma-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-couple-2020-marta-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/couple-2020-marta/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-couple-2020-marta-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-couple-2021-ola-radek-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/couple-2021-ola-radek/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-couple-2021-ola-radek-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-couple-2022-magdalena-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/couple-2022-magdalena/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-couple-2022-magdalena-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-couple-2023-iga-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/couple-2023-iga/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-couple-2023-iga-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-couple-2023-iwona-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/couple-2023-iwona/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-couple-2023-iwona-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-event-2021-baptism-filip-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/event-2021-baptism-filip/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-event-2021-baptism-filip-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-event-2021-baptism-nastazja-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/event-2021-baptism-nastazja/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-event-2021-baptism-nastazja-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-event-2021-baptism-zuza-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/event-2021-baptism-zuza/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-event-2021-baptism-zuza-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-event-2022-baptism-maja-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/event-2022-baptism-maja/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-event-2022-baptism-maja-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-family-2022-dunya-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/family-2022-dunya/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-family-2022-dunya-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-family-2022-marta-tymek-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/family-2022-marta-tymek/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-family-2022-marta-tymek-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-family-2023-janina-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/family-2023-janina/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-family-2023-janina-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-family-2023-natalia-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/family-2023-natalia/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-family-2023-natalia-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-session-template-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/session-template/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-session-template-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2018-dobrochna-przemyslaw-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/wedding-2018-dobrochna-przemyslaw/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2018-dobrochna-przemyslaw-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2018-dominika-patryk-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/wedding-2018-dominika-patryk/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2018-dominika-patryk-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2018-martyna-tomek-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/wedding-2018-martyna-tomek/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2018-martyna-tomek-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2020-ania-mateusz-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/wedding-2020-ania-mateusz/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2020-ania-mateusz-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2021-ola-radek-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/wedding-2021-ola-radek/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2021-ola-radek-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2022-ania-mateusz-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/wedding-2022-ania-mateusz/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2022-ania-mateusz-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2023-estera-norbert-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/wedding-2023-estera-norbert/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2023-estera-norbert-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2023-kalina-dawid-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/wedding-2023-kalina-dawid/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2023-kalina-dawid-index-mdx" */),
  "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2023-magdalena-pawel-index-mdx": () => import("./../../../src/templates/Session/Session.js?__contentFilePath=/opt/build/repo/content/session/wedding-2023-magdalena-paweł/index.mdx" /* webpackChunkName: "component---src-templates-session-session-js-content-file-path-opt-build-repo-content-session-wedding-2023-magdalena-pawel-index-mdx" */),
  "component---src-templates-store-store-js-content-file-path-opt-build-repo-content-store-store-mdx": () => import("./../../../src/templates/Store/Store.js?__contentFilePath=/opt/build/repo/content/store/store.mdx" /* webpackChunkName: "component---src-templates-store-store-js-content-file-path-opt-build-repo-content-store-store-mdx" */),
  "component---src-templates-webpage-webpage-js-content-file-path-opt-build-repo-content-webpage-polityka-prywatnosci-mdx": () => import("./../../../src/templates/Webpage/Webpage.js?__contentFilePath=/opt/build/repo/content/webpage/polityka-prywatnosci.mdx" /* webpackChunkName: "component---src-templates-webpage-webpage-js-content-file-path-opt-build-repo-content-webpage-polityka-prywatnosci-mdx" */)
}

