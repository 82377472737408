/**
 * Exports useArticles
 */

import { useStaticQuery, graphql } from 'gatsby';
import { getArticle } from '../helpers';
import * as fragments from '../../fragments'; // eslint-disable-line

/**
 * React hook
 * @returns all articles sorted descending according to the 'order' property
 */
const useArticles = () => {

    const { allMdx } = useStaticQuery(graphql`
        query getArticles {
            allMdx(
                filter: {fields: {collection: {eq: "article"}}}
                sort: {frontmatter: {order: DESC}}
            ) {
                nodes {
                    ...ArticleFragment
                }
            }
        }
    `);

    return allMdx.nodes.map(node => getArticle(node));

};

export default useArticles;