import React from 'react';

function Rings24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "rings 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<polyline fill="none" points="7.845 7.017 10 4 10 3 5 3 5 4 7.155 7.017"/>
		<path d="M13.669,15.54a6.509,6.509,0,1,0-4.323,4.19" fill="none"/>
		<path d="M10.331,11.46a6.509,6.509,0,1,0,4.323-4.19" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Rings24;