/**
 * Exports FooterSocialMediaItem
 */

import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { Flex, P, Icon, tokenProp } from '@arturpol/systheme';
import { SOCIAL_MEDIA_ID_TO_GLYPH } from '../../../config';
import { Link } from '../../Link';

const getIconGlyph = socialMediaId => {

    return SOCIAL_MEDIA_ID_TO_GLYPH.hasOwnProperty(socialMediaId) ? SOCIAL_MEDIA_ID_TO_GLYPH[socialMediaId] : false;

};

const ListItem = props => {

    props = {
        el: 'li',
        alignItems: 'center',
        alignContent: 'center',
        gap: 'small',
        ...props,
    };

    return <Flex { ...props } />;

};

const StyledListItem = styled(ListItem)`
    list-style: none;
`;

const Caption = props => {

    props = {
        kind: 'caption',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: 0,
        ...props,
    };

    return <P { ...props } />

};

const SocialIcon = ({ id, ...rest }) => {

    const glyph = getIconGlyph(id);

    const props = {
        size: 5,
        p: 1,
        borderRadius: 'round',
        backgroundColor: 'gray/90',
        color: 'primary/40',
        display: 'block',
        transition: 'all short linear',
        ...rest,
        target: '_blank',
    };

    return (
        <Link { ...props }>
            { glyph ? <Icon size="medium" glyph={ glyph } /> : ' ' }
        </Link>
    );

};

const StyledSocialIcon = styled(SocialIcon)`
    &:hover{
        background-color: ${ props => tokenProp(props.theme.color, 'black')};
        color: ${ props => tokenProp(props.theme.color, 'primary/50')};
    }
`;

/**
 * FooterSocialMediaItem React component
 * Link with icon and a description
 * @param {object} params
 */
const FooterSocialMediaItem = ({ link, context, ...rest }) => {

    return (
        <StyledListItem { ...rest }>
            <StyledSocialIcon { ...link } id={ context.id } span="shrink" />
            <Caption span="stretch">{ context.description }</Caption>
        </StyledListItem>
    );

};

FooterSocialMediaItem.propTypes = {

    /**
     * Link params supplied to <Link> component
     */
    link: pt.shape({
        to: pt.string.isRequired,
        title: pt.string,
    }),

    /**
     * Context from getLink()
     */
    context: pt.shape({
        id: pt.string.isRequired,
        description: pt.string.isRequired,
    }),

};

export default FooterSocialMediaItem;