import React from 'react';

function Facebook32(props) {
	const fill = props.fill || 'currentColor';
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "facebook 32";

	return (
		<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="none">
		<path d="M12.462 31V18H8v-6h4.462V7.81c0-4.564 2.89-6.81 6.961-6.81 1.95 0 3.627.145 4.115.21v4.77l-2.824.001C18.5 5.981 18 7.034 18 8.578V12h6l-2 6h-4v13h-5.538z" fill={fill}/>
	</g>
</svg>
	);
};

export default Facebook32;