/**
 * Exports useSiteMetadata
 */
import { graphql, useStaticQuery } from "gatsby"

/**
 * React hook
 * @returns site metadata from gatsby-config.js
 */
const useSiteMetadata = () => {
    
    const data = useStaticQuery(graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                    }
                }
            }
    `);

    return data.site.siteMetadata;

};

export default useSiteMetadata;