import React from 'react';

function Check20(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 2;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "check 20";

	return (
		<svg height={height} width={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth} transform="translate(0.5 0.5)">
		<path d="M1.66 9.96l5.81 5.81 10.79-10.79" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Check20;