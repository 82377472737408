/**
 * Exports option list-related design tokens
 */

export const dividerHeight = 2;
export const dividerBorderStyle = 'solid';
export const dividerBorderWidth = '1px';
export const dividerBorderColor = 'gray/60';

export const headingHeight = 7;
export const headingTextKind = 'button/small';
export const headingColor = 'gray/40';