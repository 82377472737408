/**
 * Exports HeaderMenu
 */

import React, { useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IconButton } from '@arturpol/systheme';
import { useTopMenuActions } from '../TopMenu';

/**
 * React hook
 * @returns main menu link title attribute
 */
const useMainMenuTitle = () => {

    const { menu } = useStaticQuery(graphql`
        query getMainMenuTitle {
            menu(_id: {eq: "main"}) {
                title
            }
        }
    `);

    return menu.title;

};

/**
 * HeaderMenu React component
 * Renders an icon revealing the top menu on click.
 * See the ```TopMenu``` component for the menu itself.
 * @param {object} props
 */
const HeaderMenu = props => {

    const { open } = useTopMenuActions();
    const callback = useCallback(() => open(), [ open ]);
    const title = useMainMenuTitle();
    
    props = {
        ...props,
        size: 'large',
        glyph: 'menu',
        variant: 'white',
        motion: true,
        onTap: callback,
        title,
        height: '100%',
        padding: '0 2',
        marginRight: -2,
        display: 'flex',
        alignItems: 'center',
    };

    return (
        <IconButton { ...props } />
    );

};

export default HeaderMenu;