import React from 'react';

function ChessKnight48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "chess knight 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M12,39c0-12,12-13,8-23L10,19,8,15l8-8V3h0A22,22,0,0,1,32.971,39" fill="none" stroke={fill}/>
		<rect height="6" width="34" fill="none" x="7" y="39"/>
	</g>
</svg>
	);
};

export default ChessKnight48;