/**
 * Exports SessionIcon
 */

import React from 'react';
import pt from 'prop-types';
import { useTheme } from 'styled-components';
import { Icon } from '@arturpol/systheme';

/**
 * SessionIcon React component
 * Renders icon that corresponds to particular session/portfolio type id
 * @param {object} params
 */
const SessionIcon = ({ id, ...rest }) => {

    const { sessionIcon } = useTheme();
    const glyph = sessionIcon.idToGlyph[id];

    const props = {
        glyph,
        ...rest,
    };

    return <Icon { ...props } />;

};

SessionIcon.propTypes = {

    /**
     * Session/portfolio type id e.g. 'couple' or 'wedding'
     */
    id: pt.string,

};

export default SessionIcon;