import React from 'react';

function TriangleRight24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "triangle right 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill}>
		<path d="M7.6,2.2A1,1,0,0,0,6,3V21a1,1,0,0,0,1.6.8l12-9a1,1,0,0,0,0-1.6Z" fill={fill}/>
	</g>
</svg>
	);
};

export default TriangleRight24;