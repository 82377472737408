import React from 'react';

function Instagram48(props) {
	const fill = props.fill || 'currentColor';
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "instagram 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="none">
		<path d="M24 6.784c5.607 0 6.271.021 8.486.122 1.332.016 2.65.26 3.9.723a6.954 6.954 0 0 1 3.986 3.986c.462 1.25.707 2.568.723 3.9.1 2.215.122 2.879.122 8.486 0 5.607-.021 6.271-.122 8.486a11.649 11.649 0 0 1-.723 3.9 6.954 6.954 0 0 1-3.986 3.986c-1.25.462-2.568.707-3.9.723-2.214.1-2.878.122-8.486.122-5.608 0-6.272-.021-8.486-.122a11.649 11.649 0 0 1-3.9-.723 6.954 6.954 0 0 1-3.986-3.986 11.65 11.65 0 0 1-.723-3.9c-.1-2.215-.122-2.879-.122-8.486 0-5.607.021-6.271.122-8.486.016-1.332.26-2.65.723-3.9a6.954 6.954 0 0 1 3.986-3.986 11.65 11.65 0 0 1 3.9-.723c2.215-.1 2.879-.122 8.486-.122zM24 3c-5.7 0-6.418.024-8.658.126a15.418 15.418 0 0 0-5.1.976 10.287 10.287 0 0 0-3.717 2.423A10.287 10.287 0 0 0 4.1 10.244a15.418 15.418 0 0 0-.976 5.1C3.024 17.582 3 18.3 3 24c0 5.7.024 6.418.126 8.658.035 1.743.365 3.467.976 5.1a10.287 10.287 0 0 0 2.423 3.719 10.287 10.287 0 0 0 3.719 2.423 15.42 15.42 0 0 0 5.1.976c2.24.1 2.955.126 8.658.126 5.703 0 6.418-.024 8.658-.126a15.42 15.42 0 0 0 5.1-.976 10.748 10.748 0 0 0 6.14-6.144 15.42 15.42 0 0 0 .976-5.1C44.976 30.418 45 29.7 45 24c0-5.7-.024-6.418-.126-8.658a15.42 15.42 0 0 0-.976-5.1 10.287 10.287 0 0 0-2.423-3.719A10.287 10.287 0 0 0 37.756 4.1a15.418 15.418 0 0 0-5.1-.976C30.418 3.024 29.7 3 24 3z" fill={fill}/>
		<path d="M24 13.216a10.784 10.784 0 1 0 0 21.567 10.784 10.784 0 0 0 0-21.567zM24 31a7 7 0 1 1 0-14 7 7 0 0 1 0 14z" fill={fill}/>
		<path d="M35.21 15.31a2.52 2.52 0 1 0 0-5.04 2.52 2.52 0 0 0 0 5.04z" fill={fill}/>
	</g>
</svg>
	);
};

export default Instagram48;