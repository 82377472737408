import React from 'react';

function ChessKnight32(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "chess knight 32";

	return (
		<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M22.158,26A14.982,14.982,0,0,0,11,1V4L5.5,9.5,7,12l6-2c4,7-5,8-5,16" fill="none" stroke={fill}/>
		<rect height="5" width="24" fill="none" x="4" y="26"/>
	</g>
</svg>
	);
};

export default ChessKnight32;