/**
 * Exports Step
 */

import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { Box, Icon, Heading, Paragraph, useResponsiveProp } from '@arturpol/systheme';
import { PrimaryButton } from '../Button';
import horizontalDivider from '../../../content/images/triangle-divider-h.svg';
import verticalDivider from '../../../content/images/triangle-divider-v.svg';

const ListItem = styled(Box)`
    list-style: none;
    
    &::before {
        content: " ";
        position: absolute;
        display: ${ props => props.$border ? 'block' : 'none' };
        background-image: url(${ props => props.$border === 'bottom' ? verticalDivider : horizontalDivider });
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        top: ${ props => props.$border === 'bottom' ? '100%' : '0' };
        left: ${ props => props.$border === 'bottom' ? '0' : '100%' };
        width: ${ props => props.$border === 'bottom' ? '100%' : props.theme.rem(4) };
        height: ${ props => props.$border === 'bottom' ? props.theme.rem(2) : '100%' };
    }
`;

/**
 * Step React component
 * Step in a workflow block
 */
const Step = React.forwardRef(({ title, description, icon, button, last, ...rest }, ref) => {

    const border = useResponsiveProp({ small: 'bottom', large: 'right', });

    const props = {
        el: 'li',
        p: { small: '3', large: '5 4', },
        position: 'relative',
        $border: (!last && border),
        ...rest,
        ref,
    };

    return (
        <ListItem { ...props }>
            <Icon size="xlarge" glyph={ icon } color="primary/50" mb="2" />
            <Heading kind="h5" variant="secondary">{ title }</Heading>
            <Paragraph kind="label">{ description }</Paragraph>
            { button ? <PrimaryButton { ...button.link } mt="3" width="stretch" /> : null }
        </ListItem>
    );

});

Step.propTypes = {

    /**
     * Step title
     */
    title: pt.string.isRequired,

    /**
     * Step description
     */
    description: pt.string.isRequired,

    /**
     * Optional icon
     */
    icon: pt.string,

    /**
     * Optional button (provide items returned from getLink)
     */
    button: pt.object,

    /**
     * Is last item in Workflow container?
     */
    last: pt.bool,
    
};

export default Step;