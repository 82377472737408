/**
 * Exports CarouselSlides
 */
import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, tokenProp } from '@arturpol/systheme';
import { Link } from '../Link';

const ListItem = styled(Box)`
    list-style: none;
`;

const StyledLink = styled(Link)`
    transition: transform ${ props => tokenProp(props.theme.duration, 'long') } ${ props => tokenProp(props.theme.easing, 'ease-in-out/medium') };

    &:hover{
        transform: scale(1.05);
    }
`;

/**
 * CarouselSlide React component 
 * Slide in the image carousel
 * @param {object} params
 */
const CarouselSlide = ({ page, item, alt, image, ...rest }) => {

    const linkParams = {
        slide: item,
    };

    const img = <GatsbyImage alt={ alt } image={ image } />;

    const props = {
        el: 'li',
        width: '100%',
        ...rest,
    };

    return (
        <ListItem { ...props }>
            { page ? <StyledLink to={ page } params={ linkParams } display="block">{ img }</StyledLink> : img }
        </ListItem>
    );

};

CarouselSlide.propTypes = {

    /**
     * Page id to link slide to
     */
    page: pt.string,

    /**
     * Slide index
     */
    item: pt.number.isRequired,

};

export default CarouselSlide;