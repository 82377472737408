import React from 'react';

function Rings64(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "rings 64";

	return (
		<svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<polyline fill="none" points="21.538 18.078 28 11 28 10 25 7 15 7 12 10 12 11 18.462 18.078"/>
		<path d="M28.772,26.434a18.063,18.063,0,1,0,7.5-6.672" fill="none" stroke={fill}/>
		<path d="M35.228,45.566a18.063,18.063,0,1,0-7.5,6.672" fill="none"/>
		<line fill="none" x1="20" x2="20" y1="18" y2="13"/>
	</g>
</svg>
	);
};

export default Rings64;