/**
 * Exports input-related design tokens
 */

import { token } from '../utils';

/**
 * Input appearence.
 * Can define 'text', 'icon', 'background', 'border', 'placeholder', 'labelColor' and 'labelBackground' properties for color as well as 'shadow' for the box shadow. 
 * This can be set for all possible states e.g. hover or disabled.
 * Only single icon color is allowed e.g. 'primary/50', but not 'primary/50 gray/20'.
 */
export const appearance = {
    placeholder: 'gray/40',
    text: 'white',
    background: 'gray/80',
    border: 'gray/60',
    icon: 'gray/10',
    labelColor: 'gray/10',
    labelBackground: 'transparent',

    disabled: {
        placeholder: 'gray/70',
        text: 'gray/60',
        background: 'gray/90',
        border: 'gray/70',
        icon: 'gray/40',
        labelColor: 'gray/40',
    },

    hover: {
        border: 'primary/50',
    },

    focus: {
        border: 'primary/50',
        shadow: 'in/medium',

        hover: {
            border: 'primary/80',
        },
    },

    invalid: {
        border: 'alert/50',
        labelColor: 'alert/50',
        icon: 'alert/50',

        disabled: {
            placeholder: 'gray/70',
            text: 'gray/60',
            background: 'gray/90',
            border: 'gray/80',
            icon: 'gray/40',
            labelColor: 'gray/40',
        },

        hover: {
            border: 'alert/80',
        },

        focus: {
            border: 'alert/50',
            shadow: 'in/medium',

            hover: {
                border: 'alert/80',
            },
        },

    },

};

 /**
 * Input sizes defined as design token.
 * 'text' - Text 'kind' to specify the text size and style,
 * 'height' - height of input either in base values or any CSS unit,
 * 'padding' - input horizontal padding either in base values or any CSS unit,
 * 'gap' - gap between text and icon either in base values or any CSS unit,
 * 'icon' - Icon 'size'
 * 'label' - prefix/postfix Label's 'kind'
 */
export const size = token('inputSize', [{
    key: 'medium',
    order: 2,
    value: {
        text: 'p/body',
        height: 6,
        padding: 2,
        gap: 1,
        icon: 'small',
        label: {
            kind: 'medium',
            height: 3,
            padding: 0,
        },
    }
}]);

export const defaultKind = 'outline'; // 'normal' | 'outline'
export const defaultSize = 'medium';

export const defaultBorderRadius = 'small';
export const roundBorderRadius = 'round';
export const borderWidth = '1px';

export const transitionDuration = 'short';

export const showLabelBorder = false;

export const showNativeNumberSpinner = false;