import React from 'react';

function Instagram20(props) {
	const fill = props.fill || 'currentColor';
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "instagram 20";

	return (
		<svg height={height} width={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="none">
		<g clipPath="url(#1669749503510-4480350_clip0_406_1797)">
			<path d="M10 2.485c2.447 0 2.737.01 3.704.053a5.06 5.06 0 0 1 1.702.316c.398.147.758.381 1.054.686.305.295.539.656.686 1.054.202.545.309 1.12.316 1.702.044.966.053 1.256.053 3.704 0 2.447-.01 2.737-.053 3.704a5.062 5.062 0 0 1-.316 1.702 3.038 3.038 0 0 1-1.74 1.74 5.062 5.062 0 0 1-1.702.316c-.967.044-1.257.053-3.704.053-2.448 0-2.738-.01-3.704-.053a5.061 5.061 0 0 1-1.702-.316 2.834 2.834 0 0 1-1.054-.686 2.833 2.833 0 0 1-.686-1.054 5.06 5.06 0 0 1-.316-1.702c-.044-.967-.053-1.257-.053-3.704 0-2.448.01-2.738.053-3.704a5.06 5.06 0 0 1 .316-1.702c.147-.398.381-.759.686-1.054.295-.305.656-.54 1.054-.686a5.06 5.06 0 0 1 1.702-.316c.966-.044 1.256-.053 3.704-.053zm0-1.652c-2.49 0-2.802.011-3.78.055-.76.016-1.512.16-2.224.426A4.687 4.687 0 0 0 1.314 4a6.756 6.756 0 0 0-.426 2.225C.844 7.198.833 7.51.833 10c0 2.49.011 2.802.055 3.78.016.76.16 1.512.426 2.224A4.688 4.688 0 0 0 4 18.686c.712.266 1.465.41 2.225.426.973.044 1.286.055 3.775.055 2.49 0 2.802-.011 3.78-.055a6.758 6.758 0 0 0 2.224-.426A4.689 4.689 0 0 0 18.686 16c.266-.713.41-1.465.426-2.225.044-.973.055-1.286.055-3.775 0-2.49-.011-2.802-.055-3.78a6.758 6.758 0 0 0-.426-2.224A4.688 4.688 0 0 0 16 1.314a6.756 6.756 0 0 0-2.225-.426C12.802.844 12.489.833 10 .833z" fill={ fill }/>
			<path d="M10 5.292a4.707 4.707 0 1 0 0 9.415 4.707 4.707 0 0 0 0-9.415zm0 7.764a3.056 3.056 0 1 1 0-6.112 3.056 3.056 0 0 1 0 6.112z" fill={ fill }/>
			<path d="M14.893 6.207a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2z" fill={ fill } />
		</g>
		<defs>
			<clipPath id="1669749503510-4480350_clip0_406_1797">
				<path d="M0 0h20v20H0z" fill="#fff"/>
			</clipPath>
		</defs>
	</g>
</svg>
	);
};

export default Instagram20;