/**
 * Exports getProductSchema
 */

const getImages = (images, baseUrl) => {
    
    return images.map(img => {
        return `${ baseUrl }${ img.childImageSharp.fixed.src }`;
    });

}

const getBrandSchema = (name, baseUrl) => {
    
    return {
        '@type': 'Brand',
        name,
    };

}

const getLastDayOfCurrentYear = () => {
    
    const year = (new Date()).getFullYear();
    return `${ year }-12-31`;

};

const getOfferSchema = ({ amount, currency }, url, baseUrl) => {

    

    return {
        '@type': 'Offer',
        url: `${ baseUrl }${ url }`,
        priceCurrency: currency,
        price: amount.toString(),
        availability: 'https://schema.org/InStock',
        priceValidUntil: getLastDayOfCurrentYear(),
    };

};

const getProductSchema = ({ name, description, sku, brand, price, url, ...rest }, baseUrl) => {
    
    const { image1x1, image4x3, image16x9 } = rest;

    return {
        '@type': 'Product',
        name,
        description,
        image: getImages([ image1x1, image4x3, image16x9 ], baseUrl),
        sku,
        brand: getBrandSchema(brand, baseUrl),
        offers: getOfferSchema(price, url, baseUrl),
    };

};

export default getProductSchema;