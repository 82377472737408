/**
 * Exports Carousel
 */

import React, { useContext, useRef, useCallback, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import pt from 'prop-types';

// Default carousel state
const DEFAULT_STATE_CONTEXT = {

    // Carousel items
    items: [],

    // Index of the current item in the items array
    current: 0,

};

/**
 * Carousel context for actions (next/previous)
 */
const CarouselActionContext = React.createContext(null);

/**
 * Carousel context for its state and callbacks
 */
const CarouselStateContext = React.createContext(DEFAULT_STATE_CONTEXT);

/**
 * CarouselProvider React component
 * Stores carousel state and allows to change current slide by user or automatically (timer).
 * Carousel doesn't stop at the last slide, instead it resets the counter and starts from 0.
 * @param {object} params 
 */
const CarouselProvider = ({ children, auto, items, interval, initialIndex, ...rest }) => {

    const theme = useTheme();
    const config = theme.carousel;

    // Default interval to change the slide (if 'auto' prop set to true)
    const intervalDuration = typeof interval === 'number' ? interval : config.slideChangeIntervalMs;

    // Index of current slide (item)
    const [ current, setCurrent ] = useState(initialIndex);
    
    // To keep interval handle and reset it if needed
    const intervalHandler = useRef(null);

    // Next item
    const next = useCallback(() => {
        setCurrent(prev => (prev + 1) < items.length ? (prev + 1) : 0);
    }, [ items ]);

    // Previous item
    const previous = useCallback(() => {
        setCurrent(prev => prev > 0 ? (prev - 1) : items.length - 1);
    }, [ items ]);

    // Stop automatic item switching
    const stop = useCallback(() => {
        if(intervalHandler.current) {
            clearInterval(intervalHandler.current);
            intervalHandler.current = null;
        }
    }, []);

    // Start automatic item switching
    const start = useCallback(() => {
        intervalHandler.current = setInterval(next, intervalDuration);
    }, [ next, intervalDuration ]);


    // Reset interval and start automatic item switching
    const reset = useCallback(() => {
        stop();
        start();
    }, [ start, stop ]);

    // OnMouseEnter callback (to pause automatic switching)
    const enterCallback = useCallback(event => {
        stop();
    }, [ stop ]);

    // OnMouseLeave callback (to resume automatic switching)
    const leaveCallback = useCallback(event => {
        if(auto) reset();
    }, [ reset, auto ]);

    // If changed 'auto' property, reset the interval
    useEffect(() => {
        if(auto) reset();
        else stop();
        return () => stop();
    }, [ auto, reset, stop ]);

    // If items changed, make sure that current item is within new boundaries...
    useEffect(() => {
        setCurrent(prev => (prev > items.length - 1) ? (items.length - 1) : prev);
    }, [ items ]);

    // Actions context
    const actions = {
        next,
        previous,
    };

    // State context
    const state = {
        onMouseEnter: enterCallback,
        onMouseLeave: leaveCallback,
        current,
        items,
    };

    return (
        <CarouselActionContext.Provider value={ actions }>
            <CarouselStateContext.Provider value={ state }>
                { children }
            </CarouselStateContext.Provider>
        </CarouselActionContext.Provider>
    );

};

CarouselProvider.propTypes = {

    /**
     * Should items change automatically?
     */
    auto: pt.bool,

    /**
     * Items in the carousel (slides)
     */
    items: pt.array.isRequired,

    /**
     * Carousel content
     */
    children: pt.node.isRequired,

    /**
     * Custom slide change interval in [ms]
     */
    interval: pt.number,

    /**
     * Initital item index
     */
    initialIndex: pt.number,

};

CarouselProvider.defaultProps = {
    auto: true,
    items: [],
    initialIndex: 0,
};

/**
 * Helper React hooks
 */
const useCarouselActions = () => useContext(CarouselActionContext);
const useCarouselState = () => useContext(CarouselStateContext);

export {
    CarouselProvider,
    useCarouselActions,
    useCarouselState,
};
