/**
 *  Contains Polish string constants for Systheme component,
 *  see /src/components/Layout/Layout.js
 */

export const SELECT_PLACEHOLDER = 'Wybierz…';
export const INPUT_SHOW_PASSWORD_BUTTON_HINT = 'Pokaż hasło';
export const INPUT_HIDE_PASSWORD_BUTTON_HINT = 'Ukryj hasło';
export const FORM_MESSAGE_INVALID_COLOR = 'Nieprawidłowy kolor — wymagany format: "#ff45da"';
export const FORM_MESSAGE_INVALID_DATE = 'Nieprawidłowa data — wymagany format: RRRR-MM-DD';
export const FORM_MESSAGE_INVALID_DATETIME = 'Nieprawidłowa data i czas';
export const FORM_MESSAGE_INVALID_MONTH = 'Nieprawidłowy miesiąc — wymagany format: RRRR-MM';
export const FORM_MESSAGE_INVALID_TIME = 'Nieprawidłowy czas — wymagany format: GG:MM:SS';
export const FORM_MESSAGE_INVALID_NUMBER = 'Nieprawidłowy numer';
export const FORM_MESSAGE_INVALID_URL = 'Nieprawidłowy adres URL';
export const FORM_MESSAGE_INVALID_TEL = 'Nieprawidłowy numer telefonu';
export const FORM_MESSAGE_INVALID_EMAIL = 'Nieprawidłowy adres email';
export const FORM_MESSAGE_PASSWORD_TOO_SHORT = 'Minimum {min} znaków';
export const FORM_MESSAGE_REQUIRED = 'Uzupełnij';
export const FORM_MESSAGE_INVALID_GENERAL = 'Nieprawidłowa wartość';
export const MODAL_CLOSE_HINT = 'Zamknij okno';
export const TOAST_CLOSE_HINT = 'Ukryj';