/**
 * Exports useLanding
 */

import { useStaticQuery, graphql } from 'gatsby';

/**
 * React hook
 * See /content/landing/landing.yml
 * @param {object} filter - object with keys being property names of landing page and values being values to match
 * @returns landing pages with matching properties specified in ```filter``` param
 */
const useLandings = filter => {

    const { allLanding } = useStaticQuery(graphql`
        query getLandings {
            allLanding {
                nodes {
                    _id
                    _townId
                    type
                    banner {
                        title
                    }
                }
            }
        }
    `);

    const landings = allLanding.nodes.map(({ _id, _townId, type, banner }) => ({
        id: _id,
        type,
        _location: _townId,
        title: banner.title,
    }));

    if(typeof filter !== 'object') return landings;

    return landings.filter(landing => {

        let match = true;

        for(let i in filter){
            
            if(!filter.hasOwnProperty(i)) continue;

            if(!landing.hasOwnProperty(i) || landing[i] !== filter[i]){
                match = false;
                break;
            }

        }

        return match;

    });

};

export default useLandings;