/**
 * Exports useCallout
 */

import { useStaticQuery, graphql } from 'gatsby';

/**
 * React hook
 * Returns callout content based on its id
 * @param {string} id - callout id (see /content/data/callout.yml)
 * @returns title and description 
 */
const useCallout = id => {

    const { allCallout } = useStaticQuery(graphql`
        query getCallouts {
            allCallout {
                nodes {
                    _id
                    description
                    title
                }
            }
        }
    `);
    
    // find one, remove id
    const callouts = allCallout.nodes
        .filter(item => item._id === id)
        .map(({ _id, ...rest }) => rest);

    if(callouts.length < 1) return false;
    
    return {
        ...callouts[0],
    };

};

export default useCallout;