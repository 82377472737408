import React from 'react';

function Hash16(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "hash 16";

	return (
		<svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill}>
		<path d="M2.99,15H5.01l.429-3H9.418L8.99,15h2.02l.429-3H14V10H11.725L12.3,6H15V4H12.582l.428-3H10.99l-.429,3H6.582L7.01,1H4.99L4.561,4H2V6H4.275L3.7,10H1v2H3.418ZM6.3,6h3.979L9.7,10H5.725Z" fill={fill}/>
	</g>
</svg>
	);
};

export default Hash16;