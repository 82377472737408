/**
 * Exports useSocialMedia
 */

import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

/**
 * React hook that returns website social media profile data.
 * Check ```/content/data/site.yml``` for social media profile definitions.
 * @param {string} socialMediaId - providing social media id will return info about only this given profile
 * @returns object with keys being social media ids and values being objects e.g. { id: 'fb', url: 'http://facebook.com' }
 */
const useSocialMedia = (socialMediaId = false) => {

    const data = useStaticQuery(graphql`
        query getSocialMedia {
            data(_id: {eq: "site"}) {
                socialMedia {
                    _id
                    url
                }
            }
        }
    `);

    const socialMedia = useMemo(() => {
        
        return data.data.socialMedia.map(node => {
            return { url: node.url, id: node._id };
        }).reduce((obj, node) => {
            return { ...obj, [ node.id ]: node, };
        }, {});

    }, [ data ]);

    return typeof socialMediaId === 'string' ? socialMedia[socialMediaId] : socialMedia;

};

export default useSocialMedia;