/**
 * Exports usePagePath
 */

import { useMemo } from 'react';
import usePageInfo from './usePageInfo';

/**
 * React hook that exports object with keys being page ids and values being their respective relative URL paths. 
 * Used to build links based on page id rather than path itself (that can change over the time).
 * @param {object} pageId - providing pageId will return only single matching path
 * @returns object with page paths or a single path
 */
const usePagePath = (pageId = false) => {

    const pages = usePageInfo();

    const paths = useMemo(() => {
        
        return pages.reduce((obj, page) => {
            return { ...obj, [ page.id ]: page.path, };
        }, {});

    }, [ pages ]);

    return typeof pageId === 'string' ? paths[ pageId ] : paths;

};

export default usePagePath;