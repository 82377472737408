import React from 'react';

function Check12(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "check 12";

	return (
		<svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeWidth="1">
		<polyline fill="none" points="0.5 7.5 3.5 10.5 11.5 1.5" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
	</g>
</svg>
	);
};

export default Check12;