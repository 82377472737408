/**
 * Exports button-related design tokens
 */

import { token } from '../utils';

/**
 * Button appearence variant as a design token. 
 * Can define 'caption', 'icon', 'background' and 'border' properties for color as well as 'shadow' for the box shadow. 
 * This can be set for all possible states e.g. active or toggle AND disabled.
 * Only single icon color is allowed e.g. 'primary/50', but not 'primary/50 gray/20'
 */
export const variant = token('buttonVariant', [{
        key: 'primary',
        value: {
            fill: {
                caption: 'gray/90',
                icon: 'gray/90',
                background: 'primary/50',
                hover: {
                    caption: 'black',
                    icon: 'black',
                    background: 'primary/80',
                },
                active: {
                    caption: 'gray/70',
                    icon: 'gray/70',
                    background: 'primary/80',
                    shadow: 'in/medium',
                },
                disabled: {
                    caption: 'gray/60',
                    icon: 'gray/60',
                    background: 'gray/80',
                },
                toggle: {
                    caption: 'gray/5',
                    icon: 'gray/5',
                    background: 'gray/80',
                    border: 'gray/90',
                    hover: {
                        caption: 'gray/90',
                        icon: 'gray/90',
                        background: 'primary/40',
                    },
                    active: {
                        caption: 'gray/70',
                        icon: 'gray/70',
                        background: 'primary/80',
                        shadow: 'in/medium',
                    },
                    disabled: {
                        caption: 'gray/60',
                        icon: 'gray/60',
                        background: 'gray/80',
                    },
                    selected: {
                        caption: 'gray/90',
                        icon: 'gray/90',
                        background: 'primary/50',
                        disabled: {
                            caption: 'gray/40',
                            icon: 'gray/40',
                            background: 'gray/60',
                        },
                    },
                },
            },
            outline: {
                caption: 'primary/50',
                icon: 'primary/50',
                border: 'primary/50',
                hover: {
                    background: 'black',
                    caption: 'primary/80',
                    icon: 'primary/80',
                    border: 'primary/80',
                },
                active: {
                    caption: 'gray/70',
                    icon: 'gray/70',
                    background: 'primary/80',
                    border: 'primary/80',
                    shadow: 'in/medium',
                },
                disabled: {
                    caption: 'gray/60',
                    icon: 'gray/60',
                    border: 'gray/60',
                },
                toggle: {
                    caption: 'primary/50',
                    icon: 'primary/50',
                    border: 'primary/50',
                    hover: {
                        background: 'black',
                        caption: 'primary/80',
                        icon: 'primary/80',
                        border: 'primary/80',
                    },
                    active: {
                        caption: 'gray/70',
                        icon: 'gray/70',
                        background: 'primary/80',
                        border: 'primary/80',
                        shadow: 'in/medium',
                    },
                    disabled: {
                        caption: 'gray/60',
                        icon: 'gray/60',
                    },
                    selected: {
                        caption: 'gray/90',
                        icon: 'gray/90',
                        background: 'primary/50',
                        border: 'primary/50',
                        disabled: {
                            caption: 'gray/40',
                            icon: 'gray/40',
                            background: 'gray/60',
                        },
                    },
                },
            },
            text: {
                caption: 'primary/50',
                icon: 'primary/50',
                hover: {
                    background: 'primary/50',
                    caption: 'gray/90',
                    icon: 'gray/90',
                },
                active: {
                    caption: 'gray/70',
                    icon: 'gray/70',
                    background: 'primary/80',
                    border: 'primary/80',
                    shadow: 'in/medium',
                },
                disabled: {
                    caption: 'gray/60',
                    icon: 'gray/60',
                },
                toggle: {
                    caption: 'gray/5',
                    icon: 'gray/5',
                    hover: {
                        background: 'gray/80',
                    },
                    active: {
                        caption: 'primary/80',
                        icon: 'primary/80',
                        background: 'gray/80',
                    },
                    disabled: {
                        caption: 'gray/60',
                        icon: 'gray/60',
                    },
                    selected: {
                        caption: 'primary/50',
                        icon: 'primary/50',
                        disabled: {
                            caption: 'gray/60',
                            icon: 'gray/60',
                        },
                    },
                },
            },
        }
    }]
);

/**
 * Button sizes defined as design token.
 * 'caption' - Text 'kind' to specify the caption size and style,
 * 'height' - height of button either in base values or any CSS unit,
 * 'padding' - button horizontal padding either in base values or any CSS unit,
 * 'gap' - gap between text and icon either in base values or any CSS unit,
 * 'icon' - Icon 'size'
 */
export const size = token('buttonSize', [{
    key: 'medium',
    order: 2,
    value: {
        caption: 'button/medium',
        height: 6,
        padding: 3.5,
        gap: 0.5,
        icon: 'small',
    }
},{
    key: 'small',
    order: 1,
    value: {
        caption: 'button/small',
        height: 4.5,
        padding: 2.5,
        gap: 0.5,
        icon: 'xsmall',
    },
}]);

export const defaultVariant = 'primary';
export const defaultKind = 'fill'; // 'fill' | 'outline' | 'text'
export const defaultSize = 'medium';

export const defaultBorderRadius = 'small';
export const roundBorderRadius = 'round';
export const borderWidth = '2px';

export const transitionDuration = 'short';