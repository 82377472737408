/**
 * Exports popover-related design tokens
 */

export const defaultPlacement = 'auto';
export const defaultOffset = '0 0';

export const defaultAnimation = 'fade';
export const animationDuration = 'medium';
export const instantAnimationDuration = 'none';

export const backgroundColor = 'gray/70';
export const boxShadow = 'out/large';
export const borderRadius = 'medium';
export const verticalPadding = 2;
export const horizontalPadding = 2;

export const borderWidth = '0';
export const borderColor = 'transparent';
export const borderStyle = 'solid';

const arrowPadding = 1;

export const arrowSize = 1;
export const arrowBorderRadius = 'small';

// offset applied from the edge of the popover to the arrow element
// useful when popover border-radius is grater than 0
export const arrowLeftPadding = arrowPadding;
export const arrowTopPadding = arrowPadding;
export const arrowBottomPadding = arrowPadding;
export const arrowRightPadding = arrowPadding;