/**
 * Exports tag carousel-related design tokens
 */

export const slideTransitionDuration = 'medium';
export const slideTransitionEasing = 'linear';
export const navigationGap = 'xsmall';
export const labelKind = 'counter';
export const labelMinimumWidth = 4;
export const navigationButtonVariant = 'white';
export const navigationButtonIconSize = 'medium';
export const navigationPreviousIcon = 'arrow-left';
export const navigationNextIcon = 'arrow-right';