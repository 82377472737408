import React from 'react';

function Rings48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "rings 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<polyline fill="none" points="15.689 14.035 20 9 20 7 17 5 13 5 10 7 10 9 14.311 14.035"/>
		<path d="M27.337,31.08a13.019,13.019,0,1,0-8.646,8.38" fill="none"/>
		<path d="M20.663,22.92a13.019,13.019,0,1,0,8.646-8.38" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Rings48;