/**
 * Form submission helpers
 */

/**
 * Returns form params encoded as a single string
 * @param {object} fields - fields object from the Form component context (see useForm)
 * @param {object} params - extra parameteres to include
 */
const getRequestBody = (fields, params = {}) => {

    const body = {};

    for(let i in fields){

        if(!fields.hasOwnProperty(i)) continue;
        body[i] = fields[i].value;

    }

    const payload = {
        ...body,
        ...params,
    };

    return Object.keys(payload)
        .map(key => `${ encodeURIComponent(key) }=${ encodeURIComponent(payload[key]) }`)
        .join('&');

};

export {
    getRequestBody,
};