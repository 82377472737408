import React from 'react';

function Horse48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "horse 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M7.043,35.13l5.042-13.864A8,8,0,0,1,19.6,16h8.012a3.979,3.979,0,0,0,3.8-2.866A10,10,0,0,1,41,6V9l3.074,5.379a2,2,0,0,1-.627,2.657L42,18l-4-3-2,7,4.93,13.147" fill="none" stroke={fill}/>
		<path d="M36.6,37.379l-1.314-3.41a11.983,11.983,0,0,0-22.582,0L11.4,37.379" fill="none" stroke={fill}/>
		<path d="M14.627,17.514A7,7,0,0,0,5,24H2a8,8,0,0,1,8-8,7.872,7.872,0,0,1,4.775,1.621" fill="none" stroke={fill}/>
		<path d="M3.468,32.532a31.959,31.959,0,0,0,41.064,0" fill="none"/>
	</g>
</svg>
	);
};

export default Horse48;