import React from 'react';

function Instagram32(props) {
	const fill = props.fill || 'currentColor';
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "instagram 32";

	return (
		<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="none">
		<path d="M16 3.7c4 0 4.48.015 6.061.087a8.293 8.293 0 0 1 2.786.517c.651.24 1.24.624 1.724 1.122a4.643 4.643 0 0 1 1.122 1.724c.33.892.506 1.835.517 2.786.072 1.584.09 2.064.09 6.064s-.015 4.48-.087 6.061a8.292 8.292 0 0 1-.517 2.786 4.97 4.97 0 0 1-2.846 2.846 8.292 8.292 0 0 1-2.786.517c-1.581.072-2.056.087-6.061.087s-4.48-.015-6.061-.087a8.292 8.292 0 0 1-2.786-.517 4.643 4.643 0 0 1-1.724-1.122 4.644 4.644 0 0 1-1.122-1.724 8.293 8.293 0 0 1-.517-2.786C3.718 20.48 3.7 20.005 3.7 16s.015-4.48.087-6.061a8.293 8.293 0 0 1 .517-2.786 4.644 4.644 0 0 1 1.125-1.724 4.644 4.644 0 0 1 1.724-1.122 8.293 8.293 0 0 1 2.786-.517C11.52 3.718 12 3.7 16 3.7zM16 1c-4.074 0-4.585.017-6.184.09a11.044 11.044 0 0 0-3.642.7c-1 .376-1.907.966-2.656 1.728a7.364 7.364 0 0 0-1.731 2.656 11.044 11.044 0 0 0-.7 3.642C1.017 11.415 1 11.926 1 16s.017 4.585.09 6.184c.026 1.245.263 2.476.7 3.642.377 1 .968 1.907 1.731 2.656a7.364 7.364 0 0 0 2.656 1.731c1.166.437 2.397.674 3.642.7 1.6.073 2.11.09 6.184.09s4.585-.017 6.184-.09a11.044 11.044 0 0 0 3.642-.7 7.684 7.684 0 0 0 4.387-4.387c.437-1.166.674-2.397.7-3.642.073-1.6.09-2.11.09-6.184s-.017-4.585-.09-6.184a11.044 11.044 0 0 0-.7-3.642 7.364 7.364 0 0 0-1.731-2.656 7.364 7.364 0 0 0-2.656-1.731 11.044 11.044 0 0 0-3.642-.7C20.585 1.017 20.074 1 16 1z" fill={fill}/>
		<path d="M16 8.3a7.7 7.7 0 1 0 0 15.4 7.7 7.7 0 0 0 0-15.4zM16 21a5 5 0 1 1 0-10 5 5 0 0 1 0 10z" fill={fill}/>
		<path d="M24.007 9.793a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6z" fill={fill}/>
	</g>
</svg>
	);
};

export default Instagram32;