/**
 * Exports CarouselButton
 */

import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { Flex, Icon } from '@arturpol/systheme';

const Button = styled(Flex)`
    cursor: pointer;
    pointer-events: all;

    &:hover {
        opacity: 1;
    }
`;

/**
 * CarouselButton React component
 * Previous/next button in the image carousel 
 * @param {object} params
 */
const CarouselButton = ({ direction, callback, title, ...rest }) => {

    const borderRadius = direction === 'next' ? 'small 0 0 small' : '0 small small 0';

    const props = {
        el: 'button',
        width: 6,
        onClick: callback,
        title,
        padding: 1,
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.5,
        bgColor: 'rgba(0,0,0,0.33)',
        transition: 'opacity short linear',
        borderRadius,
        ...rest,
    };

    const glyph = direction === 'next' ? 'arrow-right' : 'arrow-left';

    return (
        <Button { ...props }>
            <Icon glyph={ glyph } size="medium" color="white" />
        </Button>
    );

};

CarouselButton.propTypes = {

    /**
     * Sliding direction (left/right)
     */
    direction: pt.oneOf(['previous', 'next']),

    /**
     * Callback function to move slider
     */
    callback: pt.func,

    /**
     * Link title
     */
    title: pt.string,

};

CarouselButton.defaultProps = {
    direction: 'next',
};

export default CarouselButton;