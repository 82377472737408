/**
 * Exports VideoModalProvider and useVideoModal
 */

import React, { useContext, useState, useCallback, useMemo } from 'react';
import VideoModal from './VideoModal';

/**
 * Video Modal Context with actions open and close
 */
const VideoModalContext = React.createContext({});

/**
 * VideoModalProvider React component
 * Wraps video modal and allows it to be opened from anywhere inside
 * @param {*} params
 */
const VideoModalProvider = ({ children }) => {

    const [ opened, setOpened ] = useState(false);
    const [ videoId, setVideoId ] = useState('');

    const close = useCallback(() => setOpened(false), []);

    const open = useCallback(id => { 
        if(typeof id === 'string') setVideoId(id);
        setOpened(true);
    }, []);

    const context = useMemo(() => ({ open, close }), [ open, close ]);
    
    return (
        <VideoModalContext.Provider value={ context }>
            { children }
            <VideoModal videoId={ videoId } opened={ opened } onClose={ close } />
        </VideoModalContext.Provider>
    );

};

/**
 * React hook
 * @returns object with ```open``` and ```close``` methods to control the VideoModal
 */
const useVideoModal = () => useContext(VideoModalContext);

export {
    VideoModalProvider,
    useVideoModal
};