/**
 * Exports HeaderLogo
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { tokenProp } from '@arturpol/systheme';
import { getLink } from '../../utils';
import { Logo } from '../Logo';
import { Link } from '../Link';

const WIDTH = 206;
const HEIGHT = 61;

/**
 * React hook to deliver header logo data 
 * from /content/data/site.yml
 */
const useLogoLink = () => {
    
    const data = useStaticQuery(graphql`
        query getLogoLink {
            data(_id: {eq: "site"}) {
                logo {
                    _page
                    title
                }
            }
        }
    `);

    return getLink(data.data.logo);

};

/**
 * StyledLink React component
 */
const StyledLink = styled(Link)`
    &:hover{
        opacity: ${ props => tokenProp(props.theme.opacity, 0.9) };
    }
`;

/**
 * HeaderLogo React component
 * Renders linked logo in the page header
 * @param {object} props
 */
const HeaderLogo = props => {
    
    const { link } = useLogoLink();

    props = {
        transition: 'opacity short linear',
        display: 'block',
        width: `${ WIDTH }px`,
        ...link,
        ...props,
    };
    
    return (
        <StyledLink { ...props }>
            <Logo width={ WIDTH } height={ HEIGHT } />
        </StyledLink>
    );

};

export default HeaderLogo;