/**
 * Exports FooterSocialMedia
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Flex, H } from '@arturpol/systheme';
import { getSocialMediaLink, useSocialMedia } from '../../../utils';
import FooterSocialMediaItem from './Item';

/**
 * React hook
 * @returns footer social media menu
 */
const useFooterSocialMedia = () => {

    const socialMedia = useSocialMedia();

    const { menu } = useStaticQuery(graphql`
        query getFooterSocialMedia {
            menu(_id: {eq: "footer-social-media"}) {
                title
                items {
                    _id
                    _socialMedia
                    title
                    description
                }
            }
        }
    `);

    return {
        title: menu.title,
        items: menu.items.map(item => getSocialMediaLink(item, socialMedia)),
    };

};

const SocialMediaList = props => {

    props = {
        el: 'ul',
        flexDirection: { small: 'column', mediumOnly: 'row' },
        gap: { small: 'small', mediumOnly: 0 },
        gutter: { mediumOnly: 'medium' },
        gutterType: { mediumOnly: 'margin' },
        ...props,
    };

    return <Flex { ...props } />;

};

/**
 * FooterSocialMedia React component
 * Footer social media menu with icon and description
 * @param {object} props 
 */
const FooterSocialMedia = props => {

    const { title, items } = useFooterSocialMedia();

    return (
        <Box { ...props } el="aside">
            <H kind="h5" variant="secondary">{ title }</H>
            <SocialMediaList>
                { items.map(({ link, context }) => (
                    <FooterSocialMediaItem link={ link } context={ context } key={ context.id } span={{ small: 'shrink', mediumOnly: 4 }} />
                ))}
            </SocialMediaList>
        </Box>
    );

};

export default FooterSocialMedia;