/**
 * Exports useMdx, getMdx
 */

import React, { useMemo } from 'react';
import { evaluateSync } from '@mdx-js/mdx';
import * as provider from '@mdx-js/react';
import * as runtime from 'react/jsx-runtime';

/**
 * Transforms content of the .mdx file into evaluated JS code in form of a single React component
 * @param {string} mdxContent 
 * @returns {object} React component to render
 */
const getMdx = mdxContent => {

    const { default: Content } = evaluateSync(mdxContent, { ...provider, ...runtime });
    return <Content />;

};

/**
 * Memorizes component generated from the .mdx file content and returns it
 * @param {string} mdxContent 
 * @returns {object} React component to render
 */
const useMdx = mdxContent => {

    return useMemo(() => getMdx(mdxContent), [ mdxContent ]);

};

export {
    getMdx,
    useMdx,
};