/**
 * Exports Link
 */

import React from 'react';
import pt from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { Box, Link as SysthemeLink } from '@arturpol/systheme';
import { usePath } from '../Paths';

// Used to detect an external URL
const EXTERNAL_URL_REGEXP = /^(?:https?|mailto|tel):*/;
const INNER_URL_REGEXP = /^#/;

/**
 * Link React component
 * Merges functionality of Link component from 'gatsby' and Box/Link components from '@arturpol/systheme'.
 * Automatically detects specified in 'to' prop page id, relative path or absolute URL and renders it correctly.
 */
const Link = React.forwardRef(({ to, text, params, ...rest }, ref) => {
    
    // Determines 'wrapper' component
    const paths = usePath();

    const props = {
        ...rest,
        ref,
    };

    if(typeof to === 'string'){

        const isExternal = to.match(EXTERNAL_URL_REGEXP) !== null;
        const isInner = to.match(INNER_URL_REGEXP) !== null;
        
        if(isExternal || isInner){
            
            // Simple external <a> link
            props.el = 'a';
            props.href = to;

        } else {

            const isPath = to.startsWith('/');

            // Fetches page path based on page id specified as 'to' prop
            if(!isPath) to = paths[to];

            // Uses Gatsby Link to render internal link
            props.el = GatsbyLink;
            props.to = to;

            if(typeof params === 'object') props.state = params;

        }

    }

    return text ? <SysthemeLink { ...props } /> : <Box { ...props } />;

});

Link.propTypes = {

    /**
     * Render as text link?
     */
    text: pt.bool,

    /**
     * Extra URL parameters available in location.state in the target page template.
     * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/
     * and: https://reach.tech/router/api/Link
     */
    params: pt.object,

};

Link.defaultProps = {
    text: false,
};

export default Link;