import React from 'react';

function Cake64(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "cake 64";

	return (
		<svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M32,15 c2.209,0,4-1.791,4-4s-4-8-4-8s-4,5.791-4,8S29.791,15,32,15z" fill="none"/>
		<path d="M14,17 c1.657,0,3-1.343,3-3s-3-6-3-6s-3,4.343-3,6S12.343,17,14,17z" fill="none"/>
		<path d="M50,17 c-1.657,0-3-1.343-3-3s3-6,3-6s3,4.343,3,6S51.657,17,50,17z" fill="none"/>
		<path d="M2,42.862 c0,4.571,3.705,8.276,8.276,8.276c3.14,0,5.839-1.77,7.241-4.347c1.402,2.577,4.101,4.347,7.241,4.347s5.839-1.77,7.241-4.347 c1.402,2.577,4.101,4.347,7.241,4.347c3.14,0,5.839-1.77,7.241-4.347c1.402,2.577,4.101,4.347,7.241,4.347 c4.571,0,8.276-3.705,8.276-8.276" fill="none"/>
		<path d="M58,38H6 c-2.209,0-4,1.791-4,4v18h60V42C62,39.791,60.209,38,58,38z" fill="none" stroke={fill}/>
		<path d="M54,32v-4 c0-2.209-1.791-4-4-4H14c-2.209,0-4,1.791-4,4v4" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Cake64;