/**
 * Pagination helper functions
 */

/**
 * Returns index of the item with the specified id
 * @param {string} id - item id to find
 * @param {array} items - array of objects with 'id' property
 * @returns {number} - index of found item
 */
const getIndex = (id, items) => items.findIndex(item => item.id === id);

/**
 * Returns next item on the list based on the specified item id
 * @param {string} id - current item id
 * @param {array} items - array of objects including current one
 * @returns {object} next item in the array or the first one if there is no next one
 */
const getNextItem = (id, items) => {

    // No duplicates accepted
    if(items.length < 2) return null;
    let index = getIndex(id, items);
    if(index < 0) return null;
    index = (index + 1 >= items.length) ? 0 : (index + 1);
    return items[index];

};

/**
 * Returns previous item on the list based on the specified item id
 * @param {string} id - current item id
 * @param {array} items - array of objects including current one
 * @returns {object} previous item in the array or the last one if there is no previous one
 */
const getPreviousItem = (id, items) => {

    // No duplicates accepted
    if(items.length < 2) return null;
    let index = getIndex(id, items);
    if(index < 0) return null;
    index = (index - 1 < 0) ? (items.length - 1) : (index - 1);
    return items[index];

};

export {
    getPreviousItem,
    getNextItem,
};