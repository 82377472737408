import React from 'react';

function Close20(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 2;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "close 20";

	return (
		<svg height={height} width={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth} transform="translate(0.5 0.5)">
		<path d="M15.77 4.15l-11.62 11.62" fill="none" stroke={fill}/>
		<path d="M15.77 15.77l-11.62-11.62" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Close20;