import React from 'react';

function Face24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "face 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<polyline fill="none" points="2 6 2 2 6 2"/>
		<polyline fill="none" points="22 6 22 2 18 2"/>
		<polyline fill="none" points="2 18 2 22 6 22"/>
		<polyline fill="none" points="22 18 22 22 18 22"/>
		<path d="M18,19l-2.469-.617a2,2,0,0,1-1.515-1.95l0-1.027A6.687,6.687,0,0,0,17,10V9A5,5,0,0,0,7,9v1a6.687,6.687,0,0,0,2.986,5.406l0,1.027a2,2,0,0,1-1.515,1.95L6,19" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Face24;