import React from 'react';

function Safe48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "safe 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<rect height="40" width="40" fill="none" rx="4" stroke={fill} x="4" y="2"/>
		<rect height="26" width="26" fill="none" x="11" y="9"/>
		<circle cx="24" cy="22" fill="none" r="5" stroke={fill}/>
		<line fill="none" stroke={fill} x1="10" x2="10" y1="42" y2="46"/>
		<line fill="none" stroke={fill} x1="38" x2="38" y1="42" y2="46"/>
		<line fill="none" x1="8" x2="11" y1="15" y2="15"/>
		<line fill="none" x1="8" x2="11" y1="29" y2="29"/>
		<line fill="none" stroke={fill} x1="20.464" x2="18" y1="18.464" y2="16"/>
		<line fill="none" stroke={fill} x1="27.536" x2="30" y1="18.464" y2="16"/>
		<line fill="none" stroke={fill} x1="27.536" x2="30" y1="25.536" y2="28"/>
		<line fill="none" stroke={fill} x1="20.464" x2="18" y1="25.536" y2="28"/>
	</g>
</svg>
	);
};

export default Safe48;