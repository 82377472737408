/**
 * Exports checkbox-related design tokens
 */

import { token } from '../utils';

export const labelVariant = false;
export const checkGlyph = 'check';
export const indeterminateGlyph = 'minus';
export const borderWidth = '2px';
export const transitionDuration = 'short';
export const formElementZIndex = 'back'; // hide <input type="checkbox" />

/**
 * Checkbox appearence.
 * Can define 'icon', 'background', 'border', properties for color as well as 'shadow' for the box shadow. 
 * This can be set for all possible states e.g. hover or disabled.
 * Only single icon color is allowed e.g. 'primary/50', but not 'primary/50 gray/20'.
 */
export const appearance = {
    border: 'gray/40',
    background: 'gray/90',

    disabled: {
        border: 'gray/60',
    },

    hover: {
        border: 'primary/50',
        background: 'black',
    },

    checked: {
        border: 'primary/50',
        background: 'primary/50',
        icon: 'gray/90',

        disabled: {
            border: 'gray/60',
            background: 'gray/60',
            icon: 'gray/90',
        },

        hover: {
            border: 'primary/80',
            background: 'primary/80',
            icon: 'black',
        },
    },

    invalid: {
        border: 'alert/50',

        disabled: {
            border: 'gray/60',
        },

        hover: {
            border: 'alert/80',
        },

        checked: {
            border: 'alert/50',
            background: 'alert/50',
            icon: 'gray/90',

            disabled: {
                border: 'gray/60',
                background: 'gray/60',
                icon: 'gray/90',
            },

            hover: {
                border: 'alert/80',
                background: 'alert/80',
                icon: 'black',
            },
        },

    },

};

/**
 * Checkbox sizes defined as design token.
 * 'size' - height and width of the box
 * 'gap' - gap between the box and label
 * 'icon' - Icon 'size'
 * 'label' - Label's 'kind'
 * 'borderRadius' - box' border radius
 */
export const size = token('checkboxSize', [{
    key: 'medium',
    order: 2,
    value: {
        size: 2,
        icon: 'xxsmall',
        label: 'medium',
        gap: 1,
        borderRadius: 'small',
    },
}]);

export const defaultSize = 'medium';