/**
 * Exports field-related design tokens
 */

import { token } from '../utils';

// gap between the label and field columns in the horizontal layout
export const horizontalGap = 'small';

// margin applies only in the vertical layout
export const labelMarginBottom = 1;

// margin of the entire field
export const marginBottom = 2;

// caption (hint) properties
export const captionMarginTop = 1;
export const captionParagraphKind = 'caption';
export const captionParagraphVariant = false;
export const captionAnimationEasing = 'ease-in-out/weak';
export const captionAnimationDuration = 'short';

// when error message replaces the caption (if such exists) and pushes the content below down
export const invalidCaptionStaticStyle = {
    color: 'alert/50',
    padding: 0,
    backgroundColor: 'transparent',
    border: null,
    borderRadius: 0,
    boxShadow: null,
};

// when error message is displayed as a separate layer on top of the caption (if such exists) and is sticky to the input
export const invalidCaptionAbsoluteStyle = {
    color: 'gray/90',
    padding: '0.5 1',
    backgroundColor: 'alert/50',
    border: null,
    borderRadius: 'small',
    boxShadow: 'small',
};

/**
 * Field size design token
 * 'input' - size of the input
 * 'label' - Label's kind
 */
export const size = token('fieldSize', [{
    key: 'medium',
    order: 2,
    value: {
        input: 'medium',
        label: 'medium',
    },
}]);

export const defaultSize = 'medium';