/**
 * Exports FooterCopyrights
 */

import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Flex, Paragraph } from '@arturpol/systheme';
import { TextLink, Link } from '../Link';
import { getLink, Replace } from '../../utils';

/**
 * React hook
 * @returns footer copyright and creator info
 */
const useFooterCopyrights = () => {

    const { data } = useStaticQuery(graphql`
        query getFooterCopyrights {
            data(_id: {eq: "site"}) {
                copyrights
                creator {
                    anchor
                    url
                    title
                }
                credits
                privacyPolicy {
                    _page
                    anchor
                    title
                }
                landingIndex {
                    _page
                    anchor
                    title
                }
                weselezklasa {
                    link {
                        url
                        title
                    }
                    image {
                        src
                        alt
                    }
                }
            }
        }
    `);

    return {
        copyrights: data.copyrights,
        creator: getLink(data.creator),
        credits: data.credits,
        privacyPolicy: getLink(data.privacyPolicy),
        landingIndex: getLink(data.landingIndex),
        weselezklasa: {
            link: getLink(data.weselezklasa.link),
            image: data.weselezklasa.image,
        },
    };

};

/**
 * Returns current year
 * @returns e.g. 2022
 */
const getCurrentYear = () => {
    return (new Date()).getFullYear();
};

/**
 * FooterCopyrights React component
 * Displays website copyrights and creator info in the footer
 * @param {object} props
 */
const FooterCopyrights = props => {

    const { copyrights, creator, credits, privacyPolicy, landingIndex, weselezklasa } = useFooterCopyrights();
    const [ year, setYear ] = useState(getCurrentYear());

    // Properties to replace in credits text
    const creditsReplacements = {
        name: <TextLink { ...creator.link } target="_blank" />,
    };

    useEffect(() => {
        setYear(getCurrentYear());
    }, []);

    // Properties to replace in copyrights text
    const copyrightsReplacements = { year };

    props = {
        el: 'aside',
        flexDirection: { small: 'column', medium: 'row' },
        gutter: { small: 'small', medium: 'large' },
        gutterType: 'margin',
        marginBottom: { mediumDown: 1.5, },
        ...props,
    };

    const paragraphProps = {
        kind: 'caption',
        span: { small: 12, medium: 6 },
    };

    return (
        <>
            <Flex { ...props }>
                <Paragraph { ...paragraphProps }>
                    <Replace text={ copyrights } replacements={ copyrightsReplacements } /> | <TextLink { ...privacyPolicy.link } whiteSpace="nowrap" /> | <TextLink { ...landingIndex.link } whiteSpace="nowrap" />
                </Paragraph>
                <Paragraph { ...paragraphProps } textAlign={{ small: 'left', medium: 'right' }}>
                    <Replace text={ credits } replacements={ creditsReplacements } />
                </Paragraph>
            </Flex>
            <Link { ...weselezklasa.link.link } target="_blank" rel="nofollow" opacity="0.75">
                <Box el="img" width="2.5" { ...weselezklasa.image } />
            </Link>
        </>
    );

};

export default FooterCopyrights;