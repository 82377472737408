/**
 * Exports typography-related design tokens
 */

import { token } from '../utils';
import { fontWeight } from '../config';

export const lineHeightPrecision = 0.5;

export const headingFontFamily = 'heading';
export const headingMarginBottom = 1.5;
export const headingDefaultColor = 'gray/5';
export const headingPrimaryColor = 'primary/40';
export const headingSecondaryColor = 'gray/10';

export const bodyFontFamily = 'body';
export const bodyLineHeight = 'perfect-sixth';
export const bodyMarginBottom = 2;
export const bodyPrimaryColor = 'gray/40';
export const bodySecondaryColor = 'gray/60';

export const buttonFontFamily = 'body';
export const buttonLineHeight = 'major-second';

export const labelFontWeight = 'medium';

export const kind = token('kind', [{
        key: 'h',
        value: [{
            key: '800',
            order: 800,
            value: {
                fontFamily: headingFontFamily,
                fontWeight: 'light',
                color: headingDefaultColor,
                fontSize: 'h1',
                lineHeight: 'major-second',
                marginBottom: headingMarginBottom,
            },
        },{
            key: '700',
            order: 700,
            value: {
                fontFamily: headingFontFamily,
                fontWeight: 'regular',
                color: headingDefaultColor,
                fontSize: 'h2',
                lineHeight: 'major-second',
                marginBottom: headingMarginBottom,
            },
        },{
            key: '600',
            order: 600,
            value: {
                fontFamily: headingFontFamily,
                fontWeight: 'regular',
                color: headingDefaultColor,
                fontSize: 'h3',
                lineHeight: 'major-third',
                marginBottom: headingMarginBottom,
            },
        },{
            key: '500',
            order: 500,
            value: {
                fontFamily: headingFontFamily,
                fontWeight: 'regular',
                color: headingDefaultColor,
                fontSize: 'h4',
                lineHeight: 'perfect-fourth',
                marginBottom: headingMarginBottom,
                wordSpacing: 'xloose',
            },
        },{
            key: '400',
            order: 400,
            value: {
                fontFamily: headingFontFamily,
                fontWeight: 'medium',
                color: headingDefaultColor,
                fontSize: 'h5',
                lineHeight: 'perfect-fourth',
                marginBottom: headingMarginBottom,
                wordSpacing: 'xloose',
            },
        },{
            key: '300',
            order: 300,
            value: {
                fontFamily: headingFontFamily,
                fontWeight: 'medium',
                color: headingDefaultColor,
                fontSize: 'h6',
                lineHeight: 'perfect-fifth',
                marginBottom: headingMarginBottom,
                wordSpacing: 'xloose',
            },
        }],
    },{
        key: 'p',
        value: [{
            key: 'lead',
            order: 400,
            value: {
                fontFamily: bodyFontFamily,
                fontWeight: 'regular',
                color: bodyPrimaryColor,
                fontSize: 'h6',
                lineHeight: bodyLineHeight,
                marginBottom: bodyMarginBottom,
                letterSpacing: 'dense',
            }
        },{
            key: 'body',
            order: 300,
            value: {
                fontFamily: bodyFontFamily,
                fontWeight: 'regular',
                color: bodyPrimaryColor,
                fontSize: 'body',
                lineHeight: bodyLineHeight,
                marginBottom: bodyMarginBottom,
                letterSpacing: 'dense',
            }
        },{
            key: 'label',
            order: 200,
            value: {
                fontFamily: bodyFontFamily,
                fontWeight: 'regular',
                color: bodyPrimaryColor,
                fontSize: 'label',
                lineHeight: bodyLineHeight,
                marginBottom: bodyMarginBottom,
                letterSpacing: 'dense',
            }
        },{
            key: 'caption',
            order: 100,
            value: {
                fontFamily: bodyFontFamily,
                fontWeight: 'regular',
                color: bodyPrimaryColor,
                fontSize: 'caption',
                lineHeight: bodyLineHeight,
                marginBottom: bodyMarginBottom,
            }
        },{
            key: 'small',
            order: 50,
            value: {
                fontFamily: bodyFontFamily,
                fontWeight: 'medium',
                color: bodyPrimaryColor,
                fontSize: 'small',
                lineHeight: bodyLineHeight,
                marginBottom: bodyMarginBottom,
                textTransform: 'uppercase',
            }
        }]
    },{
        key: 'label',
        value: {
            fontFamily: bodyFontFamily,
            fontWeight: labelFontWeight,
            color: 'gray/10',
            fontSize: 'label',
            lineHeight: bodyLineHeight,
        },
    },{
        key: 'abbr',
        value: {
            fontWeight: 'medium',
            fontStyle: 'normal',
        },
    },{
        key: 'button',
        value: [{
            key: 'medium',
            order: 200,
            value: {
                fontFamily: buttonFontFamily,
                fontWeight: 'semi-bold',
                fontSize: 'label',
                lineHeight: buttonLineHeight,
                letterSpacing: 'xxloose',
                textTransform: 'uppercase',
            },
        },{
            key: 'small',
            order: 100,
            value: {
                fontFamily: buttonFontFamily,
                fontWeight: 'semi-bold',
                fontSize: 'caption',
                lineHeight: buttonLineHeight,
                letterSpacing: 'xxloose',
                textTransform: 'uppercase',
            },
        }],
    },{
        key: 'quote',
        value: {
            fontFamily: headingFontFamily,
            fontWeight: 'light',
            color: 'gray/5',
            fontSize: 'h6',
            lineHeight: 'golden-ratio',
            fontStyle: 'italic',
            marginBottom: bodyMarginBottom,
        },
    },{
        key: 'counter',
        value: {
            fontFamily: headingFontFamily,
            fontWeight: 'regular',
            color: bodyPrimaryColor,
            fontSize: 'caption',
            lineHeight: 'golden-ratio',
            letterSpacing: 'xxxloose',
        },
    }]
);

const headingVariants = {
    secondary: {
        color: headingSecondaryColor,
    },
    primary: {
        color: headingPrimaryColor,
    },
};

export const headingKind = token('headingKind', [{
    key: 'h1',
    order: 6,
    value: {
        element: 'h1',
        kind: { small: 'h/700', medium: 'h/800', },
        variant: { ...headingVariants, },
    },
},{
    key: 'h2',
    order: 5,
    value: {
        element: 'h2',
        kind: { small: 'h/600', medium: 'h/700', },
        variant: { ...headingVariants, },
    },
},{
    key: 'h3',
    order: 4,
    value: {
        element: 'h3',
        kind: { small: 'h/500', medium: 'h/600', },
        variant: { ...headingVariants, },
    },
},{
    key: 'h4',
    order: 3,
    value: {
        element: 'h4',
        kind: { small: 'h/400', medium: 'h/500', },
        variant: { ...headingVariants, },
    },
},{
    key: 'h5',
    order: 2,
    value: {
        element: 'h5',
        kind: { small: 'h/300', medium: 'h/400', },
        variant: { ...headingVariants, },
    },
},{
    key: 'h6',
    order: 1,
    value: {
        element: 'h6',
        kind: { small: 'h/300', medium: 'h/300', },
        variant: { ...headingVariants, },
    },
}]);

export const defaultHeadingKind = headingKind.get('h6');
export const headingRendering = 'optimizeLegibility';
export const headingStrongFontWeight = fontWeight.get('bold');

export const bodyKind = token('bodyKind', [{
    key: 'lead',
    order: 5,
    value: {
        kind: 'p/lead',
        variant: {},
    },
},{
    key: 'body',
    order: 4,
    value: {
        kind: 'p/body',
        variant: {},
    },
},{
    key: 'label',
    order: 3,
    value: {
        kind: 'p/label',
        variant: {},
    },
},{
    key: 'caption',
    order: 2,
    value: {
        kind: 'p/caption',
        variant: {},
    },
},{
    key: 'small',
    order: 1,
    value: {
        kind: 'p/small',
        variant: {},
    },
}]);

export const defaultBodyKind = bodyKind.get('body');
export const bodyRendering = 'optimizeLegibility';
export const bodyStrongFontWeight = fontWeight.get('bold');

export const labelKind = token('labelKind', [{
    key: 'medium',
    order: 2,
    value: {
        kind: 'label',
        variant: {},
    },
}]);

export const defaultLabelKind = labelKind.get('medium');
export const disabledLabelColor = 'gray/60';
export const invalidLabelColor = 'gray/10';
export const labelTransitionDuration = 'short';
export const labelTransitionEasing = 'linear';

export const orderedListType = 'decimal';
export const unorderedListType = 'disc';
export const listStylePosition = 'outside';
export const listMarginBottom = bodyMarginBottom;
export const listMarginLeft = 2;
export const nestedListMarginLeft = 2;
export const listItemPaddingLeft = 1;

export const definitionTermKind = 'h/300';
export const definitionDetailsKind = 'p/body';

export const abbrKind = 'abbr';
export const abbrTextDecorationColor = 'gray/40';
export const abbrTextDecorationColorHover = 'primary/50';

export const linkColor = 'primary/50';
export const linkColorHover = 'primary/80';
export const linkColorActive = 'primary/80';