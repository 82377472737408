import { token } from '../../utils';

export default token('letterSpacing', [{
    key: 'normal',
    order: 0,
    value: 'normal',
},{
    key: 'dense',
    order: -0.05,
    value: '-0.05em',
},{
    key: 'loose',
    order: 0.01,
    value: '0.01em',
},{
    key: 'xloose',
    order: 0.03,
    value: '0.03em',
},{
    key: 'xxloose',
    order: 0.05,
    value: '0.05em',
},{
    key: 'xxxloose',
    order: 0.25,
    value: '0.25em',
}]);
