import React from 'react';

function Camera48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "camera 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M32.562,20.443A4.9,4.9,0,0,0,25,21.2a4.916,4.916,0,1,0-7.562,6.206L25,35l7.563-7.59A4.939,4.939,0,0,0,32.562,20.443Z" fill="none"/>
		<path d="M6,43H42a4,4,0,0,0,4-4V15a4,4,0,0,0-4-4H35L31,4H19l-4,7H6a4,4,0,0,0-4,4V39A4,4,0,0,0,6,43Z" fill="none" stroke={fill}/>
		<circle cx="9" cy="18" fill="none" r="1"/>
	</g>
</svg>
	);
};

export default Camera48;