/**
 * Exports Seo
 */

import React from 'react';
import pt from 'prop-types';
import { useSeo } from '../../utils';

const OPEN_GRAPH_TYPE = 'website';

/**
 * Seo React component
 * Used in Gatsby page template inside the exported <Head> component to add SEO meta tags in the <head> webpage section
 * see: https://www.gatsbyjs.com/docs/how-to/adding-common-features/adding-seo-component/
 * @param {object} params
 */
const Seo = ({ title, description, path, image, children }) => {

    const seo = useSeo();

    title = `${ title }${ seo.titlePostfix }`;
    description = description || seo.description;
    image = `${ seo.siteUrl }${ image || seo.image }`;
    const lang = seo.lang;
    const url = `${ seo.siteUrl }${ path || '' }`;

    return (
        <>
            <html lang={ lang } />
            <title>{ title }</title>
            <meta name="description" content={ description } />
            <meta name="og:title" content={ title } />
            <meta name="og:description" content={ description } />
            <meta name="og:type" content={ OPEN_GRAPH_TYPE } />
            <meta name="og:image" content={ image } />
            <meta name="og:url" content={ url } />
            <meta name="og:locale" content={ seo.locale } />
            <meta name="og:site_name" content={ seo.name } />
            <link rel="canonical" href={ url } />
            { children }
        </>
    );

};

Seo.propTypes = {

    /**
     * Page meta title
     */
    title: pt.string.isRequired,

    /**
     * Page meta description
     */
    description: pt.string.isRequired,

    /**
     * URL path e.g. /contact
     */
    path: pt.string,

    /**
     * Path to the image illustrating the page
     */
    image: pt.string,

    /**
     * Any additional HTML tags to add in <head> section
     */
    children: pt.node,

};

export default Seo;