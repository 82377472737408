import React from 'react';

function ChessTower48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "chess tower 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<polyline fill="none" points="12 38 18 19 11 13 11 4 16 4 18 8 20 8 22 4 26 4 28 8 30 8 32 4 37 4 37 13 30 19 36 38" stroke={fill}/>
		<line fill="none" stroke={fill} x1="18" x2="30" y1="19" y2="19"/>
		<rect height="6" width="34" fill="none" x="7" y="38"/>
	</g>
</svg>
	);
};

export default ChessTower48;