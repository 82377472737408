import { token } from '../../utils';
import { rem } from '../size';

export default token('boxShadow', [{
    key: 'out',
    order: 1,
    value: [{
        key: 'small',
        order: 10,
        value: `0 ${rem(0.5)} ${rem(4)} ${rem(-1)} rgba(0, 0, 0, 0.1), 0 0 ${rem(12)} rgba(0, 0, 0, 0.1)`,
    },{
        key: 'medium',
        order: 20,
        value: `0 ${rem(0.5)} ${rem(4)} ${rem(-0.5)} rgba(0, 0, 0, 0.2), 0 0 ${rem(12)} rgba(0, 0, 0, 0.1)`,
    },{
        key: 'large',
        order: 30,
        value: `0 ${rem(1)} ${rem(6)} ${rem(-1)} rgba(0, 0, 0, 0.5), 0 0 ${rem(16)} rgba(0, 0, 0, 0.15)`,
    }],
},{
    key: 'in',
    order: 2,
    value: [{
        key: 'small',
        order: 10,
        value: `inset 0 ${rem(0.25)} ${rem(0.5)} rgba(0, 0, 0, 0.1)`,
    },{
        key: 'medium',
        order: 20,
        value: `inset 0 ${rem(0.25)} ${rem(1)} rgba(0, 0, 0, 0.2)`,
    }]
}]);