import React from 'react';

function Facebook48(props) {
	const fill = props.fill || 'currentColor';
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "facebook 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="none">
		<path d="M47 24.138a23 23 0 1 0-26.594 22.721V30.786h-5.839v-6.648h5.839V19.07c0-5.764 3.434-8.948 8.687-8.948 1.725.024 3.446.174 5.149.449v5.66h-2.9a3.325 3.325 0 0 0-3.747 3.594v4.313h6.379l-1.02 6.648h-5.36v16.073A23 23 0 0 0 47 24.138z" fill={fill}/>
	</g>
</svg>
	);
};

export default Facebook48;