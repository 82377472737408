/**
 * Exports getSeo
 */

import { hasProperty } from "./misc";

/**
 * Returns SEO properties from page query content
 * @param {object} location - Gatsby location object from page template props
 * @param {object} seo - contains ```title``` and/or ```description``` properties for page meta data
 * @returns object with props accepted by Seo component, see /src/components/Seo
 */
const getSeo = (location, seo) => {

    const path = (typeof location === 'object' && hasProperty(location, 'pathname', 'string')) ? location.pathname : '';

    let title = '', description = '', image = '';

    if(typeof seo === 'object'){
        if(hasProperty(seo, 'title', 'string')) title = seo.title;
        if(hasProperty(seo, 'description', 'string')) description = seo.description;
        if(hasProperty(seo, 'image', 'object') && seo.image && hasProperty(seo.image, 'childImageSharp', 'object')) image = seo.image.childImageSharp.original.src;
    }

    return {
        path,
        title,
        description,
        image,
    };

};

export default getSeo;