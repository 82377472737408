/**
 * Exports modal-related design tokens
 */

import { token } from '../utils';

// Check animation.js for the list of available animations
export const defaultAnimation = 'fade';
export const animationDuration = 'long';

// Used when animation = false
export const instantAnimationDuration = 'none';

// How elastic dragging movement to close the modal should be (between 0 to 1)
// See: https://www.framer.com/docs/gestures/###dragelastic
export const dragElastic = 0.5;

// Minimum change in position of the modal to treat it as close action
export const minDragOffset = 100;

// Minimum velocity of the modal to treat it as close action
export const minDragVelocity = 500;

// Close icon button config
export const closeButtonGlyph = 'close';
export const closeButtonVariant = 'white';

// horizontal margin for selected viewports
const mediumHorizontalMargin = 3;
const smallHorizontalMargin = 2;

const borderRadius = 'small';

const xlargePadding = 8;
const largePadding = 5;
const mediumPadding = 4;
const smallPadding = 2;

/**
 * Modal size which applies responsive properties such as:
 * - width - modal width,
 * - borderRadius - modal border radius
 * - padding - modal padding
 * - icon - close icon size
 * - iconTop - close icon top margin
 * - iconRight - close icon right margin
 */
 export const size = token('overlaySize', [{
    key: 'xlarge',
    order: 100,
    value: {
        width: '100%',
        borderRadius,
        padding: {
            small: smallPadding,
            medium: mediumPadding,
            large: xlargePadding,
        },
        icon: 'large',
        iconTop: {
            small: smallPadding,
            medium: mediumPadding,
        },
        iconRight: {
            small: smallPadding,
            medium: mediumPadding,
        },
    },
},{
    key: 'large',
    order: 80,
    value: {
        width: {
            small: '100%',
            medium: `calc(100% - ${ 2 * mediumHorizontalMargin })`,
            large: 100,
        },
        borderRadius, 
        padding: {
            small: smallPadding,
            medium: mediumPadding,
            large: largePadding,
        },
        icon: 'large',
        iconTop: {
            small: smallPadding,
            medium: mediumPadding,
        },
        iconRight: {
            small: smallPadding,
            medium: mediumPadding,
        },
    },
},{
    key: 'medium',
    order: 60,
    value: {
        width: {
            small: '100%',
            medium: 68,
        },
        borderRadius,
        padding: {
            small: smallPadding,
            medium: mediumPadding,
            large: largePadding,
        },
        icon: 'large',
        iconTop: {
            small: smallPadding,
            medium: mediumPadding,
        },
        iconRight: {
            small: smallPadding,
            medium: mediumPadding,
        },
    },
},{
    key: 'small',
    order: 40,
    value: {
        width: {
            small: `calc(100% - ${ 2 * smallHorizontalMargin })`,
            medium: 44,
        },
        borderRadius,
        padding: {
            small: smallPadding,
            medium: mediumPadding,
        },
        icon: 'large',
        iconTop: {
            small: smallPadding,
            medium: mediumPadding,
        },
        iconRight: {
            small: smallPadding,
            medium: mediumPadding,
        },
    },
},{
    key: 'xsmall',
    order: 20,
    value: {
        width: {
            small: `calc(100% - ${ 2 * smallHorizontalMargin })`,
            medium: 32,
        },
        borderRadius,
        padding: {
            small: smallPadding,
            medium: mediumPadding,
        },
        icon: 'large',
        iconTop: {
            small: smallPadding,
            medium: mediumPadding,
        },
        iconRight: {
            small: smallPadding,
            medium: mediumPadding,
        },
    },
}]);

export const defaultSize = 'small';