/**
 * Exports CookieModal
 */

import React, { useState, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql, Script } from 'gatsby';
import { Modal, Heading, Paragraph, Text } from '@arturpol/systheme';
import { getLink, Replace } from '../../utils';
import { TextLink } from '../Link';
import { PrimaryButton } from '../Button';

// Local storage key and value
const LS_KEY = 'cookie-consent';
const LS_VALUE_DEFAULT = 'denied';
const LS_VALUE_ACCEPTED = 'accepted';

/**
 * React hook
 * @returns cookie consent texts for modal and tracking scripts
 */
const useCookieContent = () => {

    const { data } = useStaticQuery(graphql`
        query getCookieContent {
            data(_id: {eq: "site"}) {
                cookies {
                    title
                    description
                    link {
                        _page
                        anchor
                        title
                    }
                    buttons {
                        accept {
                            anchor
                            title
                        }
                        deny {
                            anchor
                            title
                        }
                    }
                }
                scripts {
                    googleAnalytics
                    googleAnalyticsInline
                    facebookPixel
                }
            }
        }
    `);
    
    const { link, buttons, description, ...rest } = data.cookies;
    const policyLink = getLink(link);

    const replacements = {
        policy: <TextLink { ...policyLink.link } />
    };

    return {
        accept: getLink(buttons.accept).link,
        deny: getLink(buttons.deny).link,
        description: <Replace text={ description } replacements={ replacements } />,
        scripts: [{
            id: 'google-analytics',
            src: data.scripts.googleAnalytics.replace('GA_TRACKING_ID', process.env.GATSBY_GA_TRACKING_ID),
        }],
        inlineScripts: [{
            id: 'google-analytics',
            content: data.scripts.googleAnalyticsInline.replace('GA_TRACKING_ID', process.env.GATSBY_GA_TRACKING_ID),
        },{
            id: 'facebook-pixel',
            content: data.scripts.facebookPixel.replace('FB_PIXEL_ID', process.env.GATSBY_FB_PIXEL_ID),
        }],
        ...rest,
    };

};

/**
 * CookieModal React component
 * Renders cookie consent modal and enables tracking if user accepts the conditions.
 * Add/Modify tracking scripts via /content/data/site.yml (cookies/scripts) e.g. Google Anlytics
 * @param {object} props 
 */
const CookieModal = props => {

    const { title, description, accept, deny, scripts, inlineScripts } = useCookieContent();
    const [ opened, setOpened ] = useState(false);
    const [ accepted, setAccepted ] = useState(false);

    const onClose = useCallback(reason => {
        
        if(reason === 'button') setOpened(false);
 
    }, []);

    const onAccept = useCallback(() => {
        
        localStorage.setItem(LS_KEY, LS_VALUE_ACCEPTED);
        setOpened(false);
        setAccepted(true);

    }, []);

    const onDeny = useCallback(() => {
        
        localStorage.setItem(LS_KEY, LS_VALUE_DEFAULT);
        setOpened(false);

    }, []);

    useEffect(() => {

        const consent = localStorage.getItem(LS_KEY);

        if(consent !== LS_VALUE_ACCEPTED && consent !== LS_VALUE_DEFAULT){
            setOpened(true);
        }

        if(consent === LS_VALUE_ACCEPTED){
            setAccepted(true);
        }

    }, []);

    props = {
        opened,
        onClose,
        size: 'small',
        alignX: { small: 'stretch', medium: 'center', },
        alignY: 'center',
        backdrop: true,
        mb: '10vh',
        ...props,
    };

    return (
        <>
            <Modal { ...props }>
                <Heading kind="h6" mt="0.5">{ title }</Heading>
                <Paragraph kind="caption" mb="3">{ description }</Paragraph>
                <PrimaryButton motion { ...accept } width="stretch" onTap={ onAccept } mb="2" />
                <Text display="block" kind="button/small" textAlign="center">
                    <TextLink motion { ...deny } onTap={ onDeny } color="gray/40" />
                </Text>
            </Modal>
            { accepted ? scripts.map(({ id, src }) => (
                <Script key={ id } src={ src } async />
            )) : null }
            { accepted ? inlineScripts.map(({ id, content }) => (
                <Script key={ id } dangerouslySetInnerHTML={{ __html: content }} />
            )) : null }
        </>
    );

};

export default CookieModal;