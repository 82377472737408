/**
 * Exports getBreadcrumbListSchema
 */

const getListItemSchema = ({ name, url }, index, baseUrl) => {

    let result = {
        '@type': 'ListItem',
        position: index + 1,
        name,
    };

    if(typeof url === 'string'){
        result = { ...result, item: `${baseUrl}${url}`, };
    }

    return result;

};

const getBreadcrumbListSchema = (items, baseUrl) => {

    return {
        '@type': 'BreadcrumbList',
        itemListElement: items.map((item, index) => getListItemSchema(item, index, baseUrl)),
    };

};

export default getBreadcrumbListSchema;