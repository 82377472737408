/**
 * Exports tag-related design tokens
 */

import { bodyPrimaryColor } from "./typography";

export const iconSize = 'medium';
export const height = 5.5;
export const horizontalPadding = 2;
export const gap = 'xsmall';
export const labelKind = 'caption';
export const backgroundColor = 'gray/90';
export const borderRadius = 'small';
export const iconColor = bodyPrimaryColor;