import { token } from '../../utils';

export default token('color', [{
    key: 'black',
    value: '#000000',
},{
    key: 'white',
    value: '#ffffff',
},{
    key: 'gray',
    value: [{
        order: 100,
        key: 'gray-100',
        value: '#000000',
    },{
        order: 90,
        key: 'gray-90',
        value: '#121212',
    },{
        order: 80,
        key: 'gray-80',
        value: '#1C1A1A',
    },{
        order: 70,
        key: 'gray-70',
        value: '#212020',
    },{
        order: 60,
        key: 'gray-60',
        //Original: '#5E4635',
        value: '#42352C',
    },{
        order: 40,
        key: 'gray-40',
        // Original: #AC9381
        value: '#afa092',
    },{
        order: 10,
        key: 'gray-10',
        value: '#F6E2C2',
    },{
        order: 5,
        key: 'gray-05',
        value: '#FBF6F7',
    },{
        order: 0,
        key: 'gray-00',
        value: '#ffffff'
    }]
},{
    key: 'primary',
    value: [{
        order: 80,
        key: 'primary-80',
        value: '#C37830',
    },{
        order: 50,
        key: 'primary-50',
        value: '#DA9A5D',
    },{
        order: 40,
        key: 'primary-40',
        value: '#D2A57A',
    }]
},{
    key: 'success',
    value: [{
        order: 80,
        key: 'success-80',
        value: '#2B3B31',
    },{
        order: 50,
        key: 'success-50',
        value: '#89C29E',
    }]
},{
    key: 'alert',
    value: [{
        order: 80,
        key: 'alert-80',
        // Original: #751317
        value: '#971D21',
    },{
        order: 50,
        key: 'alert-50',
        // Original: #CB555A
        value: '#AE3539',
    }],
}]);