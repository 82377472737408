/**
 * Exports useCalloutButton
 */

import { useStaticQuery, graphql } from 'gatsby';
import { getLink } from '../helpers';

/**
 * React hook
 * Returns callout button content based on its id
 * @param {string} id - callout id (see /content/data/button.yml)
 * @returns button link properties
 */
const useCalloutButton = id => {

    let { allButton } = useStaticQuery(graphql`
        query getCalloutButtons {
            allButton {
                nodes {
                    _id
                    _menu
                    _page
                    anchor
                    title
                }
            }
        }
    `);
    
    // find one, remove id
    const buttons = allButton.nodes
        .filter(item => item._id === id)
        .map(item => getLink(item));

    if(buttons.length < 1) return false;

    return {
        ...buttons[0],
    };

};

export default useCalloutButton;