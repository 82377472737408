import React from 'react';

function Horse24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "horse 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M7.389,8.81A3.936,3.936,0,0,0,5,8a4,4,0,0,0-4,4" fill="none" stroke={fill}/>
		<path d="M7,19.2a5,5,0,1,1,10,0" fill="none" stroke={fill}/>
		<path d="M3.522,17.565l2.521-6.932A4,4,0,0,1,9.8,8h3.505a2.034,2.034,0,0,0,1.918-1.487A5,5,0,0,1,20,3V5l1.545,2.318A1,1,0,0,1,21.42,8.58L21,9,19,8l-1,3,2.465,6.574" fill="none" stroke={fill}/>
		<path d="M1.734,16.266a15.979,15.979,0,0,0,20.532,0" fill="none"/>
	</g>
</svg>
	);
};

export default Horse24;