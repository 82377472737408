import React from 'react';

function Warning20(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 2;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "warning 20";

	return (
		<svg height={height} width={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth} transform="translate(0.5 0.5)">
		<path d="M1.03 15.73l7.4-13.98a1.74 1.74 0 0 1 3.07 0l7.39 13.98a1.73 1.73 0 0 1-1.54 2.53h-14.78a1.73 1.73 0 0 1-1.54-2.53z" fill="none" stroke={fill}/>
		<path d="M9.96 6.64v4.98" fill="none" stroke={secondaryfill}/>
		<path d="M9.96 13.28a1.24 1.24 0 1 0 0 2.49 1.24 1.24 0 1 0 0-2.49z" stroke="none"/>
	</g>
</svg>
	);
};

export default Warning20;