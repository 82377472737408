/**
 * Exports Replace
 */

import React from 'react';
import pt from 'prop-types';
import { hasProperty } from './misc';

// Splits text into chunks before and after a replacement pattern e.g. 'This is {pattern } to replace'
const REPLACEMENT_PATTERN_REGEXP = /(\{[^}]+\})/;

// Matches replacement e.g. '{pattern }';
const REPLACEMENT_KEY_REGEXP = /\{\s*([^\s}]+)\s*\}/;

/**
 * Replace React component
 * Replaces single text string with React.Fragments containing parts of this text and replaced tokens e.g. { name }.
 * @param {object} params
 */
const Replace = ({ text, replacements }) => {
    
    if(!replacements) replacements = {};
    if(text.length <= 2) return text;

    const keys = Object.keys(replacements);
    if(keys.length <= 0) return text;

    return (
        <>
            { text.split(REPLACEMENT_PATTERN_REGEXP).map((part, index) => {
                // Map both regular strings and replacement patterns
                
                let content = part;
                const matches = part.match(REPLACEMENT_KEY_REGEXP);
                
                if(matches && matches.length > 1){
                    // Try to replace the replacement pattern
                    const key = matches[1];
                    if(hasProperty(replacements, key)) content = replacements[key];
                } 
                
                return <React.Fragment key={ index }>{ content }</React.Fragment>;

            })}
        </>
    );

};

Replace.propTypes = {
    
    /**
     * Text containing replacement patterns e.g. 'This is {pattern } to replace'
     */
    text: pt.string.isRequired,

    /**
     * Object with keys being names of replacement patterns and values being anything that React can render e.g. string, node.
     * E.g. { name: 'John', avatar: <Avatar id={user.id} /> }
     */
    replacements: pt.object,

};

Replace.defaultProps = {
    replacements: {},
};

export default Replace;