import React from 'react';

function Minus24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 2;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "minus 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<line fill="none" stroke={fill} x1="22" x2="2" y1="12" y2="12"/>
	</g>
</svg>
	);
};

export default Minus24;