/**
 * Exports ImageItem
 */

import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, tokenProp, getTextLineHeight, useCssProps } from '@arturpol/systheme';
import { useRevealTransition } from '../../utils';

/**
 * React hook
 * @return array of CSS properties to apply to <figcaption> element
 */
const useCaptionStyle = () => {

    const theme = useTheme();
    const kind = theme.imageItem.captionTextKind;
    const marginTop = theme.imageItem.captionMarginTop;

    // Compute line height separatelly like in <Text /> component
    const lh = getTextLineHeight(kind, theme);

    // Get style props from theme text kind
    const { lineHeight, ...props } = tokenProp(theme.typography.kind, theme.imageItem.captionTextKind);

    // Transform design tokens into CSS values
    const { $css } = useCssProps({ ...props, marginTop, });

    return [
        ...$css,
        { key: lineHeight, value: lh, },
    ];

};

/**
 * StyledImageItem React component
 * Component that should wrap an Mdx image adding caption styles
 */
const StyledImageItem = styled(Box)`
    list-style: none;
    
    figcaption{
        ${params => params.$captionCss.map(({key, value}) => `${key}: ${value};`)}

        strong, b {
            font-weight: ${ props => props.theme.typography.bodyStrongFontWeight.val() };
        }
    }
`;

const ImageItem = props => {

    // Get <figcaption> element style 
    const captionCss = useCaptionStyle();
    
    const revealProps = useRevealTransition();

    props = {
        el: 'li',
        $captionCss: captionCss,
        ...revealProps,
        ...props,
    };

    return <StyledImageItem { ...props } />;

};

export default ImageItem;