import React from 'react';

function ChessKing32(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "chess king 32";

	return (
		<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<circle cx="3" cy="8" fill="none" r="2" stroke={fill}/>
		<circle cx="29" cy="8" fill="none" r="2" stroke={fill}/>
		<circle cx="16" cy="3" fill="none" r="2" stroke={fill}/>
		<path d="M3.63,9.889,9,26" fill="none" stroke={fill}/>
		<path d="M15.45,4.924,12,17,4.414,9.414" fill="none" stroke={fill}/>
		<path d="M27.586,9.414,20,17,16.538,4.927" fill="none" stroke={fill}/>
		<path d="M23,26,28.37,9.889" fill="none" stroke={fill}/>
		<rect height="5" width="22" fill="none" x="5" y="26"/>
	</g>
</svg>
	);
};

export default ChessKing32;