/**
 * Exports global style settings
 */

import { color, fontWeight, fontFamily } from './config';

/**
 * Exports <html> font size and size of a base unit used by default for space measurements
 */
import { baseFontSize, baseUnit } from './config/size';
export { baseFontSize, baseUnit };

/**
 * Print stylesheet settings
 */
export const printBackgrounds = true;
export const printUrls = true;
export const printBorderColor = color.get('gray', 10);

/**
 * HTML <body> styles
 */
export const bodyBackground = color.get('gray/90');
export const bodyFontSize = baseFontSize;
export const bodyLineHeight = 1.75;
export const bodyFontWeight = fontWeight.get('regular');
export const bodyFontFamily = fontFamily.get('body');
export const bodyAntialiaseFont = true;
