import React from 'react';

function Instagram24(props) {
	const fill = props.fill || 'currentColor';
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "instagram 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="none">
		<path d="M12 2.982c2.937 0 3.285.011 4.445.064a6.072 6.072 0 0 1 2.042.379 3.4 3.4 0 0 1 1.265.823 3.4 3.4 0 0 1 .823 1.265 6.07 6.07 0 0 1 .379 2.042c.053 1.16.064 1.508.064 4.445 0 2.937-.011 3.285-.064 4.445a6.07 6.07 0 0 1-.379 2.042 3.644 3.644 0 0 1-2.088 2.088 6.07 6.07 0 0 1-2.042.379c-1.16.053-1.508.064-4.445.064-2.937 0-3.285-.011-4.445-.064a6.07 6.07 0 0 1-2.042-.379 3.4 3.4 0 0 1-1.265-.823 3.4 3.4 0 0 1-.823-1.265 6.072 6.072 0 0 1-.379-2.042c-.053-1.16-.064-1.508-.064-4.445 0-2.937.011-3.285.064-4.445a6.072 6.072 0 0 1 .379-2.042 3.4 3.4 0 0 1 .823-1.265 3.4 3.4 0 0 1 1.265-.823 6.072 6.072 0 0 1 2.042-.379c1.16-.053 1.508-.064 4.445-.064zM12 1c-2.987 0-3.362.013-4.535.066a8.108 8.108 0 0 0-2.67.511A5.625 5.625 0 0 0 1.577 4.8a8.108 8.108 0 0 0-.511 2.67C1.013 8.638 1 9.013 1 12s.013 3.362.066 4.535c.019.912.192 1.815.511 2.67A5.625 5.625 0 0 0 4.8 22.423c.855.32 1.758.492 2.67.511C8.638 22.987 9.013 23 12 23s3.362-.013 4.535-.066a8.109 8.109 0 0 0 2.67-.511 5.626 5.626 0 0 0 3.218-3.223c.32-.855.492-1.758.511-2.67.053-1.168.066-1.543.066-4.53s-.013-3.362-.066-4.535a8.109 8.109 0 0 0-.511-2.67A5.626 5.626 0 0 0 19.2 1.577a8.108 8.108 0 0 0-2.67-.511C15.362 1.013 14.987 1 12 1z" fill={fill}/>
		<path d="M12 6.351a5.649 5.649 0 1 0 0 11.298A5.649 5.649 0 0 0 12 6.35zm0 9.316a3.667 3.667 0 1 1 0-7.334 3.667 3.667 0 0 1 0 7.334z" fill={fill}/>
		<path d="M17.872 7.448a1.32 1.32 0 1 0 0-2.64 1.32 1.32 0 0 0 0 2.64z" fill={fill}/>
	</g>
</svg>
	);
};

export default Instagram24;