import React from 'react';

function Love48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "love 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<line fill="none" stroke={secondaryfill} x1="11" x2="16.043" y1="37" y2="31.957"/>
		<line fill="none" stroke={secondaryfill} x1="32" x2="42.5" y1="16" y2="5.5"/>
		<polygon fill="none" points="11 41 11 37 7 37 5 39 9 39 9 43 11 41" stroke={secondaryfill}/>
		<polygon points="41 4 45 3 44 7 41 4" stroke="none"/>
		<polygon fill="none" points="41 4 45 3 44 7 41 4" stroke={secondaryfill}/>
		<path d="M31.273,9A8.822,8.822,0,0,0,24,12.928a8.717,8.717,0,0,0-16,4.8c0,8.727,16,21.818,16,21.818S40,26.455,40,17.728A8.752,8.752,0,0,0,31.273,9Z" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Love48;