/**
 * Exports useRevealTransition
 */

import { useRef } from 'react';
import { useTheme } from 'styled-components';
import { useInView } from 'framer-motion';
import { easingProp, durationProp } from '@arturpol/systheme';

// Transition vertical shift (distance)
const TRANSITION_SHIFT_Y = 3;

// Duration of transition
const TRANSITION_DURATION = 'xxlong';

/**
 * React hook
 * @return props to include in a Box-based component
 */
const useRevealTransition = () => {
    
    const theme = useTheme();
    const ref = useRef(null);

    const isInView = useInView(ref, {
        once: true,
    });

    const duration = durationProp(theme.duration, TRANSITION_DURATION);

    return {
        motion: true,
        ref,
        animate: (isInView ? 'visible' : 'hidden'),
        variants: {
            visible: {
                opacity: 1,
                y: '0rem',
                transition: {
                    duration,
                    ease: easingProp(theme.easing, 'ease-out/weak'),
                },
            },
            hidden: {
                opacity: 0.01,
                y: theme.rem(TRANSITION_SHIFT_Y),
                transition: {
                    duration,
                    ease: easingProp(theme.easing, 'ease-in/weak'),
                },
            },
        },
    };

};

export default useRevealTransition;