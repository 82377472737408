/**
 * Scroll-related functions
 */

import { animate } from 'framer-motion';

/**
 * @returns browser-independent scrollTop property
 */
const getScrollTop = () => window.pageYOffset || document.documentElement.scrollTop; 

/**
 * Scrolls smoothly to a particular position on document Y axis
 * @param {number} y - optional position in pixels (0 is the top)
 * @param {number} duration - optional scroll duration in seconds
 * @param {function} callback - optional callback to fire when complete
 */
const smoothScroll = (y = 0, duration = 0, callback) => {

    const currentY = getScrollTop();

    const settings = {
        duration,
        onUpdate: val => window.scrollTo(0, val),
    };

    if(typeof callback === 'function') settings.onComplete = callback;

    animate(currentY, y, settings);

};

/**
 * Scrolls smoothly document to the specified element top edge
 * @param {HTMLElement} element - HTML element
 * @param {number} duration - optional duration in seconds
 * @param {number} offset - offset in pixels from top edge of the element
 * @param {function} callback - optional callback to fire when complete
 */
const scrollToElement = (element, duration = 0, offset = 0, callback) => {

    const rect = element.getBoundingClientRect();
    const scrollTop = getScrollTop();
    
    let target = scrollTop + rect.top - offset;
    if(target < 0) target = 0;

    smoothScroll(target, duration, callback);

};

/**
 * Scrolls smoothly to the top of the document
 * @param {number} duration - optional duration in seconds
 * @param {number} offset - offset in pixels from top of the document
 * @param {function} callback - optional callback to fire when complete
 */
const scrollToTop = (duration = 0, offset = 0, callback) => {

    smoothScroll(duration, offset, callback);

};

export {
    scrollToElement,
    scrollToTop
};