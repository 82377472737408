/**
 * Exports useGrouppedLandings
 */

import useGrouppedLocations from './useGrouppedLocations';
import useLandings from './useLandings';

/**
 * React hook
 * @returns landing pages grouped by subregion, town and sorted alphabetically
 */
const useGrouppedLandings = () => {

    const locations = useGrouppedLocations();
    const landings = useLandings();

    const landingsPerTown = landings.reduce((prev, { id, title, _location }) => {
        
        const pages = prev.hasOwnProperty(_location) ? prev[_location] : [];
        
        return {
            ...prev,
            [ _location ]: pages.concat([{ id, title, }]),
        };

    }, {});

    return locations.map(({ towns, ...rest }) => {

        return {
            ...rest,
            towns: towns.map(town => {
                
                const pages = landingsPerTown.hasOwnProperty(town.id) ? landingsPerTown[town.id] : [];
                
                return {
                    ...town,
                    pages,
                };

            }).filter(town => town.pages.length > 0),
        };

    });

};

export default useGrouppedLandings;