import { token } from '../../utils';

export default token('fontWeight', [{
    key: 'bold',
    order: 700,
    value: 700,
},{
    key: 'semi-bold',
    order: 600,
    value: 600,
},{
    key: 'medium',
    order: 500,
    value: 500,
},{
    key: 'regular',
    order: 400,
    value: 400,
},{
    key: 'light',
    order: 300,
    value: 300,
}]);