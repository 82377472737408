import React from 'react';

function Virus48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "virus 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<line fill="none" stroke={fill} x1="13.733" x2="17.429" y1="4.44" y2="2.909"/>
		<line fill="none" stroke={fill} x1="15.581" x2="17.877" y1="3.675" y2="9.218"/>
		<line fill="none" stroke={fill} x1="2.909" x2="4.44" y1="17.429" y2="13.733"/>
		<line fill="none" stroke={fill} x1="3.675" x2="9.218" y1="15.581" y2="17.877"/>
		<line fill="none" stroke={fill} x1="4.44" x2="2.909" y1="34.267" y2="30.571"/>
		<line fill="none" stroke={fill} x1="3.675" x2="9.218" y1="32.419" y2="30.123"/>
		<line fill="none" stroke={fill} x1="17.429" x2="13.733" y1="45.091" y2="43.56"/>
		<line fill="none" stroke={fill} x1="15.581" x2="17.877" y1="44.325" y2="38.782"/>
		<line fill="none" stroke={fill} x1="34.267" x2="30.571" y1="43.56" y2="45.091"/>
		<line fill="none" stroke={fill} x1="32.419" x2="30.123" y1="44.325" y2="38.782"/>
		<line fill="none" stroke={fill} x1="45.091" x2="43.56" y1="30.571" y2="34.267"/>
		<line fill="none" stroke={fill} x1="44.325" x2="38.781" y1="32.419" y2="30.123"/>
		<line fill="none" stroke={fill} x1="43.56" x2="45.091" y1="13.733" y2="17.429"/>
		<line fill="none" stroke={fill} x1="44.325" x2="38.782" y1="15.581" y2="17.877"/>
		<line fill="none" stroke={fill} x1="30.571" x2="34.267" y1="2.909" y2="4.44"/>
		<line fill="none" stroke={fill} x1="32.419" x2="30.123" y1="3.675" y2="9.218"/>
		<circle cx="20" cy="18" fill="none" r="2"/>
		<circle cx="20.5" cy="29.5" fill="none" r="2.5"/>
		<circle cx="30" cy="24" fill="none" r="3"/>
		<circle cx="24" cy="24" fill="none" r="16" stroke={fill}/>
	</g>
</svg>
	);
};

export default Virus48;