/**
 * Exports useSeo
 */

import { useStaticQuery, graphql } from 'gatsby';
import { hasProperty } from '../helpers';
import useSiteMetadata from './useSiteMetadata';

/**
 * React hook
 * @param {string} prop - property to return, optional
 * @returns site SEO property with ```prop``` name or all properties from /content/data/site.yml
 */
const useSiteSeo = prop => {

    const { data } = useStaticQuery(graphql`
        query getSiteSeo {
            data(_id: {eq: "site"}) {
                locale
                seo {
                    name
                    lang
                    titlePostfix
                    image {
                        childImageSharp {
                            original {
                                src
                            }
                        }
                    }
                }
            }
        }
    `);

    const { image, ...rest } = data.seo;
    
    const seo = { 
        ...rest, 
        image: image.childImageSharp.original.src,
        locale: data.locale,
    };

    return (typeof prop === 'string' && hasProperty(seo, prop)) ? seo[prop] : seo;

};

/**
 * React hook
 * @param {string} prop - property to return, optional
 */
const useSeo = prop => {

    const siteSeo = useSiteSeo();
    const siteMetadata = useSiteMetadata();

    const seo = {
        ...siteSeo,
        ...siteMetadata,
    };

    return (typeof prop === 'string' && hasProperty(seo, prop)) ? seo[prop] : seo;

};

export default useSeo;