/**
 * Exports TagCarousel
 */

import React, { useCallback } from 'react';
import pt from 'prop-types';
import { useCarouselActions, useCarouselState } from '../Carousel';
import { TagWrapper } from '../Tag';
import { TagNavigation } from './Navigation';
import { TagSlideList } from './SlideList';

/**
 * TagCarousel React component
 * Carousel that looks like a single tag, that changes its content either through user navigation or automatically.
 * Requires <CarouselProvider> with specified state higher in the component tree for <TagCarousel> to work properly.
 * See /src/components/Carousel
 */
const TagCarousel = React.forwardRef(({ onMouseEnter, onMouseLeave, mapping, ...rest }, ref) => {

    // Fetches carousel actions and state from CarouselProvider
    const { next, previous } = useCarouselActions();
    const { current, items, ...callback } = useCarouselState();
    const slides = typeof mapping === 'function' ? items.map(mapping) : items;
    
    const onMouseEnterCallback = callback.onMouseEnter;
    const onMouseLeaveCallback = callback.onMouseLeave;

    // Pause slide change when mouse is over the carousel
    const enterCallback = useCallback(event => {
        onMouseEnterCallback(event);
        if(typeof onMouseEnter === 'function') onMouseEnter(event);
    }, [ onMouseEnter, onMouseEnterCallback ]);

    // Resule slide change...
    const leaveCallback = useCallback(event => {
        onMouseLeaveCallback(event);
        if(typeof onMouseLeave === 'function') onMouseLeave(event);
    }, [ onMouseLeave, onMouseLeaveCallback ]);

    const props = {
        alignItems: 'stretch',
        alignContent: 'stretch',
        ...rest,
        onMouseEnter: enterCallback,
        onMouseLeave: leaveCallback,
        ref,
    };

    return (
        <TagWrapper { ...props }>
            <TagSlideList items={ slides } current={ current } span="stretch" />
            <TagNavigation current={ current } maximum={ slides.length } next={ next } previous={ previous } span="shrink" />
        </TagWrapper>
    );

});

TagCarousel.propTypes = {
    
    /**
     * Item mapping function applied to every item supplied from the CarouselProvider
     * It should return object with following properties: label (required), icon, image.
     */
    mapping: pt.func,

};

export default TagCarousel;