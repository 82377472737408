/**
 * Gatsby client-side configuration
 */

import React from 'react';
import { Layout } from './src/components';

// Adds font styles in <head>
import './src/styles/fonts.css';

// Permanent layout structure that doesn't change with pages (see gatsby-ssr.js)...
export const wrapPageElement = ({ element, props }) => (
    <Layout { ...props }>{ element }</Layout>
);