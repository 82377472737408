/**
 * Exports PortfolioMenu
 */

import React, { useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTheme } from 'styled-components';
import { Flex, Box, useResponsiveProp } from '@arturpol/systheme';
import { getLink } from '../../../utils';
import { SecondaryButton } from '../../Button';
import { useTopMenuActions } from '../Context';
import PortfolioMenuList from './List';

/**
 * React hook
 * @returns portfolio menu back button link
 */
const usePortfolioMenuButton = () => {

    const data = useStaticQuery(graphql`
        query getPortfolioMenuButton {
            menu(_id: {eq: "portfolio"}) {
                button {
                    _menu
                    anchor
                    title
                }
            }
        }
    `);
    
    return getLink(data.menu.button);

};

/**
 * PortfolioMenu React component
 * It's a portfolio sub-menu in the top menu.
 * @param {object} props 
 */
const PortfolioMenu = props => {

    const { topMenu } = useTheme();
    const { link, context } = usePortfolioMenuButton();
    const menu = context._menu;
    
    const { open } = useTopMenuActions();
    const callback = useCallback(() => open(menu), [ open, menu ]);
    
    const buttonProps = {
        width: 'stretch',
        icon: 'arrow-left',
        ...link,
    };

    if(menu){
        buttonProps.onClick = callback;
    }

    const large = useResponsiveProp({
        small: false,
        mediumOnly: true,
    });

    props = {
        el: 'nav',
        flexDirection: 'column',
        alignItems: 'stretch',
        ...props,
    };

    return (
        <Flex { ...props }>
            <PortfolioMenuList span={{ small: 'stretch', large: 'shrink', }} large={ large } />
            <Box pl={ topMenu.horizontalPadding } pr={ topMenu.horizontalPadding } pt={ topMenu.verticalPadding }>
                <SecondaryButton { ...buttonProps } />
            </Box>
        </Flex>
    );

};

export default PortfolioMenu;