/**
 * Exports SecondaryButton
 */

import React from 'react';
import PrimaryButton from './Primary';

/**
 * SecondaryButton React component.
 * From PrimaryButton it differs only in a style (outline).
 * @param {object} props 
 */
const SecondaryButton = props => {
    
    props = {
        ...props,
        kind: 'outline',
    };

    return <PrimaryButton { ...props } />;

};

export default SecondaryButton;