/**
 * Exports getHowToSchema
 */

import getImageObjectSchema from './imageObject';
import getMonetaryAmountSchema from './monetaryAmount';

const getHowToStepSchema = ({ name, text, image, }, baseUrl) => {

    return {
        '@type': 'HowToStep',
        name,
        text,
        image: getImageObjectSchema(image, baseUrl),
    };

};

const getHowToSchema = ({ name, image, price, duration, step }, baseUrl) => {
    
    return {
        '@type': 'HowTo',
        name,
        image: getImageObjectSchema(image, baseUrl),
        estimatedCost: getMonetaryAmountSchema(price, baseUrl),
        step: step.map(item => getHowToStepSchema(item, baseUrl)),
        totalTime: duration,
    };

};

export default getHowToSchema;