/**
 * Exports Page
 */

import React from 'react';
import { useTheme } from 'styled-components';
import { Box, durationProp, easingProp } from '@arturpol/systheme';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { TopMenuProvider, TopMenu } from '../TopMenu';

// Page transition vertical movement distance
const ANIMATION_DELTA_Y = 2;

/**
 * Gatsby's page wrapper
 * @param {object} props
 */
const PageLayout = ({ children, location, ...rest}) => {

    const theme = useTheme();
    const key = location && location.hasOwnProperty('pathname') ? location.pathname : '404';
    
    const deltaY = theme.rem(ANIMATION_DELTA_Y);
    const duration = durationProp(theme.duration, 'long');
    const ease = easingProp(theme.easing, 'ease-in-out/medium');

    const props = {
        el: 'main',
        motion: true,
        key,
        initial: 'initial',
        animate: 'enter',
        exit: 'exit',
        variants: {
            initial: {
                opacity: 0,
                y: deltaY,
            },
            enter: {
                opacity: 1,
                y: 0,
                transition: {
                    duration,
                    when: 'beforeChildren',
                    ease,
                },
            },
            exit: {
                opacity: 0,
                y: -1 * deltaY,
                transition: {
                    duration,
                    ease,
                },
            },
        },
        children,
    };
    
    return (
        <TopMenuProvider>
            <TopMenu />
            <Box { ...props } />
            <Footer />
            <Header />
        </TopMenuProvider>
    );

};

export default PageLayout;