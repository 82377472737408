/**
 * Exports MainMenu
 */

import React, { useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTheme } from 'styled-components';
import { Flex, Box } from '@arturpol/systheme';
import { getLink } from '../../../utils';
import { PrimaryButton } from '../../Button';
import { useTopMenuActions } from '../Context';
import MainMenuList from './List';
import SocialMediaList from './SocialMediaList';

/**
 * React hook
 * @returns main menu button link data
 */
const useMainMenuButton = () => {

    const data = useStaticQuery(graphql`
        query getMainMenuButton {
            menu(_id: {eq: "main"}) {
                button {
                    _page
                    anchor
                    title
                }
            }
        }
    `);
    
    return getLink(data.menu.button);

};

/**
 * MainMenu React component
 * Renders main menu (default page in the TopMenu).
 * @param {object} props 
 */
const MainMenu = props => {

    const { topMenu } = useTheme();
    const button = useMainMenuButton();
    
    const { close } = useTopMenuActions();
    const callback = useCallback(() => close(), [ close ]);
    
    const buttonProps = {
        ...button.link,
        onClick: callback,
        width: 'stretch',
    };

    props = {
        motion: true,
        el: 'nav',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        ...props,
    };

    return (
        <Flex { ...props }>
            <MainMenuList span={{ small: 'stretch', large: 'shrink', }} />
            <Box pl={ topMenu.horizontalPadding } pr={ topMenu.horizontalPadding } pt={ topMenu.verticalPadding } pb={ topMenu.verticalPadding }>
                <PrimaryButton { ...buttonProps } />
            </Box>
            <SocialMediaList />
        </Flex>
    );

};

export default MainMenu;