/**
 * Exports CalloutService
 */

import React from 'react';
import pt from 'prop-types';
import { useTheme } from 'styled-components';
import { Flex, Text, Heading } from '@arturpol/systheme';
import { useMinimumPriceHeader, usePrice, hasProperty } from '../../utils';

const useBodyTextColor = () => {
    
    const { typography } = useTheme();
    return typography.bodyPrimaryColor;

};

const ServiceTitle = props => {

    const { typography } = useTheme();
    const color = typography.headingSecondaryColor;

    props = {
        el: 'h4',
        kind: 'button/medium',
        color,
        whiteSpace: { small: 'nowrap', medium: 'normal', },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        mb: { medium: 3, },
        ...props,
    };

    return <Text { ...props } />;

};

const ServiceCaption = props => {

    const color = useBodyTextColor();

    props = {
        kind: 'button/small', 
        color,
        order: { smallOnly: 3, },
        mt: { smallOnly: -2, },
        mb: { medium: 1, },
        ...props,
    };

    return <Text { ...props } />;

};

const ServicePrice = props => {

    const color = useBodyTextColor();

    props = {
        el: 'div',
        kind: 'h4',
        color,
        ml: { small: 2, medium: 0, },
        whiteSpace: 'nowrap',
        ...props,
    };

    return <Heading { ...props } />;

};

/**
 * CalloutService React component
 * Name of service provided with lowest price displayed in the BlockCallout
 * @param {object} params
 */
const CalloutService = ({ title, price, ...rest }) => {

    const { container, size } = useTheme();

    const caption = useMinimumPriceHeader();
    const amount = usePrice(price);

    const offset = size(hasProperty(container.horizontalPadding, 'small') ? container.horizontalPadding.small : 0).get();
    const marginOffset = -1 * offset;

    const props = {
        bgColor: 'rgba(18,18,18,0.75)',
        ml: { smallOnly: marginOffset, },
        mr: { smallOnly: marginOffset, },
        mb: { smallOnly: 1, },
        pl: { small: offset, },
        pr: { small: offset, },
        pt: { small: 3, medium: 7.5, },
        pb: { small: 3, medium: 7.5, },
        flexDirection: { small: 'row', medium: 'column', },
        flexWrap: { small: 'wrap', medium: 'nowrap', },
        justifyContent: { small: 'space-between', medium: 'flex-start', },
        minWidth: { small: 0, medium: 24, },
        ...rest,
    };

    const span = { small: 12, medium: 'shrink', };

    return (
        <Flex { ...props }>
            <ServiceTitle span="shrink">{ title }</ServiceTitle>
            <ServiceCaption span={ span }>{ caption }</ServiceCaption>
            <ServicePrice span="shrink">{ amount }</ServicePrice>
        </Flex>
    );

};

CalloutService.propTypes = {

    /**
     * Service name
     */
    title: pt.string.isRequired,

    /**
     * Price for the service
     */
    price: pt.shape({
        amount: pt.number.isRequired,
    }),

};

export default CalloutService;