import React from 'react';

function Youtube20(props) {
	const fill = props.fill || 'currentColor';
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "youtube 20";

	return (
		<svg height={height} width={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="none">
		<path d="M19.833 6S19.667 4.583 19 4c-.75-.833-1.583-.833-2-.833C14.167 3 10 3 10 3s-4.167 0-7 .167C2.583 3.25 1.75 3.25 1 4c-.583.583-.833 2-.833 2S0 7.583 0 9.25v1.5C0 12.333.167 14 .167 14s.166 1.417.833 2c.75.833 1.75.75 2.167.833C4.75 17 10 17 10 17s4.167 0 7-.25c.417-.083 1.25-.083 2-.833.583-.584.833-2 .833-2s.167-1.584.167-3.25v-1.5C20 7.583 19.833 6 19.833 6zM7.917 12.583V7l5.416 2.833-5.416 2.75z" fill={fill}/>
	</g>
</svg>
	);
};

export default Youtube20;