/**
 * Exports icon-related design tokens
 */

import { token } from '../utils';

/**
 * Exports all glyphs
 */
export { default as glyph } from './glyph';

/**
 * Can be any color token e.g. 'primary/50' or 'currentColor' to inherit color.
 */ 
export const defaultFill = 'currentColor';

/**
 * Scales glyph within the icon itself (use percentage values e.g. '80%')
 */
export const scale = '75%';

/**
 * Predefined icon sizes specified as: 'value' in base units, 'order' in pixels
 */
export const size = token('iconSize', [{
    key: 'xxsmall',
    value: 1.5,
    order: 12,
},{
    key: 'xsmall',
    value: 2,
    order: 16,
},{
    key: 'small',
    value: 2.5,
    order: 20,
},{
    key: 'medium',
    value: 3,
    order: 24,
},{
    key: 'large',
    value: 4,
    order: 32,
},{
    key: 'xlarge',
    value: 6,
    order: 48,
},{
    key: 'xxlarge',
    value: 8,
    order: 64,
}]);

/**
 * Icon used to indicate loading state in other components, will be animated (rotated around z-axis)
 */
export const loadingGlyph = 'refresh';
export const loadingAnimationDuration = 'xxxlong';