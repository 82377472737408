/**
 * Exports NetlifyForm
 */

import React, { useCallback } from 'react';
import pt from 'prop-types';
import { Form } from '@arturpol/systheme';
import { getRequestBody } from '../../utils';

/**
 * Netlify form configuration
 */
export const NETLIFY_FORM_HONEYPOT = 'bot-field';
export const NETLIFY_FORM_PARAM_NAME = 'form-name';

/**
 * NetlifyForm React component
 * Form with extra fields for Netlify Form submission
 * @param {object} props 
 */
const NetlifyForm = ({ onSubmit, name, children, params, ...rest }) => {

    const submitCallback = useCallback((context, event) => {

        if(typeof onSubmit !== 'function') return false;
        const extraParams = params ? params : {};

        onSubmit({
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: getRequestBody(context.fields, { ...extraParams, [ NETLIFY_FORM_PARAM_NAME ]: name }),
        }, context, event);

    }, [ onSubmit, name, params ]);
    
    const props = {
        name,
        'data-netlify': 'true',
        'data-netlify-honeypot': NETLIFY_FORM_HONEYPOT,
        onSubmit: submitCallback,
        ...rest,
    };
    
    return (
        <Form { ...props }>
            <input type="hidden" name={ NETLIFY_FORM_PARAM_NAME } value={ name } />
            <input type="hidden" name={ NETLIFY_FORM_HONEYPOT } />
            { children }
        </Form>
    );

};

NetlifyForm.propTypes = {

    /**
     * Form name to identify in Netlify
     */
    name: pt.string.isRequired,

    /**
     * Form submit event hander
     */
    onSubmit: pt.func,

    /**
     * Form content
     */
    children: pt.node.isRequired,

    /**
     * Object with extra parameters added to the request body
     */
    params: pt.object,
    
};

export default NetlifyForm;