/**
 * Misc helper functions
 */

/**
 * Check if object has particular property of a specified type
 * @param {object} obj - object to inspect
 * @param {string} name - 'obj' property name
 * @param {string} type - optional name of the type the property in question should be of
 * @returns true if property exists and is of specified type
 */
const hasProperty = (obj, name, type) => {
    
    const hasProp = obj.hasOwnProperty(name);
    return typeof type === 'string' ? (hasProp && typeof obj[name] === type) : hasProp;

};

/**
 * Filters array of objects.
 * @param {Array} arr - input array of objects
 * @param {object} query - object containing properties to compare with objects in the arr e.g. { type: 'shoes', color: 'white' }
 * @param {number} limit - maximum number of objects to return
 * @returns array of objects matching the query
 */
const filter = (arr, query, limit = false) => {

    const result = [];

    for(let i = 0 ; i < arr.length ; i++){

        const item = arr[i];
        if(typeof item !== 'object') continue;
        
        if(typeof query === 'object'){

            let matches = true;

            for(let key in query){

                if(!query.hasOwnProperty(key)) continue;
                const value = query[key];
                
                if(!item.hasOwnProperty(key) || item[key] !== value){
                    matches = false;
                    break;
                }

            }

            if(!matches) continue;

        }

        result.push(item);
        if(typeof limit === 'number' && result.length >= limit) break;

    }

    return result;

};

/**
 * Returns the first object that matches query from the specified array.
 * @param {Array} arr 
 * @param {object} query 
 * @returns object or false
 */
const find = (arr, query) => {
    
    const result = filter(arr, query, 1);
    return result.length > 0 ? result[0] : false;

};

/**
 * Returns random subset of array in a random order determined by ```seed``` param
 * @param {Array} set - array of items to shuffle
 * @param {string} seed - used to determine the order of items
 * @param {number} limit - optional, number of items to return
 */
const shuffle = (set, seed, limit) => {

    const shuffledSet = [ ...set ];
    let currentIndex = shuffledSet.length;
    let temporaryValue, randomIndex;

    // Convert the seed to an array of character codes
    const seedCodes = [...seed].map(char => char.charCodeAt(0));

    // Loop through the array and shuffle its elements
    while (currentIndex > 0) {
        // Generate a random index using the seed
        randomIndex = seedCodes.reduce((acc, code) => acc + code, 0) % currentIndex;

        // Decrease the current index by 1
        currentIndex--;

        // Swap the current element with the random element
        temporaryValue = shuffledSet[currentIndex];
        shuffledSet[currentIndex] = shuffledSet[randomIndex];
        shuffledSet[randomIndex] = temporaryValue;
    }

    // Return the shuffled subset if limit is specified
    if (limit !== undefined && limit <= shuffledSet.length) {
        return shuffledSet.slice(0, limit);
    }

    return shuffledSet;

};

export {
    hasProperty,
    filter,
    find,
    shuffle
};