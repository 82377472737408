/**
 * Exports getFaqPageSchema
 */

const getAnswerSchema = (answer, baseUrl) => {

    return {
        '@type': 'Answer',
        text: answer,
    };

};

const getQuestionSchema = ({ question, answer }, baseUrl) => {

    return {
        '@type': 'Question',
        name: question,
        acceptedAnswer: getAnswerSchema(answer, baseUrl),
    };

};

const getFaqPageSchema = (items, baseUrl) => {

    return {
        '@type': 'FAQPage',
        mainEntity: items.map(question => getQuestionSchema(question, baseUrl)),
    };

};

export default getFaqPageSchema;