/**
 * Exports useVideos
 */

import { useStaticQuery, graphql } from 'gatsby';

/**
 * React hook
 * @param id - video id to return (optional)
 * @returns all videos as object with id as keys or single video if id is specified, see from /content/data/site.yml
 */
const useVideos = id => {

    const { data } = useStaticQuery(graphql`
        query getVideos {
            data(_id: {eq: "site"}) {
                videos {
                    _id
                    youTubeId
                }
            }
        }
    `);

    const videos = data.videos.reduce((prev, { _id, ...rest }) => {
        return { 
            ...prev, 
            [_id]: { id: _id, ...rest, },
        };
    }, {});

    return typeof id === 'string' ? videos[id] : videos;

};

export default useVideos;