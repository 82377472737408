/**
 * Exports usePrice
 */

import { useStaticQuery, graphql } from 'gatsby';

/**
 * React hook
 * @returns price format data based on settings in /content/data/site.yml
 */
const usePriceData = () => {

    const { data } = useStaticQuery(graphql`
        query getPriceFormat {
            data(_id: {eq: "site"}) {
                locale
                currency {
                    abbr
                    name
                }
            }
        }
    `);

    const { currency, locale } = data;

    return {
        ...currency,
        locale,
    };

};

/**
 * DEPRECTIATED -- Output differs on server and in the browser and causes React hydration issues
 * React hook
 * @returns JS price format function
 */
/*const usePriceFormat = () => {

    const { abbr, locale } = usePriceData();

    const price = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: abbr,
        maximumFractionDigits: 0, // Rounds to whole numbers
        useGrouping: true,
    });
    
    return price.format;

};*/

/**
 * Separates group of number in price
 * @param num - number to separate
 * @param separator - group separator (single character)
 * @param groupLength - number of digits in a group
 */
const separateNumber = (num, separator = ',', groupLength = 3) => {

    let str = Math.floor(num).toString(10);
    let result = '';
    
    while(str.length > 0){
        
        const part = str.substring(str.length - groupLength);
        let sep = (result.length > 0 && part !== '-') ? separator : '';
        result = `${ part }${ sep }${ result }`;
        str = str.substring(0, str.length - part.length);

    }

    return result;

};

/**
 * Returns price formatting function for Polish reader
 * @param {string} code - currency code e.g. 'PLN'
 * @returns function that formats price like this: '32 323 444 zł'
 */
const getPolishPriceFormat = code => {

    let symbol = code;

    switch(code){
        case 'PLN':
            symbol = 'zł';
            break;
        default:
            symbol = code;
            break;
    }

    return amount => {

        amount = separateNumber(amount, ' ', 3); // Using &nbsp; here!
        return `${amount} ${symbol}`; // Using &nbsp; here!

    };

};

/**
 * Returns price formatting function that uses default settings (English, international)
 * @param {object} code - currency code e.g. 'USD'
 * @returns function that formats price to e.g. '10,234,200 USD'
 */
const getDefaultPriceFormat = code => {

    return amount => {

        amount = separateNumber(amount);
        return `${amount} ${code}`; // Using &nbsp; here!

    };

};

/**
 * React hook
 * @returns JS price format function
 */
const usePriceFormat = () => {

    const { abbr, locale } = usePriceData();

    switch(locale){
        case 'pl-PL':
            return getPolishPriceFormat(abbr);
        default:
            return getDefaultPriceFormat(abbr);
    };

};

/**
 * Formats price based on specified object and format function from ```usePriceFormat```
 * @param {object} price - object with required numeric 'amount' key and optional 'unit'
 * @param {function} format - function from ```usePriceFormat```
 * @returns formatted price as string
 */
const formatPrice = ({ amount, unit }, format) => {
    
    const value = format(amount);
    if(!unit) return value;

    return `${value}/${unit}`;

};

/**
 * React hook
 * @param {object} price - price object containing numeric value in the 'amount' property
 * @returns price formatted according to the settings in the /content/data/site.yml
 */
const usePrice = (price) => {

    const format = usePriceFormat();
    return formatPrice(price, format);

};

export {
    usePriceData,
    usePrice,
    usePriceFormat,
    formatPrice,
};