/**
 * Exports MainMenuItem
 */

import React, { useCallback } from 'react';
import pt from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { Box, Flex, Text, Icon, tokenProp, useResponsiveProp } from '@arturpol/systheme';
import { PORTFOLIO_NODE_TYPE, ARTICLE_NODE_TYPE } from '../../../config';
import { usePath } from '../../Paths';
import { Link } from '../../Link';
import { PORTFOLIO_MENU_ID, useTopMenuActions } from '../Context';

// Needed to set active item when article is opened
const BLOG_PAGE_ID = 'blog';

const ListItem = styled(Box)`
    list-style: none;
`;

const StyledLink = styled(Link)`
    cursor: pointer;

    &:hover > div{
        color: ${ props => props.$hoverColor };
    }

    &:hover span{
        color: ${ props => props.$hoverColor };
    }

    &::before{
        position: absolute;
        display: block;
        content: " ";
        top: calc(50% - ${ props => props.theme.rem(props.$height * 0.33) });
        left: 0;
        height: ${ props => props.theme.rem(props.$height * 0.66) };
        width: 4px;
        border-radius: 0 4px 4px 0;
        background-color: ${ props => tokenProp(props.theme.color, 'primary/50') };
        opacity: ${ props => props.$active ? '1' : '0' };
    }
`;

const MenuItem = ({ children, active, hasChildren, ...rest}) => {

    const { topMenu, typography, color } = useTheme();

    const transition = 'color short linear';
    const height = useResponsiveProp(topMenu.itemHeight);

    const props = {
        ...rest,
        display: 'block',
        position: 'relative',
        $active: active,
        $hoverColor: tokenProp(color, typography.headingDefaultColor),
        $height: height,
    };

    const textColor = active ? typography.headingDefaultColor : typography.bodyPrimaryColor;

    const hp = useResponsiveProp(topMenu.horizontalPadding);

    return (
        <StyledLink { ...props }>
            <Flex height={ height } alignItems="center" color={ textColor } pl={ hp } pr={ hp } transition={ transition }>
                <Text el="span" display="block" span="stretch" kind={ topMenu.itemTextKind } color={ textColor } transition={ transition } mb="0" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">{ children }</Text>
                { hasChildren ? <Icon size="medium" glyph="triangle-right" /> : null }
            </Flex>
        </StyledLink>
    );

};

/**
 * MainMenuItem React component
 * @param {object} params
 */
const MainMenuItem = ({ link, context, current, ...rest }) => {

    // Menu actions
    const { open, close } = useTopMenuActions();

    // Path to determine if it's a current page
    const path = usePath(context.pageId);

    // Does it open another menu (which one - id)?
    const menu = context.hasOwnProperty('_menu') && context._menu;

    // If it doesn't open any menu, close current one
    const callback = useCallback(() => {
        
        if(menu) open(menu);
        else close();

    }, [ open, close, menu ]);
    
    const linkProps = {
        ...link,
        onClick: callback,
        hasChildren: !!menu,
    };

    const liProps = {
        el: 'li',
        ...rest,
    };

    let isActive = false;
    
    if(current){
        if(menu) isActive = menu === PORTFOLIO_MENU_ID && current.collection === PORTFOLIO_NODE_TYPE;
        else isActive = current.path === path || (context.pageId === BLOG_PAGE_ID && current.collection === ARTICLE_NODE_TYPE);
    }

    linkProps.active = isActive;

    return (
        <ListItem { ...liProps }>
            <MenuItem { ...linkProps } />
        </ListItem>
    );

};

MainMenuItem.propTypes = {

    /**
     * Props applied to Link component
     */
    link: pt.shape({
        children: pt.string.isRequired,
        to: pt.string,
        title: pt.string,
    }).isRequired,

    /**
     * Info about current page
     */
    current: pt.shape({
        path: pt.string,
        collection: pt.string,
    }).isRequired,

    /**
     * Menu item context returned from getLink()
     */
    context: pt.object,

};

MainMenuItem.defaultProps = {
    context: {},
};

export default MainMenuItem;