import React from 'react';

function Spa48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "spa 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M12,43.887a11.6,11.6,0,0,1-8.6-5.872C-.3,31.666,4.3,15,4.3,15s1.966.515,4.7,1.456" fill="none"/>
		<path d="M39,16.456C41.734,15.515,43.7,15,43.7,15s4.6,16.666.9,23.015A11.6,11.6,0,0,1,36,43.887" fill="none"/>
		<path d="M10,29.3C10,20.9,24,4,24,4S38,20.9,38,29.3C38,38,30.8,43,24,43S10,38,10,29.3Z" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Spa48;