/**
 * Exports CarouselWrapper
 */

import React, { useCallback } from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { Box, Flex } from '@arturpol/systheme';
import { useTouch } from '../../utils';
import { useCarouselActions, useCarouselState } from '../Carousel';
import CarouselButton from './Button';
import CarouselSlide from './Slide';

const BUTTON_HEIGHT = 8;
const DRAG_MIN_PX = 15;

const StyledNavigation = styled(Flex)`
    pointer-events: none;
`;

const Navigation = props => {

    const isTouchEnabled = useTouch();
    const opacity = isTouchEnabled ? 1 : 0;

    props = {
        el: 'nav',
        position: 'absolute',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        height: BUTTON_HEIGHT,
        top: `calc(50% - ${ BUTTON_HEIGHT / 2 })`,
        left: 0,
        width: '100%',
        opacity,
        transition: 'opacity short linear',
        ...props,
    };

    return <StyledNavigation { ...props } />;

};

const StyledSlidesWrapper = styled(Flex)`
    transform: translateX(${ props => props.$x });
`;

const SlidesWrapper = ({ current, max, ...rest }) => {

    max = max === 0 ? 1 : max;

    const props = {
        el: 'ul',
        alignItems: 'center',
        bgColor: 'gray/80',
        transition: 'transform long ease-out/medium',
        $x: `${ -100 * current }%`,
        ...rest,
    };

    return <StyledSlidesWrapper { ...props } />;

};

const Wrapper = styled(Box)`
    isolation: isolate;
    
    &:hover nav{
        opacity: 1;
    }
`;

/**
 * CarouselWrapper React component
 * Wraps slides in the image carousel
 * @param {object} params
 */
const CarouselWrapper = ({ page, previousTitle, nextTitle, ...rest }) => {

    const { next, previous } = useCarouselActions(); 
    const { items, current } = useCarouselState();

    const props = {
        el: 'figure',
        overflow: 'hidden',
        position: 'relative',
        ...rest,
    };

    const onDragEnd = useCallback((event, info) => {
        
        const x = info.offset.x;
        if(Math.abs(x) < DRAG_MIN_PX) return;
        
        if(x < 0) next();
        else previous();

    }, [ next, previous ]);

    return (
        <Wrapper { ...props }>
            <Box motion drag="x" dragConstraints={{ left: 0, right: 0, }} dragSnapToOrigin dragElastic={ 0.1 } onDragEnd={ onDragEnd }>
                <SlidesWrapper current={ current } max={ items.length }>
                    { items.map((item, index) => (
                        <CarouselSlide key={ index } item={ index } page={ page } { ...item } />
                    ))}
                </SlidesWrapper>
            </Box>
            <Navigation>
                <CarouselButton direction="previous" callback={ previous } title={ previousTitle } />
                <CarouselButton direction="next" callback={ next } title={ nextTitle } />
            </Navigation>
        </Wrapper>
    );

};

CarouselWrapper.propTypes = {

    /**
     * Page id slides will link to
     */
    page: pt.string,

    /**
     * Previous slide button title
     */
    previousTitle: pt.string,

    /**
     * Next slide button title
     */
    nextTitle: pt.string,

};

export default CarouselWrapper;