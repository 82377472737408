/**
 * Exports TagNavigationLabel
 */

import React from 'react';
import pt from 'prop-types';
import { useTheme } from 'styled-components';
import { Text } from '@arturpol/systheme';

/**
 * TagNavigationLabel React component
 */
const TagNavigationLabel = React.forwardRef((props, ref) => {

    const theme = useTheme();
    const config = theme.tagCarousel;

    props = {
        el: 'small',
        kind: config.labelKind,
        minWidth: config.labelMinimumWidth,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        marginBottom: 0,
        ...props,
        ref,
    };

    return <Text { ...props } />;

});

TagNavigationLabel.propTypes = {
    
    /**
     * Text
     */
    children: pt.node.isRequired,
    
};

export default TagNavigationLabel;