/**
 * Exports icon button-related design tokens
 */

import { token } from '../utils';

export const defaultSize = 'medium';
export const transitionDuration = 'short';

/**
 * Icon button appearence variant as a design token. 
 * Can define 'icon' property that stands for its color.
 * This can be set for all possible states e.g. active or hover AND disabled.
 * Only single icon color is allowed e.g. 'primary/50', but not 'primary/50 gray/20'
 */
export const variant = token('iconButtonVariant', [{
    key: 'primary',
    value: {
        icon: 'primary/40',

        hover: {
            icon: 'primary/50',
        },

        active: {
            icon: 'primary/80',
        },

        disabled: {
            icon: 'gray/60',
        },
    },
},{
    key: 'gray',
    value: {
        icon: 'gray/10',

        hover: {
            icon: 'gray/5',
        },

        active: {
            icon: 'white',
        },

        disabled: {
            icon: 'gray/60',
        },
    },
},{
    key: 'white',
    value: {
        icon: 'white',

        hover: {
            icon: 'primary/50',
        },

        active: {
            icon: 'primary/80',
        },

        disabled: {
            icon: 'gray/60',
        },
    },
}]);

export const defaultVariant = 'primary';