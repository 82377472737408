import React from 'react';

function Calendar24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "calendar 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<line fill="none" x1="1" x2="23" y1="9" y2="9"/>
		<line fill="none" stroke={fill} x1="6" x2="5.999" y1="1" y2="5"/>
		<line fill="none" stroke={fill} x1="18" x2="17.999" y1="1" y2="5"/>
		<rect height="20" width="22" fill="none" rx="2" stroke={fill} x="1" y="3"/>
	</g>
</svg>
	);
};

export default Calendar24;