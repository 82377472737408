/**
 * Exports breakpoint-related design tokens
 */

import { token } from '../utils';
import { size } from '../config';

export const widthBreakpoint = token('widthBreakpoint', [{
        key: 'small',
        value: size('0px'),
        order: 0,
    },{
        key: 'medium',
        value: size('640px'),
        order: 640,
    },{
        key: 'large',
        value: size('1024px'),
        order: 1024,
    },{
        key: 'xlarge',
        value: size('1280px'),
        order: 1280,
    },{
        key: 'xxlarge',
        value: size('1440px'),
        order: 1440,
    }]
);

export const dpiBreakpoint = token('dpiBreakpoint', [{
        key: 'dpi-10',
        value: 1,
        order: 1,
    },{
        key: 'dpi-15',
        value: 1.5,
        order: 1.5,
    },{
        key: 'dpi-20',
        value: 2,
        order: 2,
    },{
        key: 'dpi-30',
        value: 3,
        order: 3,
    },{
        key: 'dpi-40',
        value: 4,
        order: 4,
    }]
);

export const defaultDpiBreakpoint = dpiBreakpoint.get('dpi-10');
export const printBreakpoint = widthBreakpoint.get('large');

/*
 * Default server side rendering breakpoint (will validate match on client side and re-render on fail)
 * Can improve with this with ua-parser-js to detect device on server or react-urgent
 */
export const serverSideRenderingBreakpoint = widthBreakpoint.get('small');