/**
 * Exports useCurrentPageInfo
 */

import { useLocation } from '@reach/router';
import usePageInfo from './usePageInfo';

/**
 * React hook
 * @returns current page basic info: id, path, collection
 */
const useCurrentPageInfo = () => {

    const { pathname } = useLocation();
    return usePageInfo({ path: pathname }, true);

};

export default useCurrentPageInfo;