import { token } from '../../utils';
import { size } from '../size';

export default token('gap', [{
    key: 'xxsmall',
    value: size(0.5),
    order: 0.5,
},{
    key: 'xsmall',
    value: size(1),
    order: 1,
},{
    key: 'small',
    value: size(2),
    order: 2,
},{
    key: 'medium',
    value: size(3),
    order: 3,
},{
    key: 'large',
    value: size(4),
    order: 4,
},{
    key: 'xlarge',
    value: size(5),
    order: 5,
},{
    key: 'xxlarge',
    value: size(8),
    order: 8,
}]);