import React from 'react';

function ArrowRight16(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "arrow right 16";

	return (
		<svg height={height} width={width} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill}>
		<path d="M10.707,2.293A1,1,0,0,0,9.293,3.707L12.586,7H1A1,1,0,0,0,1,9H12.586L9.293,12.293a1,1,0,1,0,1.414,1.414l5-5a1,1,0,0,0,0-1.414Z" fill={fill}/>
	</g>
</svg>
	);
};

export default ArrowRight16;