import { token } from '../../utils';

export default token('opacity', [{
    key: 'transparent',
    order: 0,
    value: 0,
},{
    key: 'opacity-05',
    order: 0.05,
    value: 0.05,
},{
    key: 'opacity-10',
    order: 0.1,
    value: 0.1,
},{
    key: 'opacity-20',
    order: 0.2,
    value: 0.2,
},{
    key: 'opacity-30',
    order: 0.3,
    value: 0.3,
},{
    key: 'opacity-40',
    order: 0.4,
    value: 0.4,
},{
    key: 'opacity-50',
    order: 0.5,
    value: 0.5,
},{
    key: 'opacity-60',
    order: 0.6,
    value: 0.6,
},{
    key: 'opacity-70',
    order: 0.7,
    value: 0.7,
},{
    key: 'opacity-80',
    order: 0.8,
    value: 0.8,
},{
    key: 'opacity-90',
    order: 0.9,
    value: 0.9,
},{
    key: 'opaque',
    order: 1,
    value: 1,
}]);