/**
 * Exports getOrganizationSchema
 */

const getImage = (image, baseUrl) => `${ baseUrl }${ image.childImageSharp.original.src }`;

const getOrganizationSchema = ({ logo }, baseUrl) => {

    return {
        '@type': 'Organization',
        logo: getImage(logo, baseUrl),
        url: baseUrl,
    };

};

export default getOrganizationSchema;