import React from 'react';

function Refresh24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "refresh 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<polyline fill="none" points="7.929 18.659 2.237 17.073 2.966 22.87"/>
		<polyline fill="none" points="16.071 5.341 21.763 6.927 21.034 1.13" stroke={fill}/>
		<path d="M23,12A11,11,0,0,1,2.237,17.073" fill="none"/>
		<path d="M1,12A11,11,0,0,1,21.763,6.927" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Refresh24;