/**
 * Exports BannerBackground
 */

import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { Box } from '@arturpol/systheme';

/**
 * Adds top gradient and fixes iOS overflow hidden 
 */
const BackgroundWrapper = styled(Box)`
    isolation: isolate;
    
    &::after{
        content: " ";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 192px;
        background-image: linear-gradient(180deg, rgba(18,18,18,1) 0%, rgba(18,18,18,0) 100%);
    }
`;

/**
 * BannerBackground React component
 * Wrapper for the image on page banner (need to set posiiton: relative) on the parent!
 * Set height: 100% if you want to limit the image height to the height of the parent.
 * @param {object} params
 */
const BannerBackground = ({ children, ...rest }) => {

    const props = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        overflow: 'hidden',
        borderBottomLeftRadius: { small: 'xlarge', xlarge: 'xxlarge' },
        borderBottomRightRadius: { small: 'xlarge', xlarge: 'xxlarge' },
        ...rest,
    };

    return <BackgroundWrapper { ...props }>{ children }</BackgroundWrapper>;

};

BannerBackground.propTypes = {

    /**
     * Image component such as <GatsbyImage /> or <StaticImage />
     */
    children: pt.node.isRequired,

};

export default BannerBackground;