import { token } from '../../utils';

export default token('borderWidth', [{
    key: '1px',
    value: '1px',
    order: 1,
},{
    key: '2px',
    value: '2px',
    order: 2,
}, {
    key: '4px',
    value: '4px',
    order: 4,
}]);