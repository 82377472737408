/**
 * Exports TestimonialQuote
 */

import React from 'react';
import pt from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { Text, Box, Heading, Icon, Paragraph } from '@arturpol/systheme';

const Caption = styled(Paragraph)`
    &::before{
        content: "— ";
    }
`;

/**
 * TestimonialQuote React component
 * @param {object} params 
 */
const TestimonialQuote = ({ author, quote, title, ...rest }) => {

    const { typography } = useTheme();
    const headingColor = typography.bodyPrimaryColor;

    return (
        <Box el="figure" { ...rest }>
            <Icon glyph="quote" size="xlarge" fill="white" opacity="0.1" mb="2" />
            <Heading kind="h5" color={ headingColor }>{ title }</Heading>
            <Box el="blockquote">
                <Text el="p" kind="quote">{ quote }</Text>
            </Box>
            <Caption el="figcaption" kind="label">{ author }</Caption>
        </Box>
    );
    
};

TestimonialQuote.propTypes = {

    /**
     * Quote author
     */
    author: pt.string.isRequired,

    /**
     * Quote text
     */
    quote: pt.string.isRequired,

    /**
     * Quote block title
     */
    title: pt.string.isRequired,

};

export default TestimonialQuote;