import React from 'react';

function BabyClothes48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "baby clothes 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M42,18.333,44,19,46,9,40.432,5.52A10,10,0,0,0,35.132,4H32A8,8,0,0,1,16,4H12.868a10,10,0,0,0-5.3,1.52L2,9,4,19l2-.666" fill="none" stroke={fill}/>
		<path d="M10,15V36a8.024,8.024,0,0,1,8,8H30a8.024,8.024,0,0,1,8-8V15" fill="none" stroke={fill}/>
		<path d="M29.849,25.711a3.932,3.932,0,1,0-5.562-5.558,4.16,4.16,0,0,0-.287.321,3.931,3.931,0,0,0-6.138,4.913,3.825,3.825,0,0,0,.288.322L24,31.561Z" fill="none"/>
	</g>
</svg>
	);
};

export default BabyClothes48;