import React from 'react';

function Pin20(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "pin 20";

	return (
		<svg height={height} width={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth} transform="translate(0.5 0.5)">
		<path d="M16.6 7.47c0 4.07-6.64 10.79-6.64 10.79s-6.64-6.72-6.64-10.79c0-4.23 3.4-6.64 6.64-6.64s6.64 2.41 6.64 6.64z" fill="none" stroke={fill}/>
		<path d="M9.96 4.98a2.49 2.49 0 1 0 0 4.98 2.49 2.49 0 1 0 0-4.98z" fill="none"/>
	</g>
</svg>
	);
};

export default Pin20;