/**
 * Exports Testimonial
 */

import React from 'react';
import pt from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Flex, Container } from '@arturpol/systheme';
import { StyledImage } from '../StyledImage';
import TestimonialQuote from './Quote';

const TestimonialBlock = ({ border, backgroundColor, padding, ...rest }) => {

    backgroundColor = backgroundColor ? backgroundColor : 'rgba(12,12,12,0.33)';
    border = border ? border : '1px solid rgba(255,255,255,0.05)';
    padding = padding ? padding : { small: 3, medium: '4 2', large: '5 1', };

    const props = {
        flexDirection: { small: 'column', medium: 'row', },
        alignItems: { small: 'stretch', medium: 'center', },
        gutterType: { medium: 'margin', }, 
        gutter: { medium: 'large', large: 'xxlarge' },
        rowGap: { smallOnly: 'medium', },
        backgroundColor: { large: backgroundColor },
        borderRadius: 'large',
        padding,
        border: { large: border, },
        ...rest,
    };

    return <Flex { ...props } />;

};

/**
 * Testimonial React component
 * @param {object} params 
 */
const Testimonial = ({ author, quote, title, image, backgroundColor, border, padding, ...rest }) => {

    const props = {
        el: 'article',  
        marginBottom: { small: 5, medium: 8, large: 12, },
        ...rest,
    };

    return (
        <Container { ...props }>
            <Flex justifyContent="center" gutterType={{ large: 'margin' }} gutter={{ large: 'xxlarge' }}> 
                <TestimonialBlock span={{ small: 12, large: 10, }} backgroundColor={ backgroundColor } border={ border } padding={ padding }>
                    <StyledImage span={{ small: 'shrink', medium: 6, }}>
                        <GatsbyImage { ...image } />
                    </StyledImage>
                    <TestimonialQuote title={ title } quote={ quote } author={ author } span={{ small: 'shrink', medium: 6, }} />
                </TestimonialBlock>
            </Flex>
        </Container>
    );
    
};

Testimonial.propTypes = {

    /**
     * Quote author
     */
    author: pt.string.isRequired,

    /**
     * Quote text
     */
    quote: pt.string.isRequired,

    /**
     * Quote block title
     */
    title: pt.string.isRequired,

    /**
     * Author's image
     */
    image: pt.object.isRequired,

};

export default Testimonial;