/**
 * Exports ArticleImage
 */

import React from 'react';
import pt from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { Flex, splitByWhitespace, tokenProp } from '@arturpol/systheme';
import { ImageItem } from '../ImageItem';

const StyledImagesWrapper = styled(Flex)`
    p + & {
        margin-top: 2rem;
    }
`;

/**
 * ImagesWrapper React component
 * Wrapper for ImageItems, e.i. columns with images inside
 * @param {object} params
 */
const ImagesWrapper = ({ gutter, ...rest }) => {

    const props = {
        el: 'ul',
        flexDirection: { small: 'column', medium: 'row' },
        justifyContent: { medium: 'center' },
        alignItems: { small: 'stretch', medium: 'flex-start' },
        gutterType: { medium: 'margin', },
        gutter: { medium: gutter, },
        gap: { smallOnly: gutter, },
        ...rest,
    };

    return <StyledImagesWrapper { ...props } />;

};

/**
 * Returns array of 'span' props for all children nodes containing an image HTML element
 * @param {Array} children - children elements to be rendered
 * @param {string} widths - requested 'span' properties for each image separated by white space 
 * e.g. '3 6 3' for three columns, where the first and the last one are equal in width and the middle one is twice as wide
 * @returns array of objects that can be used in <Flex> child elements
 */
const useNormalizedWidths = (children, widths) => {

    const { flex, articleImage } = useTheme();

    // Number of image elements
    const images = React.Children
        .map(children, child => React.isValidElement(child))
        .reduce(( sum, current) => current ? (sum + 1) : sum, 0);

    if(images <= 0) return [];

    // Allowed string values for individual width (span) e.g. '3' or '10'
    const allowed = articleImage.allowedWidths.map(num => Math.floor(num).toString());

    // Transforming 'widths' to an Array
    switch(typeof widths){
        case 'number':
            widths = [ Math.floor(widths.toString()) ];
            break;
        case 'string':
            widths = splitByWhitespace(widths);
            break;
        default:
            widths = [];
            break;
    }

    // Filtering out invalid width values; returns numbers
    widths = widths
        .filter(width => allowed.indexOf(width) >= 0)
        .map(width => parseInt(width, 10));
    
    // Capacity of used grid row e.g. 12
    const maxSpan = tokenProp(flex.capacity, flex.defaultCapacity);

    // Maximum allowed width value e.i. the widest column possible on desktop
    const maxAllowed = articleImage.allowedWidths.reduce((max, current) => current > max ? current : max, 0);

    // Setting up default width value (if none specified)
    // If there is only one image, make it as wide as possible
    // If there are more images, make them equal in width, trying to fill in entire container
    let defaultSpan = widths.length > 0 ? widths[0] : Math.floor(maxSpan / images);
    if(defaultSpan > maxAllowed) defaultSpan = maxAllowed;

    // Span properties for large viewport e.i. desktop
    const largeSpans = [];
    
    for(let index = 0 ; index < images ; index++){
        
        // Smart copy of supplied widths, filling gaps with default values
        let span = defaultSpan;
        if(widths.length > index) span = widths[index];

        // Special case when there are more images than specified widths - repeat the last specified width
        else if(widths.length > 1) span = largeSpans[index - 1]; 
        largeSpans.push(span);

    }

    const spans = [];

    // Returns array of responsive span properties for each image
    // Warning! Each IMAGE, NOT each CHILD!
    for(let i = 0 ; i < images ; i++){
        
        spans.push({
            small: 'shrink',
            medium: largeSpans[i],
        });

    }

    return spans;

};

/**
 * ArticleImage React component
 * Wrapper for images specified in the .mdx article file content e.g.
 * 
 * <ArticleImage widths="5 3">
 *   ![Alt text](./images/image1.jpg "Optional caption text")
 *   ![Alt text](./images/image2.jpg "Optional caption text")
 * </ArticleImage>
 * 
 * @param {object} params
 */
const ArticleImage = ({ children, widths, ...rest }) => {

    // Get children responsive 'span' props
    const spans = useNormalizedWidths(children, widths);

    // In case there are no valid children elements e.i. images
    if(spans.length <= 0) return null;

    // Counter for child images (instead of counting children)
    let imageIndex = 0;

    const props = {
        gutter: 'small',
        marginBottom: 2,
        ...rest,
    };

    return (
        <ImagesWrapper { ...props }>
            { React.Children.map(children, child => {
                
                // Removes line breaks etc.
                if(!React.isValidElement(child)) return null;
                
                const span = spans.length > imageIndex ? spans[imageIndex] : 'shrink';
                imageIndex++;

                return (
                    <ImageItem span={ span }>
                        { child }
                    </ImageItem>
                );

            })}
        </ImagesWrapper>
    );

};

ArticleImage.propTypes = {

    /**
     * Requested column widths containing images e.g. '3 6 3'.
     * These values will be used to render images on medium and large viewports (tablet & desktop).
     * On mobile viewport images are one after another and take whole row.
     * By default sum of widths should not exceed 12, see ```defaultCapacity``` variable in /src/themes/bafDark/components/flex.js.
     * If not specified, largest possible values will be assigned automatically.
     */
    widths: pt.string,

};

export default ArticleImage;