/**
 * Exports GeneralCallout
 */

import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { Box, Container, Flex, Heading, Paragraph, useResponsiveProp } from '@arturpol/systheme';
import { useCallout } from '../../utils';
import feather from '../../../content/images/feather-callout.svg';

const StyledFeatherBackground = styled(Box)`
    transform: ${ props => props.$transform };
`;

const FeatherBackground = props => {

    props = {
        position: 'absolute',
        top: { small: 2, medium: -8, },
        left: { small: -10, medium: 0, },
        width: { small: 36, medium: 32, },
        height: { small: 36, medium: 32, },
        backgroundImage: `url(${ feather })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        opacity: 0.05,
        $transform: useResponsiveProp({ small: 'rotateZ(180deg) rotateX(180deg)', medium: 'rotateZ(180deg)', }),
        ...props,
    };

    return <StyledFeatherBackground { ...props } />;

};

const ColumnWrapper = props => {

    props = {
        position: 'relative', 
        textAlign: { small: 'left', medium: 'center', }, 
        justifyContent: { medium: 'center', }, 
        gutterType: { medium: 'margin', }, 
        gutter: { medium: 'large', large: 'xxlarge', },
        ...props,
    };

    return <Flex { ...props } />;

};

const ActionWrapper = props => {

    props = {
        el: 'footer',
        flexDirection: { small: 'column', medium: 'row', },
        justifyContent: { medium: 'center', },
        alignItems: { small: 'stretch', medium: 'center', },
        gap: { small: 'small', medium: 'medium', },
        ...props,
    };

    return <Flex { ...props } />;

};

/**
 * GeneralCallout React component.
 * Simple, generic callout to convert user.
 * @param {object} params
 */
const GeneralCallout = ({ id, children, showBackground, ...rest }) => {

    const { title, description } = useCallout(id);

    const props = {
        el: 'aside',
        paddingTop: 4, 
        paddingBottom: 12,
        ...rest,
    };

    return (
        <Box { ...props }>
            <Container position="relative">
                { showBackground ? <FeatherBackground /> : null }
                <ColumnWrapper>
                    <Box span={{ small: 12, medium: 10, large: 6, }} padding={{ smallOnly: '0 3', }}>
                        <Heading kind="h4" variant="secondary">{ title }</Heading>
                        <Paragraph kind="lead" mb="3">{ description }</Paragraph>
                        <ActionWrapper>{ children }</ActionWrapper>
                    </Box>
                </ColumnWrapper>
            </Container>
        </Box>
    );

};

GeneralCallout.propTypes = {

    /**
     * Callout id
     * See more in /content/data/callout.yml
     */
    id: pt.string.isRequired,

    /**
     * Primary and secondary callout buttons
     * See more in /src/components/Callout/Button.js and /content/data/button.yml
     */
    children: pt.node.isRequired,

    /**
     * Display background?
     */
    showBackground: pt.bool,
    
};

GeneralCallout.defaultProps = {
    showBackground: true,
};

export default GeneralCallout;