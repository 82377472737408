/**
 * Exports FooterMenuItem
 */

import React, { useCallback } from 'react';
import pt from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { Text, tokenProp } from '@arturpol/systheme';
import { PORTFOLIO_NODE_TYPE } from '../../../config';
import { usePath } from '../../Paths';
import { Link } from '../../Link';
import { PORTFOLIO_MENU_ID, useTopMenuActions } from '../../TopMenu';

const ListItem = props => {

    props = {
        el: 'li',
        display: 'inline-block',
        kind: 'button/medium',
        ...props,
    };

    return <Text { ...props } />;

};

const StyledListItem = styled(ListItem)`
    list-style: none;
`;

const StyledLink = styled(Link)`
    cursor: pointer;

    &:hover {
        color: ${ props => tokenProp(props.theme.color, props.theme.typography.linkColorHover)};
    }

    &:active {
        color: ${ props => tokenProp(props.theme.color, props.theme.typography.linkColorActive)};
    }
`;

/**
 * FooterMenuItem React component
 * @param {object} params
 */
const FooterMenuItem = ({ link, context, current, ...rest }) => {

    const { color, typography } = useTheme();

    // Top menu action (for portfolio)
    const { open } = useTopMenuActions();

    // Path to determine if it's a current page
    const path = usePath(context.pageId);

    // Does it open another menu (which one - id)?
    const menu = context.hasOwnProperty('_menu') && context._menu;

    // Open top menu callback with smooth scroll
    const callback = useCallback(() => open(menu, true), [ open, menu ]);

    const linkProps = {
        display: 'inline-block',
        pt: 2,
        pb: 2,
        color: tokenProp(color, typography.linkColor),
        ...link,
    };

    if(menu){
        linkProps.onTap = callback;
        linkProps.motion = true;
    }

    const liProps = {
        ...rest,
    };

    let isActive = false;
    
    if(current){
        if(menu) isActive = menu === PORTFOLIO_MENU_ID && current.collection === PORTFOLIO_NODE_TYPE;
        else isActive = current.path === path;
    }

    if(isActive) liProps.className = 'active';

    return (
        <StyledListItem { ...liProps }>
            <StyledLink { ...linkProps } />
        </StyledListItem>
    );

};

FooterMenuItem.propTypes = {
    
    /**
     * Props applied to Link component
     */
    link: pt.shape({
        to: pt.string,
        title: pt.string,
        children: pt.string.isRequired,
    }),

    /**
     * Info about current page
     */
    current: pt.shape({
        path: pt.string,
        collection: pt.string,
    }).isRequired,

    /**
     * Menu item context returned from getLink()
     */
    context: pt.shape({
        _menu: pt.string,
        id: pt.string,
    }),

};

FooterMenuItem.defaultProps = {
    context: {},
};

export default FooterMenuItem;