import React from 'react';

function ChessKing48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "chess king 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<circle cx="24" cy="6" fill="none" r="3" stroke={fill}/>
		<circle cx="42" cy="14" fill="none" r="3" stroke={fill}/>
		<circle cx="6" cy="14" fill="none" r="3" stroke={fill}/>
		<polyline fill="none" points="39.971 16.198 30 27 24.821 8.872" stroke={fill}/>
		<polyline fill="none" points="8.029 16.198 18 27 23.179 8.872" stroke={fill}/>
		<line fill="none" stroke={fill} x1="36" x2="41.302" y1="39" y2="16.91"/>
		<line fill="none" stroke={fill} x1="12" x2="6.698" y1="39" y2="16.91"/>
		<rect height="6" width="36" fill="none" x="6" y="39"/>
	</g>
</svg>
	);
};

export default ChessKing48;