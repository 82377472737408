/**
 * Exports Footer
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Box, Breakpoint, Container, Flex, Ruler } from '@arturpol/systheme';
import { getMdx } from '../../utils';
import FooterTextBlock from './TextBlock';
import { FooterSocialMedia } from './SocialMedia';
import { FooterMenu } from './Menu';
import FooterCopyrights from './Copyrights';

// Number of text blocks to render
const MAX_TEXT_BLOCKS = 2;

/**
 * React hook
 * @param {number} limit - max. number of blocks to return
 * @returns footer text blocks with title and Mdx description
 */
const useFooterTextBlocks = (limit = false) => {

    const data = useStaticQuery(graphql`
        query getFooterTextBlocks {
            allMdx(filter: {frontmatter: {_id: {regex: "/footer\\-block\\-[0-9]+/"}}}) {
                nodes {
                    frontmatter {
                        _id
                        footerBlock {
                            title
                        }
                    }
                    body
                }
            }
        }
    `);
    
    const blocks = data.allMdx.nodes.map((node, index) => {

        const id = node.frontmatter._id;
        let order = index;

        // sort by number at the end of the id
        const matches = id.match(/\d+$/);
        if(matches.length > 0) order = parseInt(matches[0], 10);

        return {
            id,
            title: node.frontmatter.footerBlock.title,
            description: getMdx(node.body),
            order,
        };

    }).sort((a, b) => a.order - b.order);

    if(limit === false) limit = blocks.length;
    return blocks.slice(0, limit);

};

/**
 * Overflow hidden fix for Safari
 */
const StyledFooterBackground = styled(Box)`
    isolation: isolate;
`;

const FooterBackground = props => {

    props = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        borderTopLeftRadius: { small: 'xlarge', xlarge: 'xxlarge' },
        borderTopRightRadius: { small: 'xlarge', xlarge: 'xxlarge' },
        ...props,
    };

    return (
        <StyledFooterBackground { ...props }>
            <Breakpoint small={
                <StaticImage src="../../../content/images/footer-bg-small.jpg" alt="" layout="fullWidth" />
            } medium={
                <StaticImage src="../../../content/images/footer-bg-medium.jpg" alt="" layout="fullWidth" />
            } />
        </StyledFooterBackground>
    );

};

const FooterContainer = props => {

    props = {
        pt: { small: 6, xlarge: 12 },
        pb: { small: 6, xlarge: 12 },
        position: 'relative',
        ...props,
    };

    return <Container { ...props } />;

};

const FooterBlockWrapper = props => {

    props = {
        flexDirection: { small: 'column', medium: 'row' }, 
        flexWrap: { small: 'nowrap', mediumOnly: 'wrap' }, 
        gutter: { small: 'medium', large: 'xxlarge' }, 
        gutterType: 'margin',
        mb: { mediumDown: 4 },
        ...props,
    };

    return <Flex { ...props } />;

};

/**
 * Footer React component - common footer for all pages
 * @param {object} props
 */
const Footer = props => {

    const textBlocks = useFooterTextBlocks(MAX_TEXT_BLOCKS);

    props = {
        el: 'footer',
        position: 'relative',
        ...props,
    };

    return (
        <Box { ...props }>
            <FooterBackground />
            <FooterContainer>
                <FooterBlockWrapper>
                    { textBlocks.map(({ id, title, description }) => (
                        <FooterTextBlock title={ title } description={ description } key={ id } span={{ small: 12, medium: 6, large: 4 }} />
                    ))}
                    <FooterSocialMedia span={{ small: 12, large: 4 }} />
                </FooterBlockWrapper>
                <Breakpoint medium={ <Ruler /> } />
                <FooterMenu />
                <FooterCopyrights />
            </FooterContainer>
        </Box>
    );
};

export default Footer;