/**
 * Exports TagNavigationButton
 */

import React from 'react';
import pt from 'prop-types';
import { useTheme } from 'styled-components';
import { IconButton } from '@arturpol/systheme';

/**
 * TagNavigationButton React component
 * Button to change slide in the TagCarousel
 */
const TagNavigationButton = React.forwardRef(({ icon, callback, ...rest }, ref) => {

    const theme = useTheme();
    const config = theme.tagCarousel;

    const props = {
        glyph: icon,
        size: config.navigationButtonIconSize,
        variant: config.navigationButtonVariant,
        onTap: callback,
        motion: true,
        ...rest,
        ref,
    };
    
    return <IconButton { ...props } />;

});

TagNavigationButton.propTypes = {

    /**
     * Glyph name
     * See /src/themes/bafDark/components/glyph
     */
    icon: pt.string.isRequired,

    /**
     * OnTap button handler
     */
    callback: pt.func.isRequired,
    
};

export default TagNavigationButton;