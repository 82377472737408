/**
 * Exports usePageInfo
 */

import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { filter, find } from '../../utils';

/**
 * React hook that returns contextual information about a given page.
 * @param {object} query - object to filter pages by one or more properties e.g. id
 * @param {boolean} single - true to return single page instead of array
 * @returns id, path and collection about pages found
 */
const usePageInfo = (query = false, single = false) => {

    const data = useStaticQuery(graphql`
        query getPageInfo {
            allSitePage {
                nodes {
                    path
                    pageContext
                }
            }
        }
    `);

    const pages = useMemo(() => {
        
        return data.allSitePage.nodes.filter(node => {
            // Only pages that have id and path
            return node.hasOwnProperty('path') && typeof node.path === 'string' 
                && node.hasOwnProperty('pageContext') && node.pageContext.hasOwnProperty('_id') && typeof node.pageContext._id === 'string';
        }).map(node => {
            return { path: node.path, id: node.pageContext._id, collection: node.pageContext.collection, };
        });

    }, [ data ]);

    if(typeof query !== 'object') return pages;
    return single ? find(pages, query) : filter(pages, query);

};

export default usePageInfo;