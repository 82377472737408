/**
 * Exports usePortfolioSubtitle
 */

import { useStaticQuery, graphql } from 'gatsby';

/**
 * React hook
 * @returns subtitle used both in the portfolio and session template: 'Portfolio'
 */
const usePortfolioSubtitle = () => {

    const { mdx } = useStaticQuery(graphql`
        query getPortfolioSubtitle {
            mdx(frontmatter: {_id: {eq: "portfolio-content"}}) {
                frontmatter {
                    portfolioContent {
                        title
                    }
                }
            }
        }
    `);

    return mdx.frontmatter.portfolioContent.title;

};

export default usePortfolioSubtitle;