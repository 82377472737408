/**
 * Exports Decoration, DECORATION_PROPS, useDecorationStyle
 */

import React from 'react';
import { useTheme } from 'styled-components';
import { Breakpoint, tokenProp } from '@arturpol/systheme';
import { Parallax } from '../Parallax';

// Image z-index as design token
const DECORATION_Z_INDEX = 'level-1';

// Background image default styles
const DECORATION_STYLE = {
    position: 'absolute',
    display: 'inline-block',
    pointerEvents: 'none',
    top: 0,
    left: 0,
};

// Computes image position and returns style object
const useDecorationStyle = (width, top, horizontalOffset, alignToLeft = true, rotation = '0deg') => {

    const { container, rem, size, ...theme } = useTheme();
    
    width = size(width).px();

    horizontalOffset = size(horizontalOffset).get();
    horizontalOffset = alignToLeft ? -1 * horizontalOffset : horizontalOffset;
    
    const containerWidth = size(container.width).get();
    const halfWidth = rem(containerWidth / 2 + horizontalOffset);
    const sign = alignToLeft ? '-' : '+';
    const left = `calc(50% ${ sign } ${ halfWidth }`;
    const zIndex = tokenProp(theme.zIndex, DECORATION_Z_INDEX);
    const transform = `rotateZ(${ rotation })`;

    return {
        ...DECORATION_STYLE,
        zIndex,
        top: rem(top),
        left,
        width,
        transform,
    };

};

// Decoration React component as a prototype for all background elements
const Decoration = ({ children, ...rest }) => <Breakpoint large={ <Parallax>{ children }</Parallax> } />;

export {
    Decoration,
    useDecorationStyle
};