/**
 * Exports Feature
 */

import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import { Box, Icon, Heading, Paragraph } from '@arturpol/systheme';

const BORDER_STYLE = '1px solid rgba(255,255,255,0.05)';

const ListItem = styled(Box)`
    list-style: none;
`;

/**
 * Feature React component
 * Feature on the feature list
 */
const Feature = React.forwardRef(({ title, description, icon, last, ...rest }, ref) => {

    const props = {
        el: 'li',
        padding: { small: 3, large: 5, },
        ...rest,
        ref,
    };

    if(!last){
        props.borderRight = { small: 'none', medium: BORDER_STYLE, };
        props.borderBottom = { small: BORDER_STYLE, medium: 'none', };
    }

    return (
        <ListItem { ...props }>
            { icon ? <Icon size="xlarge" glyph={ icon } fill="primary/50" mb="3" /> : null }
            <Heading kind="h5" variant="secondary">{ title }</Heading>
            <Paragraph kind="label" mb={{ small: 1, medium: 0, }}>{ description }</Paragraph>
        </ListItem>
    );

});

Feature.propTypes = {

    /**
     * Feature name
     */
    title: pt.string.isRequired,

    /**
     * Feature description
     */
    description: pt.string.isRequired,

    /**
     * Optional icon
     */
    icon: pt.string,

    /**
     * Is it last item?
     */
    last: pt.bool,
    
};

export default Feature;