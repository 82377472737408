/**
 * Exports useLanguages
 */

import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { hasProperty } from '../helpers';
import flagUk from '../../../content/images/flag-uk.png';
import flagPl from '../../../content/images/flag-pl.png';
import flagCn from '../../../content/images/flag-cn.png';
import flagRu from '../../../content/images/flag-ru.png';

const ID_TO_IMAGE = {
    en: flagUk,
    pl: flagPl,
    cn: flagCn,
    ru: flagRu,
};

/**
 * React hook that returns website author's languages.
 * Check ```/content/data/site.yml``` for languages definitions.
 * @returns array of language objects
 */
const useLanguages = () => {

    const { data } = useStaticQuery(graphql`
        query getLanguages {
            data(_id: {eq: "site"}) {
                languages {
                    _id
                    alt
                    caption
                }
            }
        }
    `);
    
    const languages = useMemo(() => {

        return data.languages.map(({ _id, caption, alt }) => {

            const result = { 
                id: _id, 
                caption,
            };
    
            if(hasProperty(ID_TO_IMAGE, _id, 'string')) result.image = { alt, src: ID_TO_IMAGE[_id], };

            return result;

        });

    }, [ data ]);
    
    return languages;

};

export default useLanguages;