import { token } from '../../utils';
import { size } from '../size';

export default token('fontSize', [{
    key: 'h1',
    order: 64,
    value: size(8),
},{
    key: 'h2',
    order: 50,
    value: size(6.25),
},{
    key: 'h3',
    order: 38,
    value: size(4.75),
},{
    key: 'h4',
    order: 28,
    value: size(3.5),
},{
    key: 'h5',
    order: 21,
    value: size(2.625),
},{
    key: 'h6',
    order: 18,
    value: size(2.25),
},{
    key: 'body',
    order: 16,
    value: size(2),
},{
    key: 'label',
    order: 14,
    value: size(1.75),
},{
    key: 'caption',
    order: 12,
    value: size(1.5),
},{
    key: 'small',
    order: 10,
    value: size(1.25),
}]);