import React from 'react';

function Face48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "face 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<polyline fill="none" points="4 12 4 4 12 4"/>
		<polyline fill="none" points="44 12 44 4 36 4"/>
		<polyline fill="none" points="4 36 4 44 12 44"/>
		<polyline fill="none" points="44 36 44 44 36 44"/>
		<path d="M38,38l-6.275-2.092a4,4,0,0,1-2.735-3.781l-.006-1.974A13.576,13.576,0,0,0,34,20V18.342a10.227,10.227,0,0,0-9.335-10.32A10,10,0,0,0,14,18v2a13.559,13.559,0,0,0,5,10.153v1.964a4,4,0,0,1-2.735,3.8L10,38" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Face48;