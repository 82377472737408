/**
 * Exports ImageParallax
 */

import React, { useRef } from 'react';
import pt from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { useScroll, useTransform } from 'framer-motion';
import { Box, useSize, useTransition, durationProp, easingProp } from '@arturpol/systheme';

// iOS overflow=hidden fix
const StyledBox = styled(Box)`
    isolation: isolate;
`;

/**
 * ImageParallax React component
 * @param {object} params
 * @returns wraps image, reduces its height and scrolls content automatically as user scrolls too
 */
const ImageParallax = ({ children, offset, trimPct, ...rest }) => {

    const theme = useTheme();
    const innerRef = useRef(null);
    const outerRef = useRef(null);
    const size = useSize(innerRef);
    const os = offset ? offset : ['0 start', '0 end'];

    const { scrollYProgress } = useScroll({
        target: outerRef,
        offset: os,
    });

    const diff = size ? Math.floor(size.height * trimPct) : 0;
    const height = size ? (size.height - diff) : false;

    const y = useTransform(
        scrollYProgress, 
        [0 , 1], 
        [`${ -1 * diff }px`, '0px'],
    );

    const ty = useTransition(y, {
        duration: durationProp(theme.duration, 'long'),
        ease: easingProp(theme.easing, 'ease-out/weak'),
    });
    
    let props = {
        ...rest,
        overflow: 'hidden',
        ref: outerRef,
    };

    if(height) props = { ...props, height: `${ height }px` };
    
    const style = { y: ty };

    return (
        <StyledBox { ...props }>
            <Box motion ref={ innerRef } style={ style }>{ children }</Box>
        </StyledBox>
    );

};

ImageParallax.propTypes = {

    /**
     * GatsbyImage node or similar containing an image
     */
    children: pt.node.isRequired,

    /**
     * Array with two items indicating two intersections of target (image) and viewport e.g. ['start start', 'end end']
     * See framer motion ```useScroll``` hook and ```offset``` param
     */
    offset: pt.array,

    /**
     * What percentage of image should be trimmed? Example: 0.2 means 20% of image will be hidden initially
     */
    trimPct: pt.number,

};

ImageParallax.defaultProps = {
    trimPct: 0.1,
};

export default ImageParallax;