import React from 'react';

function Refresh20(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "refresh 20";

	return (
		<svg height={height} width={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth} transform="translate(0.5 0.5)">
		<path d="M6.58 15.49l-4.72-1.32 0.6 4.81" fill="none"/>
		<path d="M19.09 9.96a9.13 9.13 0 0 1-17.23 4.21" fill="none"/>
		<path d="M13.34 4.43l4.72 1.32-0.6-4.81" fill="none" stroke={fill}/>
		<path d="M0.83 9.96a9.13 9.13 0 0 1 17.23-4.21" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Refresh20;