/**
 * Exports TagSlide
 */

import React from 'react';
import pt from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { Flex, durationProp, easingProp } from '@arturpol/systheme';
import { TagLabel, TagIcon, TagImage } from '../../Tag';

/**
 * Removes default styles for <li>
 */
const ListItem = styled(Flex)`
    list-style: none;
`;

/**
 * TagSlide React component
 * Slide wrapper in the TagCarousel
 */
const TagSlide = React.forwardRef(({ icon, image, label, hidden, ...rest }, ref) => {

    const theme = useTheme();
    const config = theme.tagCarousel;
    const tagConfig = theme.tag;

    const duration = durationProp(theme.duration, config.slideTransitionDuration) / 2;
    const easing = easingProp(theme.easing, config.slideTransitionEasing);

    const transition = {
        duration,
        ease: easing,
    };
    
    const props = {
        el: 'li',
        motion: true,
        height: '100%',
        gap: tagConfig.gap,
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
        animate: hidden ? 'hidden' : 'visible',
        variants: {
            visible: {
                opacity: 1,
                position: 'static',
                transition: {
                    delay: duration,
                    ...transition,
                }
            },
            hidden: {
                opacity: 0,
                transitionEnd: {
                    position: 'absolute',
                },
                transition,
            },
        },
        ...rest,
        ref,
    };

    return (
        <ListItem { ...props }>
            { image ? <TagImage { ...image } /> : null }
            { icon ? <TagIcon glyph={ icon } /> : null }
            <TagLabel>{ label }</TagLabel>
        </ListItem>
    );

});

TagSlide.propTypes = {

    /**
     * Tag label
     */
    label: pt.node.isRequired,

    /**
     * Tag icon
     */
    icon: pt.string,

    /**
     * Tag image
     */
    image: pt.shape({
        src: pt.string.isRequired,
        alt: pt.string.isRequired,
    }),

    /**
     * Set true if it's not current slide
     */
    hidden: pt.bool,
    
};

export default TagSlide;