/**
 * Exports useServices
 */

import { useStaticQuery, graphql } from 'gatsby';
import { getService, find } from '../helpers';

/**
 * React hook
 * See /content/data/service/service.yml
 * @param {string|Array} serviceIds - single service id as a string or array of such ids (optional)
 * @returns services with matching id(s) or all of them if no id is specified
 */
const useServices = serviceIds => {

    const { allService } = useStaticQuery(graphql`
        query getServices {
            allService {
                nodes {
                    _id
                    _portfolio
                    description
                    image {
                        alt
                        src {
                            childImageSharp {
                                gatsbyImageData(width: 783, layout: CONSTRAINED)
                            }
                        }
                    }
                    price {
                        amount
                    }
                    title
                }
            }
        }
    `);

    const services = allService.nodes.map(item => getService(item));

    if(typeof serviceIds === 'string'){
        return find(services, { id: serviceIds });
    }

    if(Array.isArray(serviceIds)){
        
        const ids = [ ...serviceIds ];
        return services.filter(item => {
            
            const index = ids.indexOf(item.id);

            if(index >= 0){
                ids.splice(index, 1);
                return true;
            }

            return false;

        });

    }

    return services;

};

export default useServices;