import React from 'react';

function Hash24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "hash 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<line fill="none" stroke={fill} x1="7" x2="10" y1="22" y2="2"/>
		<line fill="none" stroke={fill} x1="14" x2="17" y1="22" y2="2"/>
		<line fill="none" stroke={fill} x1="5" x2="20" y1="8" y2="8"/>
		<line fill="none" stroke={fill} x1="4" x2="19" y1="16" y2="16"/>
	</g>
</svg>
	);
};

export default Hash24;