/**
 * Exports icon glyphs as a single design token
 */

import { icons } from '../../utils';

/**
 * Import all individual icons as React components from the Nucleo app. Use JSX export option with following settings:
 * - "Use props for inline colors"
 * - "Use props for stroke-width values"
 * - "Use props for height/width values"
 * 
 * IMPORTANT: Make sure names of the icons are in the following format: "glyph-name-separated-with-dashes-16", where "16" is the size in pixels
 */

import ArrowLeft16 from './arrow-left-16';
import ArrowLeft20 from './arrow-left-20';
import ArrowLeft24 from './arrow-left-24';

import ArrowRight16 from './arrow-right-16';
import ArrowRight20 from './arrow-right-20';
import ArrowRight24 from './arrow-right-24';

import BabyClothes48 from './baby-clothes-48';

import Badge48 from './badge-48';

import ButtonPlay20 from './button-play-20';
import ButtonPlay24 from './button-play-24';
import ButtonPlay48 from './button-play-48';

import Cake24 from './cake-24';
import Cake48 from './cake-48';
import Cake64 from './cake-64';

import Calendar20 from './calendar-20';
import Calendar24 from './calendar-24';

import Camera48 from './camera-48';

import Chat48 from './chat-48';

import Check12 from './check-12';
import Check16 from './check-16';
import Check20 from './check-20';
import Check24 from './check-24';
import Check32 from './check-32';
import Check48 from './check-48';

import ChessKing32 from './chess-king-32';
import ChessKing48 from './chess-king-48';

import ChessKnight32 from './chess-knight-32';
import ChessKnight48 from './chess-knight-48';

import ChessPawn32 from './chess-pawn-32';
import ChessPawn48 from './chess-pawn-48';

import ChessTower32 from './chess-tower-32';
import ChessTower48 from './chess-tower-48';

import Close12 from './close-12';
import Close16 from './close-16';
import Close20 from './close-20';
import Close24 from './close-24';
import Close32 from './close-32';

import Face24 from './face-24';
import Face48 from './face-48';
import Face64 from './face-64';

import Facebook20 from './facebook-20';
import Facebook24 from './facebook-24';
import Facebook32 from './facebook-32';
import Facebook48 from './facebook-48';

import Hash16 from './hash-16';
import Hash20 from './hash-20';
import Hash24 from './hash-24';

import Horse24 from './horse-24';
import Horse48 from './horse-48';
import Horse64 from './horse-64';

import Instagram20 from './instagram-20';
import Instagram24 from './instagram-24';
import Instagram32 from './instagram-32';
import Instagram48 from './instagram-48';

import Love24 from './love-24';
import Love48 from './love-48';
import Love64 from './love-64';

import Menu32 from './menu-32';

import Minus12 from './minus-12';
import Minus16 from './minus-16';
import Minus20 from './minus-20';
import Minus24 from './minus-24';

import Pin20 from './pin-20';
import Pin24 from './pin-24';

import Quote48 from './quote-48';

import Refresh20 from './refresh-20';
import Refresh24 from './refresh-24';

import Repeat24 from './repeat-24';
import Repeat48 from './repeat-48';

import Rings24 from './rings-24';
import Rings48 from './rings-48';
import Rings64 from './rings-64';

import Safe48 from './safe-48';

import Send48 from './send-48';

import Spa48 from './spa-48';

import Submarine48 from './submarine-48';

import TriangleDown12 from './triangle-down-12';
import TriangleDown16 from './triangle-down-16';

import TriangleRight24 from './triangle-right-24';

import TriangleUp12 from './triangle-down-12';

import Users48 from './users-48';

import Virus48 from './virus-48';

import Wand48 from './wand-48';

import Warning20 from './warning-20';
import Warning24 from './warning-24';

import Youtube20 from './youtube-20';
import Youtube24 from './youtube-24';
import Youtube32 from './youtube-32';
import Youtube48 from './youtube-48';

/**
 * Glyph token generation
 */
const glyph = icons({
    
    ArrowLeft16,
    ArrowLeft20,
    ArrowLeft24,

    ArrowRight16,
    ArrowRight20,
    ArrowRight24,

    BabyClothes48,

    Badge48,

    ButtonPlay20,
    ButtonPlay24,
    ButtonPlay48,

    Cake24,
    Cake48,
    Cake64,

    Calendar20,
    Calendar24,

    Camera48,

    Chat48,

    Check12,
    Check16,
    Check20,
    Check24,
    Check32,
    Check48,

    ChessKing32,
    ChessKing48,

    ChessKnight32,
    ChessKnight48,

    ChessPawn32,
    ChessPawn48,

    ChessTower32,
    ChessTower48,

    Close12,
    Close16,
    Close20,
    Close24,
    Close32,

    Face24,
    Face48,
    Face64,

    Facebook20,
    Facebook24,
    Facebook32,
    Facebook48,

    Hash16,
    Hash20,
    Hash24,

    Horse24,
    Horse48,
    Horse64,

    Instagram20,
    Instagram24,
    Instagram32,
    Instagram48,

    Love24,
    Love48,
    Love64,

    Menu32,

    Minus12,
    Minus16,
    Minus20,
    Minus24,

    Pin20,
    Pin24,

    Quote48,

    Refresh20,
    Refresh24,

    Repeat24,
    Repeat48,

    Rings24,
    Rings48,
    Rings64,

    Safe48,

    Send48,

    Spa48,

    Submarine48,

    TriangleDown12,
    TriangleDown16,

    TriangleRight24,
    
    TriangleUp12,

    Users48,

    Virus48,

    Wand48,

    Warning20,
    Warning24,

    Youtube20,
    Youtube24,
    Youtube32,
    Youtube48,

});

export default glyph;