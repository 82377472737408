/**
 * Exports option-related design tokens
 */

export const height = 5;
export const horizontalPadding = 2;
export const iconSize = 'small';
export const borderRadius = 'none';

// gap between text elements e.i. main text and counter and/or caption
export const textGap = 2; 

// gap between text elements wrapper icon
export const iconGap = 1; 

export const transitionDuration = 'short';

const primaryColor = 'gray/10';
const secondaryColor = 'gray/40';

/**
 * Object defining option colors and text styles for verious states using following properties:
 * - background - option background color
 * - textKind - main text kind (see Text)
 * - captionKind - caption text kind
 * - counterKind - counter text kind
 * - text - colour of main text
 * - icon - color of an icon
 * - caption - text color of a caption
 * - counter - text color of a counter
 */
export const appearance = {
    background: 'transparent',
    textKind: 'p/label',
    captionKind: 'p/caption',
    counterKind: 'p/label',
    text: primaryColor,
    icon: primaryColor,
    caption: secondaryColor,
    counter: secondaryColor,

    hover: {
        background: 'gray/80',
    },

    active: {
        background: 'gray/80',
        text: 'primary/40',
        icon: 'primary/40',
    },

    disabled: {
        text: 'gray/60',
        icon: 'gray/60',
        caption: 'gray/60',
        counter: 'gray/60',
    },

    invalid: {
        text: 'alert/50',
        icon: 'alert/50',

        hover: {
            background: 'gray/80',
        },

        active: {
            background: 'gray/80',
            text: 'alert/80',
            icon: 'alert/80',
        },

        disabled: {
            text: 'gray/60',
            icon: 'gray/60',
            caption: 'gray/60',
            counter: 'gray/60',
        },
    },

    selected: {
        background: 'primary/40',
        text: 'gray/90',
        icon: 'gray/90',
        caption: 'gray/70',
        counter: 'gray/70',

        hover: {
            background: 'primary/50',
        },

        active: {
            background: 'primary/50',
            text: 'black',
            icon: 'black',
            caption: 'gray/80',
            counter: 'gray/80',
        },

        disabled: {
            background: 'gray/80',
            text: 'gray/60',
            icon: 'gray/60',
            caption: 'gray/60',
            counter: 'gray/60',
        },

        invalid: {
            background: 'alert/50',
            text: 'gray/90',
            icon: 'gray/90',
            caption: 'gray/70',
            counter: 'gray/70',

            hover: {
                background: 'alert/80',
            },

            active: {
                background: 'alert/80',
                text: 'black',
                icon: 'black',
                caption: 'gray/80',
                counter: 'gray/80',
            },

            disabled: {
                background: 'gray/80',
                text: 'gray/60',
                icon: 'gray/60',
                caption: 'gray/60',
                counter: 'gray/60',
            },
        },
    },
};