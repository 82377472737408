import React from 'react';

function Facebook20(props) {
	const fill = props.fill || 'currentColor';
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "facebook 20";

	return (
		<svg height={height} width={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="none">
		<path d="M7.526 19.167L7.5 10.833H4.167V7.5H7.5V5.417C7.5 2.324 9.415.833 12.174.833c1.322 0 2.458.099 2.789.143v3.232h-1.914c-1.5 0-1.79.714-1.79 1.76V7.5h4.366l-1.667 3.333h-2.7v8.334H7.526z" fill={fill}/>
	</g>
</svg>
	);
};

export default Facebook20;