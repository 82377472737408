import { token } from '../../utils';

export default token('duration', [{
    key: 'none',
    order: 0,
    value: '0ms',
},{
    key: 'short',
    order: 150,
    value: '150ms',
},{
    key: 'medium',
    order: 300,
    value: '300ms',
},{
    key: 'long',
    order: 500,
    value: '500ms',
},{
    key: 'xlong',
    order: 750,
    value: '750ms',
},{
    key: 'xxlong',
    order: 1500,
    value: '1.5s',
},{
    key: 'xxxlong',
    order: 2500,
    value: '2.5s',
}]);