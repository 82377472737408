import React from 'react';

function Cake48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "cake 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M46,29c0,4.418-3.582,8-8,8 c-3.036,0-5.645-1.711-7-4.202C29.645,35.289,27.036,37,24,37s-5.645-1.711-7-4.202C15.645,35.289,13.036,37,10,37 c-4.418,0-8-3.582-8-8" fill="none"/>
		<path d="M24,15 c2.209,0,4-1.791,4-4s-4-8-4-8s-4,5.791-4,8S21.791,15,24,15z" fill="none"/>
		<line fill="none" x1="24" x2="24" y1="15" y2="21"/>
		<rect height="16" width="44" fill="none" stroke={fill} x="2" y="29"/>
		<polyline fill="none" points="40,29 40,21 8,21 8,29 " stroke={fill}/>
	</g>
</svg>
	);
};

export default Cake48;