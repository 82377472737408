/**
 * Exports useTouch
 */

import { useState, useEffect } from 'react';

/**
 * Returns true if device supports touch events
 */
const isTouchEnabled = () => {
    
    return ( 'ontouchstart' in window ) ||
           ( navigator.maxTouchPoints > 0 ) ||
           ( navigator.msMaxTouchPoints > 0 );

};

/**
 * React hook
 * @returns true if touch events are supoorted
 */
const useTouch = () => {

    const [ isTouch, setIsTouch ] = useState(false);

    useEffect(() => {

        setIsTouch(isTouchEnabled());

    }, []);

    return isTouch;

};

export default useTouch;