/**
 * Exports TopMenu
 */

import React, { useCallback, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { useTheme } from 'styled-components';
import { Modal, Box, Text, Flex, durationProp, easingProp, useResponsiveProp } from '@arturpol/systheme';
import { useTopMenuState, useTopMenuActions, PORTFOLIO_MENU_ID, MAIN_MENU_ID } from './Context';
import { MainMenu } from './Main';
import { PortfolioMenu } from './Portfolio';

/**
 * React hook
 * @param {string} menuId - menu id to determine which title to return
 * @returns top menu titles
 */
const useTopMenuTitle = menuId => {

    const data = useStaticQuery(graphql`
        query getTopMenuTitle {
            allMenu(filter: {_id: {in: ["main", "portfolio"]}}) {
                nodes {
                    _id
                    title
                }
            }
        }
    `);

    const node = data.allMenu.nodes.find(node => node._id === menuId);
    return node ? node.title : false;

};

const TitleWrapper = ({ children, ...rest }) => {

    const { topMenu, typography } = useTheme();

    const vp = useResponsiveProp(topMenu.verticalPadding);
    const hp = useResponsiveProp(topMenu.horizontalPadding);

    const props = {
        el: 'header',
        padding: `${ vp } ${ hp }`,
        ...rest,
    };

    return (
        <Box { ...props }>
            <Text kind="button/medium" color={ typography.headingDefaultColor } mb="0">{ children }</Text>
        </Box>
    );

};

const StyledModal = styled(Modal)`
    & > a {
        top: ${ props => props.$buttonTop };
        right: ${ props => props.$buttonRight };
    }
`;

const StyledMenuWrapper = styled(Flex)`
    ${ props => !props.$enabled && 'pointe-events: none;' }
`;

const MenuWrapper = ({ menu, ...rest }) => {

    const { topMenu, ...theme } = useTheme();
    
    const duration = durationProp(theme.duration, topMenu.transitionDuration);
    const easing = easingProp(theme.easing, topMenu.transitionEasing);

    const transition = {
        duration,
        ease: easing,
    };
    
    const [ isEnabled, setIsEnabled ] = useState(true);
    const onAnimationStart = useCallback(() => setIsEnabled(false), []);
    const onAnimationComplete = useCallback(() => setIsEnabled(true), []);

    const props = {
        motion: true, 
        width: '200%',
        alignItems: { small: 'stretch', large: 'flex-start', }, 
        initial: false,
        animate: menu,
        variants: { 
            [ MAIN_MENU_ID ]: { 
                x: 0,
                transition,            
            }, 
            [ PORTFOLIO_MENU_ID ]: { 
                x: '-50%',
                transition,
            },
        },
        onAnimationStart: onAnimationStart,
        onAnimationComplete: onAnimationComplete,
        $enabled: isEnabled,
        ...rest,
    };

    return <StyledMenuWrapper { ...props } />;

};

/**
 * TopMenu React component
 * Renders top menu including main and portfolio parts based on the state from the context provider (see Context.js)
 * @param {object} props 
 */
const TopMenu = props => {

    const { container, size, topMenu } = useTheme();
    const halfWidth = size(container.width).get() / 2;

    // Menu state
    const { opened, menuId } = useTopMenuState();
    const { close } = useTopMenuActions();
    const title = useTopMenuTitle(menuId);

    const buttonTop = size(useResponsiveProp(topMenu.closeButtonOffsetTop)).rem();
    const buttonRight = size(useResponsiveProp(topMenu.closeButtonOffsetRight)).rem();

    const modalProps = {
        ...props,
        opened,
        onClose: close,
        animation: 'slide-top',
        swipe: { small: 'to-top', large: false, },
        alignX: { small: 'stretch', large: 'right', },
        alignY: { small: 'stretch', large: 'top', },
        width: { small: '100%', large: '44', },
        marginRight: { 
            small: 0, 
            xlarge: `calc(50% - ${ halfWidth })`,
        },
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: topMenu.verticalPadding,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: { small: 0, large: 'medium', },
        borderBottomRightRadius: { small: 0, large: 'medium', },
        overflow: 'hidden',
        zIndex: 'modal',
        $buttonTop: buttonTop,
        $buttonRight: buttonRight,
    };

    return (
        <StyledModal { ...modalProps }>
            <TitleWrapper>{ title }</TitleWrapper>
            <Flex flex={{ small: 1, large: '0 0 auto', }} alignItems={{ small: 'stretch', large: 'flex-start', }} position="relative" overflow="hidden">
                <MenuWrapper menu={ menuId }>
                    <MainMenu span="6" />
                    <PortfolioMenu span="6" />
                </MenuWrapper>
            </Flex>
        </StyledModal>
    );

};

export default TopMenu;