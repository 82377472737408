/**
 * Exports TextLink
 */
import React from 'react';
import Link from './Link';

/**
 * TextLink React component.
 * Renders a text link. It is a wrapper on Link component, that changes its style.
 * @param {object} props 
 */
const TextLink = props => {

    return <Link { ...props } text />;

};

export default TextLink;