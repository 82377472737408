import React from 'react';

function ChessPawn32(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "chess pawn 32";

	return (
		<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<circle cx="16" cy="6" fill="none" r="5" stroke={fill}/>
		<line fill="none" stroke={fill} x1="9" x2="23" y1="11" y2="11"/>
		<path d="M19,11a23.866,23.866,0,0,0,5.3,15" fill="none" stroke={fill}/>
		<path d="M7.7,26A23.866,23.866,0,0,0,13,11" fill="none" stroke={fill}/>
		<rect height="5" width="22" fill="none" x="5" y="26"/>
	</g>
</svg>
	);
};

export default ChessPawn32;