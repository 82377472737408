/**
 * Exports data manipulation/mapping functions
 * used in context of data fetched from graphql queries
 */

import { getImage as gatsbyGetImage } from 'gatsby-plugin-image';
import { FEATURE_ICONS } from '../../config';
import { getLink } from './link';
import { hasProperty } from './misc';

/**
 * Orders graphql nodes according to the list of their ids (or other property)
 * @param {array} nodes - array of objects
 * @param {array} order - array of ids (or other node property)
 * @param {string} propertyName - property name used to find/order nodes
 * @returns nodes array in the orders specified by 'order' property
 */
const orderNodes = (nodes, order, propertyName = 'id') => {

    let input = [ ...nodes ];

    return order.map(id => {

        const index = input.findIndex(item => {
            return item.hasOwnProperty(propertyName) && item[propertyName] === id;
        });

        return index >= 0 ? input.splice(index, 1)[0] : false;

    }).filter(item => item !== false);

};

/**
 * Normalizes session data returned from the graphql
 * @param {object} node
 */
const getSession = node => {

    const { frontmatter } = node;
    const image = getImage(node);
    const { _id, _portfolio, credits, ...rest } = frontmatter;

    const result = { 
        id: _id, 
        portfolio: _portfolio, 
        ...rest,
        image,
    };
    
    if(Array.isArray(credits)){
        
        // Normalizes credits
        result.credits = credits.map(({ role, ...rest }) => {
            
            const item = { role };

            if(rest.hasOwnProperty('url')){
                // Link person's name
                const { link } = getLink({ ...rest, external: true });
                item.link = link;
            } else if(rest.hasOwnProperty('anchor')){
                // No link
                item.name = rest.anchor;
            }

            return item;

        });

    }

    return result;

};

/**
 * Returns normalized sessions from page query
 * @param {array} items - array of mdx nodes (sessions)
 * @param {array} order - array of session page ids defining the order
 * @returns array of normalized sessions
 */
const getSessions = (items, order) => {

    const sessions = items.map(getSession);
    return orderNodes(sessions, order);

};

/**
 * Normalizes article data returned from the graphql
 * @param {object} node
 */
const getArticle = ({ frontmatter, excerpt }) => {
    
    const { _id, image, ...rest } = frontmatter;
    
    return { 
        id: _id,
        image: {
            alt: image.alt,
            image: {
                square: gatsbyGetImage(image.square),
                wide: gatsbyGetImage(image.wide),
                tall: gatsbyGetImage(image.tall),
            },
        },
        ...rest, 
        excerpt, 
    };

};

/**
 * Returns normalized articles from page query
 * @param {array} items - array of mdx nodes (articles)
 * @param {array} order - array of article page ids defining the order
 * @returns array of normalized articles
 */
const getArticles = (items, order) => {

    const articles = items.map(getArticle);
    return orderNodes(articles, order);

};

/**
 * Normalizes webpage data returned from the graphql
 * @param {object} node
 */
const getWebpage = ({ frontmatter }) => {
    
    const { _id, ...rest } = frontmatter;
    
    return { 
        id: _id,
        ...rest, 
    };

};

/**
 * Normalizes testimonial data returned from the graphql
 * @param {object} node
 */
const getTestimonial = ({ _id, image, ...rest }) => {
    
    return { 
        id: _id,
        image: {
            alt: image.alt,
            image: gatsbyGetImage(image.src),
        },
        ...rest,
    };

};

/**
 * Returns normalized testimonials from page query
 * @param {array} items - array of yaml nodes (testimonials)
 * @param {array} order - array of testimonial ids defining the order
 * @returns array of normalized testimonials
 */
const getTestimonials = (items, order) => {

    const testimonials = items.map(getTestimonial);
    return orderNodes(testimonials, order);

};

/**
 * Normalizes feature data returned from the graphql
 * Assigns icon name to a feature.
 * @param {object} node
 */
const getFeature = ({ _id, ...rest }) => {
        
    const result = { id: _id, ...rest };
    if(FEATURE_ICONS.hasOwnProperty(_id)) result.icon = FEATURE_ICONS[_id];
    return result;
    
};

/**
 * Returns normalized features from page query
 * @param {array} items - array of yaml nodes (features)
 * @param {array} order - array of feature ids defining the order
 * @returns array of normalized features
 */
const getFeatures = (items, order) => {

    const features = items.map(getFeature);
    return orderNodes(features, order);

};

/**
 * Normalizes service data returned from the graphql
 * @param {object} node
 */
const getService = ({ _id, _portfolio, image, ...rest }) => {

    const result = { 
        id: _id, 
        image: {
            alt: image.alt,
            image: gatsbyGetImage(image.src),
        },
        ...rest 
    };

    if(_portfolio) result.portfolio = _portfolio;

    return result;

};

/**
 * Returns normalized services from page query
 * @param {array} items - array of yaml nodes (services)
 * @param {array} order - array of service ids defining the order
 * @returns array of normalized services
 */
const getServices = (items, order) => {

    const services = items.map(getService);
    return orderNodes(services, order);

};

/**
 * Normalizes product data returned from the graphql
 * @param {object} node
 */
const getProduct = ({ _id, product }) => {

    let { images, ...rest } = product;

    images = images.map(image => {
        return {
            full: { alt: image.alt, image: gatsbyGetImage(image.full), },
            thumbnail: { alt: image.alt, image: gatsbyGetImage(image.thumbnail), },
        };
    });

    const result = { 
        id: _id, 
        images,
        ...rest, 
    };

    return result;

};

/**
 * Returns image data from Mdx or Yaml graphql node ready to supply to GatsbyImage
 * @param {object} node - graphql node
 * @param {bool} isYaml - is it yaml-based node?
 * @returns object with image and alt properties
 */
const getImage = (node, isYaml = false) => {

    const root = isYaml ? node : node.frontmatter;
    if(!hasProperty(root, 'image', 'object')) return false;
    
    const { alt, src } = root.image;

    return {
        alt,
        image: gatsbyGetImage(src),
    };

};

/**
 * Normalizes location data returned from the graphql
 * @param {object} node
 */
const getLocation = ({ _id, churches, venues, powiat, wojewodztwo, towns, ...rest }) => {

    let result = {
        id: _id,
        ...rest,
    };

    if(powiat) result = { ...result, subregion: powiat, };
    if(wojewodztwo) result = { ...result, region: wojewodztwo, };

    if(churches) result = {
        ...result,
        churches: churches.map(({ _id, ...church }) => ({ id: _id, ...church, })),
    };

    if(venues) result = {
        ...result,
        venues: venues.map(({ _id, ...venue }) => ({ id: _id, ...venue, })),
    };

    if(towns) result = {
        ...result,
        _locations: towns,
    };

    return result;

};

export { 
    orderNodes,

    getSession,
    getSessions,

    getTestimonial,
    getTestimonials,

    getArticle,
    getArticles,

    getFeature,
    getFeatures,

    getService,
    getServices,

    getProduct,

    getWebpage,

    getLocation,

    getImage,
};