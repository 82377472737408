/**
 * Exports Image
 */

import React from 'react';
import pt from 'prop-types';
import { useTheme } from 'styled-components';
import { Flex, Container, splitByWhitespace, useResponsiveProp, tokenProp } from '@arturpol/systheme';
import { ImageItem } from '../ImageItem';
import { ImageParallax } from '../ImageParallax';

/**
 * Returns Flex gutter prop to separate columns of images and marginBottom 
 * to separate this image row from another
 * @param {string} gap - up to two values from theme ```allowedGaps``` separated by white space
 * @param {string} rowGap - overrides first value from ```gap``` prop (gap between rows, e.i. margin bottom)
 * @param {*} columnGap - overrides first value from ```gap``` prop (gap between columns, e.i. gutter)
 * @returns object with 'gutter' and 'marginBottom' properties containing numeric values in base units
 */
const useSpacingProps = (gap, rowGap, columnGap) => {

    const { image } = useTheme();

    // Merges gap, rowGap and columnGap props
    if(typeof gap === 'string'){

        let gaps = splitByWhitespace(gap);
        if(typeof rowGap !== 'string') rowGap = gaps[0];
        if(typeof columnGap !== 'string') {
            // duplicate gap value if only one has been specified
            columnGap = gaps.length > 1 ? gaps[1] : gaps[0];
        }

    }

    // Retrives spacing options from theme
    const options = useResponsiveProp(image.gap);

    // Default gutter and margin bottom from the theme
    const defaultGap = options[image.defaultGap].gap;
    const defaultMarginBottom = options[image.defaultMarginBottom].marginBottom;

    // Apply defaults if specified value is invalid
    if(image.allowedGaps.indexOf(rowGap) < 0) rowGap = defaultGap;
    else rowGap = options[rowGap].gap;
    
    if(image.allowedGaps.indexOf(columnGap) < 0) columnGap = defaultMarginBottom;
    else columnGap = options[columnGap].marginBottom;

    return {
        gutter: columnGap,
        marginBottom: rowGap,
    };

};

/**
 * ImagesWrapper React component
 * Wrapper for ImageItems, e.i. columns with images inside
 * @param {object} params
 */
const ImagesWrapper = ({ gutter, ...rest }) => {

    const props = {
        el: 'ul',
        flexDirection: { small: 'column', medium: 'row' },
        justifyContent: { medium: 'center' },
        alignItems: { small: 'stretch', medium: 'center' },
        gutterType: { medium: 'margin', },
        gutter: { medium: gutter, },
        gap: { smallOnly: gutter, },
        ...rest,
    };

    return <Flex { ...props } />;

};

/**
 * Returns array of 'span' props for all children nodes containing an image HTML element
 * @param {Array} children - children elements to be rendered
 * @param {string} widths - requested 'span' properties for each image separated by white space 
 * e.g. '3 6 3' for three columns, where the first and the last one are equal in width and the middle one is twice as wide
 * @returns array of objects that can be used in <Flex> child elements
 */
const useNormalizedWidths = (children, widths) => {

    const { image, flex } = useTheme();

    // Number of image elements
    const images = React.Children
        .map(children, child => React.isValidElement(child))
        .reduce(( sum, current) => current ? (sum + 1) : sum, 0);

    if(images <= 0) return [];

    // Allowed string values for individual width (span) e.g. '3' or '10'
    const allowed = image.allowedWidths.map(num => Math.floor(num).toString());

    // Transforming 'widths' to an Array
    switch(typeof widths){
        case 'number':
            widths = [ Math.floor(widths.toString()) ];
            break;
        case 'string':
            widths = splitByWhitespace(widths);
            break;
        default:
            widths = [];
            break;
    }

    // Filtering out invalid width values; returns numbers
    widths = widths
        .filter(width => allowed.indexOf(width) >= 0)
        .map(width => parseInt(width, 10));
    
    // Capacity of used grid row e.g. 12
    const maxSpan = tokenProp(flex.capacity, flex.defaultCapacity);

    // Maximum allowed width value e.i. the widest column possible on desktop
    const maxAllowed = image.allowedWidths.reduce((max, current) => current > max ? current : max, 0);

    // Setting up default width value (if none specified)
    // If there is only one image, make it as wide as possible
    // If there are more images, make them equal in width, trying to fill in entire container
    let defaultSpan = widths.length > 0 ? widths[0] : Math.floor(maxSpan / images);
    if(defaultSpan > maxAllowed) defaultSpan = maxAllowed;

    // Span properties for large viewport e.i. desktop
    const largeSpans = [];
    
    for(let index = 0 ; index < images ; index++){
        
        // Smart copy of supplied widths, filling gaps with default values
        let span = defaultSpan;
        if(widths.length > index) span = widths[index];

        // Special case when there are more images than specified widths - repeat the last specified width
        else if(widths.length > 1) span = largeSpans[index - 1]; 
        largeSpans.push(span);

    }

    // Sum of used horizontal space in large viewport (desktop) e.i. with two widths being 5 it's 10 in total
    const sumOfLargeSpans = largeSpans.reduce((sum, current) => sum + current, 0);

    // Copy large span values to medium viewport (tablet) if there is no horizontal space left anyways
    const mediumSpans = sumOfLargeSpans < maxSpan ? [] : largeSpans;
    
    if(mediumSpans.length <= 0){

        // Try to allocate any unused horizontal space in 'large' viewport,
        // so medium viewport e.i. tablet would be filled in horizontally

        // Spans left to allocate on tablet
        const unallocatedSpans = maxSpan - sumOfLargeSpans;
        let leftUnallocatedSpans = unallocatedSpans;
        
        for(let i = 0 ; i < largeSpans.length ; i++){
            
            const isLast = i + 1 === largeSpans.length;
            const largeSpan = largeSpans[i];
            
            if(isLast){
                // If it's the last item, just allocate what's left unallocated
                mediumSpans.push(largeSpan + leftUnallocatedSpans);
                break;
            }

            // Try to find new width using same column proportions
            let span = Math.round((largeSpan / sumOfLargeSpans) * maxSpan);
            let allocated = span - largeSpan;

            if(allocated > leftUnallocatedSpans) {

                // If computed span value is wider than space left
                allocated = leftUnallocatedSpans;
                span -= allocated - leftUnallocatedSpans;

            }

            leftUnallocatedSpans -= allocated;
            mediumSpans.push(span);

        }

    }

    const spans = [];

    // Returns array of responsive span properties for each image
    // Warning! Each IMAGE, NOT each CHILD!
    for(let i = 0 ; i < images ; i++){
        
        spans.push({
            small: 'shrink',
            medium: mediumSpans[i],
            large: largeSpans[i],
        });

    }

    return spans;

};

/**
 * Image React component
 * Wrapper for images specified in the .mdx file content e.g.
 * 
 * <Image widths="5 3" gap="small large" fullWidth>
 *   ![Alt text](./images/image1.jpg "Optional caption text")
 *   ![Alt text](./images/image2.jpg "Optional caption text")
 * </Image>
 * 
 * @param {object} params
 */
const Image = ({ children, fullWidth, gap, columnGap, rowGap, widths, parallax, ...rest }) => {

    // Get margin bottom and gutter based on 'gap', 'rowGap' and 'columnGap' props
    const { marginBottom, gutter } = useSpacingProps(gap, rowGap, columnGap);

    // Get children responsive 'span' props
    const spans = useNormalizedWidths(children, widths);

    // In case there are no valid children elements e.i. images
    if(spans.length <= 0) return null;

    const props = {
        fluid: fullWidth,
        marginBottom,
        ...rest,
    };

    // Counter for child images (instead of counting children)
    let imageIndex = 0;

    return (
        <Container { ...props }>
            <ImagesWrapper gutter={ gutter }>
                { React.Children.map(children, child => {
                    
                    // Removes line breaks etc.
                    if(!React.isValidElement(child)) return null;
                    
                    const span = spans.length > imageIndex ? spans[imageIndex] : 'shrink';
                    imageIndex++;

                    const item = parallax ? <ImageParallax>{ child }</ImageParallax> : child;
                    return <ImageItem span={ span }>{ item }</ImageItem>;

                })}
            </ImagesWrapper>
        </Container>
    );

};

Image.propTypes = {

    /**
     * Requested column widths containing images e.g. '3 6 3'.
     * These values will be used to render images on large viewport (desktop).
     * System will try to adapt these numbers for medium viewport (tablet) automatically.
     * On mobile viewport images are one after another and take whole row.
     * By default sum of widths should not exceed 12, see ```defaultCapacity``` variable in /src/themes/bafDark/components/flex.js.
     * If not specified, largest possible values will be assigned automatically.
     */
    widths: pt.string,

    /**
     * Remove horizonal margin surrounding the row with images?
     */
    fullWidth: pt.bool,

    /**
     * Defines spacing between images (columnGap) and below them (rowGap).
     * The first specified number corresponds to ```columnGap```, and the latter to the ```rowGap```.
     * If only ```columnGap``` is specified, the same value will be used for ```rowGap```.
     * Example: 'small large'
     */
    gap: pt.string,

    /**
     * Gap between image columns, overrides ```gap``` property
     * Example: 'large' or 'small'
     */
    columnGap: pt.string,

    /**
     * Images margin bottom, overrides ```gap``` property
     * Example: 'large' or 'small'
     */
    rowGap: pt.string,

    /**
     * Set true to enable parallax effect
     */
    parallax: pt.bool,

};

Image.defaultProps = {
    fullWidth: false,
    parallax: false,
};

export default Image;