/**
 * Exports PathsProvider, usePath
 */

import React, { useContext } from 'react';
import { usePagePath } from '../../utils';

// PathContext to keep paths to all pages
const PathsContext = React.createContext(null);

/**
 * PathsProvider React component to place at the app root, above any links
 * providing paths to all pages
 * @param {object} params
 */
const PathsProvider = ({ children }) => {

    const paths = usePagePath();
    return <PathsContext.Provider value={ paths }>{ children }</PathsContext.Provider>;

};

/**
 * React hook
 * @param {string} pageId - optional page id
 * @returns page path if pageId is specified, object with keys being page ids and values their respective paths otherwise
 */
const usePath = (pageId = false) => {
    
    const paths = useContext(PathsContext);
    return typeof pageId === 'string' ? paths[ pageId ] : paths;

};

export {
    PathsProvider,
    usePath
};