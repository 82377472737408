/**
 * Exports styled image-related design tokens
 */

export const borderRadius = {
    small: 'medium',
    medium: 'large',
};

export const boxShadow = 'out/large';

export const stickerWidth = 8;
export const stickerHeight = 5;
export const stickerBackgroundColor = 'gray/5';
export const stickerBoxShadow = '0px 2px 4px -1px rgba(0,0,0,0.4)';
export const stickerOpacity = 0.33;