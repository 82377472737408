/**
 * Exports Tag
 */

import React from 'react';
import pt from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { Icon, Flex, Paragraph, Box } from '@arturpol/systheme';

/**
 * TagIcon React component
 */
const TagIcon = React.forwardRef((props, ref) => {

    const theme = useTheme();
    const config = theme.tag;

    props = {
        size: config.iconSize,
        fill: config.iconColor,
        span: 'shrink',
        ...props,
        ref,
    };

    return <Icon { ...props } />

});

TagIcon.propTypes = {

    /**
     * Name of the glyph (see /src/themes/bafDark/components/glyph)
     */
    glyph: pt.string.isRequired,

};

/**
 * TagLabel React component
 */
const TagLabel = React.forwardRef((props, ref) => {
    
    const theme = useTheme();
    const config = theme.tag;

    props = {
        el: 'span',
        kind: config.labelKind,
        span: 'stretch',
        marginBottom: 0,
        ...props,
        ref,
    };

    return <Paragraph { ...props } />;

});

TagLabel.propTypes = {

    /**
     * Text
     */
    children: pt.node.isRequired,

};

/**
 * TagWrapper React component
 */
const TagWrapper = React.forwardRef((props, ref) => {

    const theme = useTheme();
    const config = theme.tag;

    props = {
        el: 'span',
        display: 'inline-flex',
        overflow: 'hidden',
        height: config.height,
        paddingLeft: config.horizontalPadding,
        paddingRight: config.horizontalPadding,
        gap: config.gap,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: config.backgroundColor,
        borderRadius: config.borderRadius,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...props,
        ref,
    };

    return <Flex { ...props } />

});

TagWrapper.propTypes = {

    /**
     * Content such as <TagLabel /> or <TagIcon />
     */
    children: pt.node.isRequired,

};

const StyledTagImage = styled(Box)`
    object-fit: contain;
`;

const TagImage = ({ src, alt, ...rest }) => {

    const props = {
        el: 'img',
        src,
        alt, 
        borderRadius: 'round',
        size: 2.5,
        border: '2px solid white',
        marginRight: 0.5,
        ...rest,
    };

    return <StyledTagImage { ...props } />;

};

/**
 * Tag React component
 * Displays text, image and/or icon
 */
const Tag = React.forwardRef(({ icon, image, label, ...rest }, ref) => {

    const props = {
        ...rest,
        ref,
    };

    return (
        <TagWrapper { ...props }>
            { image ? <TagImage { ...image } /> : '' }
            { icon ? <TagIcon glyph={ icon } /> : null }
            <TagLabel>{ label }</TagLabel>
        </TagWrapper>
    );

});

Tag.propTypes = {

    /**
     * Text to display as a tag label
     */
    label: pt.string.isRequired,

    /**
     * Tag icon
     */
    icon: pt.string,

    /**
     * Tag image
     */
    image: pt.shape({
        alt: pt.string.isRequired,
        src: pt.string.isRequired,
    }),

};

export {
    Tag,
    TagWrapper,
    TagIcon,
    TagLabel,
    TagImage,
};