import React from 'react';

function Repeat24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "repeat 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<circle cx="4" cy="18" fill="none" r="3"/>
		<polyline fill="none" points="2.966 1.13 2.237 6.927 7.929 5.341" stroke={fill}/>
		<path d="M2.237,6.927A11,11,0,1,1,9,22.58" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Repeat24;