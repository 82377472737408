/**
 * Exports Workflow
 */
import React from 'react';
import pt from 'prop-types';
import { Flex } from '@arturpol/systheme';
import Step from './Step';

/**
 * Workflow React component
 * Renders workflow card consisting from a few steps
 */
const Workflow = React.forwardRef(({ steps, ...rest }, ref) => {

    const props = {
        el: 'ul',
        flexDirection: { small: 'column', large: 'row', },
        alignItems: 'stretch',
        bgColor: 'rgba(18,18,18,0.75)',
        borderRadius: { small: 'medium', large: 'large', },
        overflow: 'hidden',
        gap: { small: 2, large: 4, },
        ...rest,
        ref: ref,
    };

    return (
        <Flex {...props }>
            { steps.map(({ id, ...step }, index) => (
                <Step { ...step } span={{ small: 'shrink', large: 'stretch', }} key={ id } last={ index + 1 === steps.length } />
            ))}
        </Flex>
    );

});

Workflow.propTypes = {

    /**
     * Workflow steps with specified title, description, icon and button
     */
    steps: pt.arrayOf(pt.object),
    
};

export default Workflow;