/**
 * Functions to normalize proposal data returned from graphql
 */

import { getImage } from 'gatsby-plugin-image';
import { getLink } from './link';

/**
 * Uses getLink to produce props for <Link> or <Button> component
 * @param {object} buttons - object with buttons
 * @returns object with link data
 */
const getNormalizedButtons = buttons => {
    
    const result = {};

    for(let i in buttons){
        if(!buttons.hasOwnProperty(i)) continue;
        result[i] = getLink(buttons[i]);
    }

    return result;

};

/**
 * Normalizes section with blocks and image mosaic
 * @param {object} params
 */
const getNormalizedSection = ({ items, ...rest }) => {

    items = items.map(item => {
        
        const img = {};

        for(let i in item.images){
            
            if(!item.images.hasOwnProperty(i)) continue;
            const image = item.images[i];
            
            img[i] = {
                alt: image.alt,
                image: getImage(image.src),
            };

        }

        return {
            ...item,
            images: img,
        };

    });

    return {
        items,
        ...rest,
    };

};

/**
 * Normalizes proposal data returned from the graphql
 * @param {object} node
 */
const getProposal = ({ buttons, features, packages, approach, sessions, upsell, ...rest }) => {
    
    let result = {
        ...rest,
        buttons: getNormalizedButtons(buttons),
        features: {
            ...features,
            button: getLink(features.button),
        },
        packages: {
            ...packages,
            items: packages.items.map(({ _id, image, ...rest }) => ({ 
                id: _id,
                image: {
                    alt: image.alt,
                    image: getImage(image.src),
                },
                ...rest,
            })),
        },
    };

    if(approach){
        approach = getNormalizedSection(approach);
        result = { ...result, approach };
    }

    if(sessions){
        sessions = getNormalizedSection(sessions);
        result = { ...result, sessions };
    }

    if(upsell){
        upsell = getNormalizedSection(upsell);
        result = { ...result, upsell };
    }

    return result;

};

export default getProposal;