import React from 'react';

function Repeat48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "repeat 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<circle cx="9" cy="38" fill="none" r="6"/>
		<polyline fill="none" points="5.932 2.26 4.474 13.854 15.858 10.682" stroke={fill}/>
		<path d="M4.474,13.854A22,22,0,1,1,18,45.16" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Repeat48;