/**
 * Exports flex-related design tokens
 */

import { token } from '../utils';

/**
 * Maximum number of columns in a row (grid setup)
 */
export const capacity = token('capacity', [{
    key: 'small',
    value: 4,
    order: 4,
},{
    key: 'medium',
    value: 12,
    order: 12,
},{
    key: 'large',
    value: 24,
    order: 24,
}]);

// default gap between the columns
export const defaultGapWidth = 'large';

// default grid
export const defaultCapacity = 'medium';