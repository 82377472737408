/**
 * Exports FeatureList
 */
import React from 'react';
import pt from 'prop-types';
import { Flex } from '@arturpol/systheme';
import Feature from './Feature';

/**
 * FeatureList React component
 * Renders feature list
 */
const FeatureList = React.forwardRef(({ items, ...rest }, ref) => {

    const props = {
        el: 'ul',
        bgColor: 'rgba(18,18,18,0.95)',
        borderRadius: { small: 'medium', medium: 'large', },
        flexDirection: { small: 'column', medium: 'row', },
        justifyContent: { medium: 'stretch', },
        alignItems: 'stretch',
        ...rest,
        ref,
    };

    return (
        <Flex {...props }>
            { items.map(({ id, ...item }, index) => (
                <Feature { ...item } span={{ small: 12, medium: 4, }} last={ index === items.length - 1 } key={ id } />
            ))}
        </Flex>
    );

});

FeatureList.propTypes = {

    /**
     * Features with specified title, description and icon
     */
    items: pt.arrayOf(pt.shape({
        title: pt.string.isRequired,
        description: pt.string.isRequired,
        icon: pt.string.isRequired,
    })),
    
};

export default FeatureList;
