/**
 * Exports StyledImage
 */

import React from 'react';
import pt from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { Box, tokenProp, useResponsiveProp } from '@arturpol/systheme';
import { ImageParallax } from '../ImageParallax';

/**
 * ImageWithSticker React component
 * Wrapper for the sticker outside inner image container with overflow=hidden
 */
const ImageWithSticker = styled(Box)`
    position: relative;

    &::after{
        position: absolute;
        display: block;
        content: " ";
        width: ${ props => props.theme.px(props.theme.styledImage.stickerWidth) };
        height: ${ props => props.theme.px(props.theme.styledImage.stickerHeight) };
        top: ${ props => props.theme.px(props.theme.size(props.theme.styledImage.stickerHeight).get() / -2) };
        left: calc(50% - ${ props => props.theme.px(props.theme.size(props.theme.styledImage.stickerWidth).get() / 2) });
        background-color: ${ props => tokenProp(props.theme.color, props.theme.styledImage.stickerBackgroundColor) };
        box-shadow: ${ props => tokenProp(props.theme.boxShadow, props.theme.styledImage.stickerBoxShadow) };
        opacity: ${ props => tokenProp(props.theme.opacity, props.theme.styledImage.stickerOpacity) };
    }
`;

/**
 * BoxWithOverflow React component
 * iOS fix
 */
const BoxWithOverflow = styled(Box)`
    isolation: isolate;
`;

/**
 * StyledImage React component used as a wrapper for GatsbyImage or StaticImage component
 * with extra visual effects
 * @param {*} props 
 * @returns 
 */
const StyledImage = ({ sticker, parallax, children, ...rest }) => {

    const { styledImage } = useTheme();

    sticker = useResponsiveProp(sticker);

    const props = {
        overflow: 'hidden',
        boxShadow: styledImage.boxShadow,

        borderTopLeftRadius: styledImage.borderRadius,
        borderTopRightRadius: styledImage.borderRadius,
        borderBottomRightRadius: styledImage.borderRadius,
        borderBottomLeftRadius: styledImage.borderRadius,

        ...rest,
    };

    const innerBox = parallax ? <ImageParallax>{ children }</ImageParallax> : children;
    const outerBox = <BoxWithOverflow { ...props }>{ innerBox }</BoxWithOverflow>;
    return sticker ? <ImageWithSticker>{ outerBox }</ImageWithSticker> : outerBox;

};

StyledImage.propTypes = {

    /**
     * Show sticker above the image?
     */
    sticker: pt.oneOfType([pt.bool, pt.object]),

    /**
     * Enable parallax effect?
     */
    parallax: pt.bool,

};

StyledImage.defaultProps = {
    sticker: false,
    parallax: false,
};

export default StyledImage;