/**
 * Exports useGrouppedLocations
 */

import { useStaticQuery, graphql } from 'gatsby';
import { getLocation } from '../helpers';

/**
 * React hook
 * See /content/data/location.yml
 * @returns locations groupped by subregion (powiat)
 */
const useGrouppedLocations = () => {

    const { allLocation } = useStaticQuery(graphql`
        query getLocations {
            allLocation(sort: {powiat: ASC}) {
                group(field: {powiat: SELECT}) {
                    fieldValue
                    nodes {
                        _id
                        name
                    }
                }
            }
        }
    `);

    return allLocation.group.map((group, index) => {

        return {
            id: `subregion-${ index }`,
            name: group.fieldValue,
            towns: group.nodes.map(node => getLocation(node)),
        };

    });

};

export default useGrouppedLocations;