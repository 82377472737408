/**
 * Exports Logo
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import logo from '../../../content/images/logo-dark.svg';

/**
 * @returns logo alternative text
 */
const useLogoAlt = () => {
    
    const data = useStaticQuery(graphql`
        query getLogoAlt {
            data(_id: {eq: "site"}) {
                logo {
                    alt
                }
            }
        }
    `);
    
    return data.data.logo.alt;

};

/**
 * Logo React component
 * Renders website logo
 * @param {object} props 
 */
const Logo = props => {
    
    const alt = useLogoAlt();
    
    props = {
        alt,
        src: logo,
        ...props,
    };
    
    // eslint-disable-next-line no-alert
    return <img { ...props } />;

};

export default Logo;