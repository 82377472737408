/**
 * Exports getMonetaryAmountSchema
 */

const getMonetaryAmountSchema = ({ amount, currency }) => {

    return {
        '@type': 'MonetaryAmount',
        currency,
        value: amount.toString(),
    };

};

export default getMonetaryAmountSchema;