/**
 * Exports getGeneralSchema, getInteger
 */

const getGeneralSchema = () => ({ '@context': 'https://schema.org', });
const getInteger = num => Math.floor(num).toString();

export {
    getGeneralSchema,
    getInteger,
};