import React from 'react';

function Cake24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "cake 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M12,8 c1.104,0,2-0.896,2-2s-2-4-2-4s-2,2.896-2,4S10.896,8,12,8z" fill="none"/>
		<path d="M23,15c0,2.209-1.791,4-4,4 c-1.518,0-2.822-0.855-3.5-2.101C14.822,18.145,13.518,19,12,19s-2.822-0.855-3.5-2.101C7.822,18.145,6.518,19,5,19 c-2.209,0-4-1.791-4-4" fill="none" stroke={fill}/>
		<rect height="8" width="22" fill="none" stroke={fill} x="1" y="15"/>
		<polyline fill="none" points="20,15 20,11 4,11 4,15 " stroke={fill}/>
	</g>
</svg>
	);
};

export default Cake24;