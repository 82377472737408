/**
 * Exports useProducts
 */

import { useStaticQuery, graphql } from 'gatsby';
import * as fragments from '../../fragments'; // eslint-disable-line
import { getProduct } from '../helpers';

/**
 * React hook
 * @returns all products available in the store sorted descending according to the 'order' property
 */
const useProducts = () => {

    const { allProduct } = useStaticQuery(graphql`
        query getProducts {
            allProduct(sort: {order: DESC}) {
                nodes {
                    ...ProductFragment
                }
            }
        }
    `);

    return allProduct.nodes.map(node => getProduct(node));

};

export default useProducts;