/**
 * Exports CalloutButton
 */

import React, { useCallback } from 'react';
import pt from 'prop-types';
import { useCalloutButton } from '../../utils';
import { PrimaryButton, SecondaryButton } from '../Button';
import { useTopMenuActions } from '../TopMenu';

/**
 * CalloutButton React component
 * Facilitates links with _menu property, e.i. one that can open top menu.
 * @param {object} params
 */
const CalloutButton = ({ id, secondary, ...rest }) => {

    const { link, context } = useCalloutButton(id);

    // Top menu action (for portfolio)
    const { open } = useTopMenuActions();

    // Does it open another menu (which one - id)?
    const menu = context.hasOwnProperty('_menu') && context._menu;

    // Open top menu callback with smooth scroll
    const callback = useCallback(() => open(menu, true), [ open, menu ]);

    const props = {
        ...link,
        ...rest,
    };

    if(menu){
        props.onTap = callback;
        props.motion = true;
    }

    return secondary ? <SecondaryButton { ...props } /> : <PrimaryButton { ...props } />;

};

CalloutButton.propTypes = {

    secondary: pt.bool,
    
    /**
     * Callout button id from /content/data/button.yml
     */
    id: pt.string.isRequired,

};

CalloutButton.defaultProps = {
    secondary: false,
};

export default CalloutButton;