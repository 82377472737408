/**
 * Exports getImageSchema
 */

import { getInteger } from './general';

const getImageObjectSchema = (image, baseUrl) => {

    const { src, width, height } = image.childImageSharp.fixed;

    return {
        '@type': 'ImageObject',
        url: `${baseUrl}${src}`,
        width: getInteger(width),
        height: getInteger(height),
    };

};

export default getImageObjectSchema;