import React from 'react';

function Horse64(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "horse 64";

	return (
		<svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M9.391,47.507l7.361-20.241A8,8,0,0,1,24.27,22H34.53A8.04,8.04,0,0,0,42,16.866,14,14,0,0,1,55.023,8v4l4.724,8.474a2,2,0,0,1-.547,2.574L56.6,25l-5.574-4L48,30l6.574,17.53" fill="none" stroke={fill}/>
		<path d="M49.685,50.155,48.6,47.172h0a17.407,17.407,0,0,0-33.2,0h0l-1.084,2.983" fill="none" stroke={fill}/>
		<path d="M18.824,23.048A8.249,8.249,0,0,0,18,23,11,11,0,0,0,7,34H2A12,12,0,0,1,14,22a11.872,11.872,0,0,1,5.663,1.462" fill="none" stroke={fill}/>
		<path d="M4.624,44.043a42.613,42.613,0,0,0,54.752,0" fill="none"/>
	</g>
</svg>
	);
};

export default Horse64;