/**
 * Exports useMinimumPriceHeader
 */

import { useStaticQuery, graphql } from 'gatsby';

/**
 * React hook
 * @returns minimum price header ('Price from')
 */
const useMinimumPriceHeader = () => {

    const { mdx } = useStaticQuery(graphql`
        query getMinimumPriceHeader {
            mdx(frontmatter: {_id: {eq: "offer-content"}}) {
                frontmatter {
                    offerContent {
                        item {
                            priceHeader
                        }
                    }
                }
            }
        }
    `);

    return mdx.frontmatter.offerContent.item.priceHeader;

};

export default useMinimumPriceHeader;