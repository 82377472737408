/**
 * Exports MainMenuList
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box } from '@arturpol/systheme';
import { useCurrentPageInfo, getLink } from '../../../utils';
import MainMenuItem from './Item';

/**
 * React hook
 * @returns main menu items as links
 */
const useMainMenuItems = () => {

    const data = useStaticQuery(graphql`
        query getMainMenuItems {
            menu(_id: {eq: "main"}) {
                items {
                    _id
                    _menu
                    _page
                    anchor
                    title
                    url
                    external
                }
            }
        }
    `);
    
    return data.menu.items.map(item => getLink(item));

};

/**
 * MainMenuList React component
 * List of main menu items
 * @param {object} props 
 */
const MainMenuList = props => {
    
    const items = useMainMenuItems();

    // Needed to determine active page
    const current = useCurrentPageInfo();

    props = {
        el: 'ul',
        minHeight: 40,
        ...props,
    };
    
    return (
        <Box { ...props }>
            { items.map(({ link, context }) => (
                <MainMenuItem link={ link } context={ context } current={ current } key={ context.id } />
            ))}
        </Box>
    );

};

export default MainMenuList;