/**
 * Exports TagNavigation
 */

import React from 'react';
import pt from 'prop-types';
import { useTheme } from 'styled-components';
import { Flex } from '@arturpol/systheme';
import TagNavigationLabel from './Label';
import TagNavigationButton from './Button';

/**
 * TagNavigation React component
 * TagCarousel component navigation with current slide info and next/previous buttons
 */
const TagNavigation = React.forwardRef(({ current, maximum, next, previous, ...rest }, ref) => {

    const theme = useTheme();
    const config = theme.tagCarousel;

    // current slide feedback label
    const text = `${current + 1}/${maximum}`;

    const props = {
        el: 'nav',
        gap: config.navigationGap,
        whiteSpace: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'stretch',
        ...rest,
        ref,
    };

    return (
        <Flex { ...props }>
            <TagNavigationButton icon={ config.navigationPreviousIcon } callback={ previous } span="shrink" />
            <TagNavigationLabel>{ text }</TagNavigationLabel>
            <TagNavigationButton icon={ config.navigationNextIcon } callback={ next } span="shrink" />
        </Flex>
    );

});

TagNavigation.propTypes = {

    /**
     * Current slide (0-based index)
     */
    current: pt.number.isRequired,

    /**
     * Total number of slides
     */
    maximum: pt.number.isRequired,

    /**
     * Next slide button callback
     */
    next: pt.func.isRequired,

    /**
     * Previous slide button callback
     */
    previous: pt.func.isRequired,
    
};

export default TagNavigation;