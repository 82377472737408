import React from 'react';

function Youtube48(props) {
	const fill = props.fill || 'currentColor';
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "youtube 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="none">
		<path d="M47.5 14.4s-.5-3.3-1.9-4.8c-1.8-1.9-3.9-1.9-4.8-2-6.7-.5-16.8-.5-16.8-.5s-10.1 0-16.8.5c-.9.1-3 .1-4.8 2C1 11.1.5 14.4.5 14.4S0 18.3 0 22.2v3.6c0 3.9.5 7.8.5 7.8s.5 3.3 1.9 4.8c1.8 1.9 4.2 1.9 5.3 2.1 3.8.4 16.3.5 16.3.5s10.1 0 16.8-.5c.9-.1 3-.1 4.8-2 1.4-1.5 1.9-4.8 1.9-4.8s.5-3.9.5-7.8v-3.6c0-4-.5-7.9-.5-7.9zM19 30.2V16.7l13 6.8-13 6.7z" fill={fill}/>
	</g>
</svg>
	);
};

export default Youtube48;