import React from 'react';

function Love24(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "love 24";

	return (
		<svg height={height} width={width} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<line fill="none" x1="5" x2="7.796" y1="19" y2="16.204"/>
		<line fill="none" x1="16" x2="22" y1="8" y2="2"/>
		<polyline fill="none" points="2 19 5 19 5 22"/>
		<path d="M16.091,3A4.966,4.966,0,0,0,12,5.209a4.9,4.9,0,0,0-9,2.7c0,4.909,9,12.273,9,12.273s9-7.364,9-12.273A4.924,4.924,0,0,0,16.091,3Z" fill="none" stroke={fill}/>
	</g>
</svg>
	);
};

export default Love24;