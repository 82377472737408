import React from 'react';

function Quote48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 2;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "quote";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M11,9a9,9,0,0,0,0,18h2.088A46.289,46.289,0,0,1,8.429,40h2.756A31.817,31.817,0,0,0,20,18,9,9,0,0,0,11,9Z" fill="none" stroke={fill}/>
		<path d="M37,9a9,9,0,0,0,0,18h2.088a46.289,46.289,0,0,1-4.659,13h2.756A31.817,31.817,0,0,0,46,18,9,9,0,0,0,37,9Z" fill="none"/>
	</g>
</svg>
	);
};

export default Quote48;