/**
 * Exports FooterMenu
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Box, Flex, Heading } from '@arturpol/systheme';
import { getLink, useCurrentPageInfo } from '../../../utils';
import FooterMenuItem from './Item';
import { SecondaryButton } from '../../Button';

/**
 * React hook
 * @returns footer menu title, items and contact button
 */
const useFooterMenu = () => {

    const { menu } = useStaticQuery(graphql`
        query getFooterMenu {
            menu(_id: {eq: "footer"}) {
                title
                items {
                    _id
                    _menu
                    _page
                    anchor
                    external
                    title
                    url
                }
                button {
                    _page
                    anchor
                    title
                }
            }
        }
    `);
    
    return {
        title: menu.title,
        items: menu.items.map(item => getLink(item)),
        button: getLink(menu.button),
    };

};

const List = props => {

    props = {
        el: 'ul',
        flexWrap: 'wrap',
        alignItems: 'center',
        columnGap: 'medium',
        ...props,
    };

    return <Flex { ...props } />;

};

const ButtonItem = props => {

    props = {
        el: 'li',
        display: 'inline-block',
        ...props,
    };

    return <Box { ...props } />;
};

const StyledButtonItem = styled(ButtonItem)`
    list-item: none;
`;

/**
 * FooterMenu React component
 * Footer menu with title and contact button
 * @param {object} props 
 */
const FooterMenu = props => {

    const { title, items, button } = useFooterMenu();

    // Needed to determine active page
    const current = useCurrentPageInfo();

    props ={
        el: 'nav',
        mt: { medium: 4 },
        mb: 4,
        ...props,
    };

    return (
        <Box { ...props }>
            <Heading kind="h6" variant="secondary" mb="0">{ title }</Heading>
            <List>
                { items.map(({ link, context }) => (
                    <FooterMenuItem link={ link } context={ context } current={ current } key={ context.id } span="shrink" />
                ))}
                <StyledButtonItem>
                    <SecondaryButton { ...button.link } size="small" />
                </StyledButtonItem>
            </List>
        </Box>
    );

};

export default FooterMenu;