import React from 'react';

function Submarine48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "submarine 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<polyline fill="none" points="9.149 22.869 7 19 4 19 4 25.55" stroke={fill}/>
		<polyline fill="none" points="9.24 34.873 7 39 4 39 4 32.166" stroke={fill}/>
		<polyline fill="none" points="33.333 20.999 32 13 18 13 16.619 21.284" stroke={fill}/>
		<path d="M29,5a4,4,0,0,0-4,4v4" fill="none" stroke={fill}/>
		<path d="M38,21,22,21C10,21,2,25,2,29s10,8,20,8l16,0a8,8,0,0,0,0-16Z" fill="none" stroke={fill}/>
		<circle cx="35" cy="29" fill="none" r="1"/>
		<circle cx="25" cy="29" fill="none" r="1"/>
		<circle cx="15" cy="29" fill="none" r="1"/>
	</g>
</svg>
	);
};

export default Submarine48;