/**
 * Exports Header
 */

import React from 'react';
import HeaderLogo from './Logo';
import HeaderMenu from './Menu';
import { Box, Flex, Container } from '@arturpol/systheme';

/**
 * Header React component
 * Common header for all pages (excluding these rendered inside a modal).
 * @param {object} props 
 */
const Header = props => {

    props = {
        el: 'header',
        position: 'absolute',
        left: 0,
        top: 0,
        // top: { small: 2, medium: 3 }, // Original design
        width: '100%',
        ...props,
    };

    return (
        <Box { ...props }>
            <Container>
                <Flex height="10" justifyContent="space-between" alignItems="center" alignContent="center">
                    <HeaderLogo />
                    <HeaderMenu />
                </Flex>
            </Container>
        </Box>
    );
};

export default Header;