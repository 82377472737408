import React from 'react';

function ChessTower32(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "chess tower 32";

	return (
		<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<polyline fill="none" points="24 25 20 12 25 8 25 1 23 1 21 5 19 5 17 1 15 1 13 5 11 5 9 1 7 1 7 8 12 12 8 25" stroke={fill}/>
		<line fill="none" stroke={fill} x1="12" x2="20" y1="12" y2="12"/>
		<rect height="5" width="22" fill="none" x="5" y="25"/>
	</g>
</svg>
	);
};

export default ChessTower32;