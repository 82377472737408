import React from 'react';

function ChessPawn48(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';
	const title = props.title || "chess pawn 48";

	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokewidth}>
		<path d="M9.883,39A28.927,28.927,0,0,0,20,17" fill="none" stroke={fill}/>
		<path d="M28,17A28.927,28.927,0,0,0,38.117,39" fill="none" stroke={fill}/>
		<circle cx="24" cy="10" fill="none" r="7" stroke={fill}/>
		<line fill="none" stroke={fill} x1="15" x2="33" y1="17" y2="17"/>
		<rect height="6" width="34" fill="none" x="7" y="39"/>
	</g>
</svg>
	);
};

export default ChessPawn48;